import React, { useState, useRef } from "react";

const Sidebar = ({ userRole, onPageClick }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [showReportsSection, setShowReportsSection] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    onPageClick(item);

    if (item === "Reports") {
      setShowReportsSection(true);
    } else if (["UnderFollowUp", "MultipleProdCategory", "RepeatedCustomer", "ProductCategory", "EmployeeBased", "CustomerAgeing", "LeadEscalation", "MissedLead"].includes(item)) {
      setShowReportsSection(true);
    } else {
      setShowReportsSection(false);
    }
  };



  const getNavIcons = (pageName) => {
    switch (pageName) {
      case "Admin Dashboard":
        return "fa fa-bullseye";
      case "User Dashboard":
        return "fa fa-bullseye";
      case "Lead Source":
        return "fa fa-bullseye";
      case "Customer Credit":
        return "fa fa-inr";
      case "Customer Category":
        return "fa fa-vcard";
      case "Brand":
        return "fa fa-industry menu-icon";
      case "Area Manager":
        return "fa fa-map-marker";
      case "Product Category":
        return "fa fa-cubes";
      case "Customer Creation":
        return "fa fa-users";
      case "Lead Online":
        return "fa fa-bell";
      case "Unit":
        return "fa fa-truck";
      case "Role & Access":
        return "fa fa-gavel";
      case "Users":
        return "fa fa fa-podcast";
      case "Team":
        return "fa fa-sitemap";
      case "Live Marketing":
        return "fa fa-automobile";
      case "Quotation Approval":
        return "fa fa-automobile";
      case "Setting":
        return "fa fa-cog";
      case "Lead Cancelation":
        return "fa fa-cog";
      case "Lead Monitoring":
        return "fa fa-eye";
      case "Create Vendor":
        return "fa fa-bullseye";
      case "Reports":
        return "fa fa-eye";
      default:
        return "fa fa-angle-right";
    }
  };

  const renderSidebarItems = () => {
    if (!userRole || !userRole.pageList || userRole.pageList.length === 0) {
      return null;
    }

    // Filter out Admin Dashboard and User Dashboard
    const adminAndUserDashboards = userRole.pageList.filter(
      (page) =>
        page.pageName === "Admin Dashboard" || page.pageName === "User Dashboard"
    );

    // Get the rest of the pages excluding Admin and User Dashboards
    const otherPages = userRole.pageList.filter(
      (page) =>
        page.pageName !== "Admin Dashboard" && page.pageName !== "User Dashboard"
    );

    // Combine Admin, User Dashboards first, then the other pages (without sorting)
    const sortedPages = [...adminAndUserDashboards, ...otherPages];

    return sortedPages.map((page) => {
      if (page.access.every((permission) => permission === 0)) {
        return null;
      }

      const id = `${page.pageName.replace(/\s+/g, '')}`;
      return (
        <li
          key={page._id}
          className={`nav-item ${activeItem === page.pageName ? "active" : ""}`}
        >
          <a
            className="nav-link"
            data-toggle="collapse"
            href={`#${id}`}
            aria-expanded={activeItem === page.pageName}
            aria-controls={id}
            onClick={() => {
              handleItemClick(page.pageName);
              toggleSidebar();
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="menu-title">{page.pageName}</span>
            <i className={getNavIcons(page.pageName) + " menu-icon"}></i>
          </a>
        </li>
      );
    });
  };



  const renderReportsSection = () => {
    return (
      showReportsSection && (
        <div className={`collapse ${showReportsSection ? "show" : ""} mx-4`} id="dashboard-collapse">
          <ul className="nav flex-column sub-menu">
            <li className={`nav-item ${activeItem === "UnderFollowUp" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("UnderFollowUp")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Under FollowUp</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            <li className={`nav-item ${activeItem === "MultipleProdCategory" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("MultipleProdCategory")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">MultiProdCategory</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            {/* repeated customer */}
            <li className={`nav-item ${activeItem === "RepeatedCustomer" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("RepeatedCustomer")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Repeated Customer</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            {/*product category based */}
            <li className={`nav-item ${activeItem === "ProductCategory" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("ProductCategory")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Prod Category Based</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            {/* Employee based report */}
            <li className={`nav-item ${activeItem === "EmployeeBased" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("EmployeeBased")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Employee Based</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            {/* Customer Ageing */}
            <li className={`nav-item ${activeItem === "CustomerAgeing" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("CustomerAgeing")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Customer Ageing</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            {/* Lead Escalation */}
            <li className={`nav-item ${activeItem === "LeadEscalation" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("LeadEscalation")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Lead Escalation</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
            {/* Missing Lead */}
            <li className={`nav-item ${activeItem === "MissedLead" ? "active" : ""}`}>
              <a
                className="nav-link pe-0 justify-content-between"
                onClick={() => handleItemClick("MissedLead")}
                style={{ cursor: "pointer" }}
              >
                <span className="menu-title">Missed Lead</span>
                <i class="fa fa-calendar "></i>
              </a>
            </li>
          </ul>
        </div>
      )
    );
  };

  return (
    <nav
      ref={sidebarRef}
      className={`sidebar sidebar-offcanvas ${isActive ? "active" : ""}`}
      id="sidebar"
    >
      <ul className="nav">
        {renderSidebarItems()}
        {renderReportsSection()}
      </ul>
    </nav>
  );
};

export default Sidebar;
