import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from 'xlsx';

const MissingLead = () => {
    const [searchMissedLeadTableData, setSearchMissedLeadProdTableData] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState("FollowUp");
    const [statusFilter, setStatusFilter] = useState(["escalation", "quotation"]);
    const [getMissedLeadReport, setGetMissedLeadReport] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [getLeadStatus, setGetLeadStatus] = useState([]);
    const [getUser, setGetUser] = useState([]);

    useEffect(() => {
        const fetchLeadEscalationReport = async () => {
            try {
                const response = await api.get("/missedLeadReport");
                setGetMissedLeadReport(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchLeadEscalationReport();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get("/user");
                setGetUser(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchCustomerCreation();
    }, []);

    useEffect(() => {
        const fetchLeadStatus = async () => {
            try {
                const response = await api.get("/leadStatus");
                setGetLeadStatus(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchLeadStatus();
    }, []);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = getMissedLeadReport.filter((row) => {
            const createdAt = moment(row.createdAt, "YYYY-MM-DD hh:mm A");
            return createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );
        });
        setFilteredRows(filtered);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };
        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
    };

    useEffect(() => {
        const determineStatusFilter = () => {
            switch (selectedReport) {
                case "FollowUp":
                    return ["open", "escalation", "quotation"];
                case "Non Converted Leads":
                    return ["cancel"];
                case "Converted":
                    return ["order success"];
                default:
                    return [];
            }
        };
        setStatusFilter(determineStatusFilter());
    }, [selectedReport]);

    const getReportFilteredRows = () => {
        if (!searchClicked) return [];

        return filteredRows.filter((row) => {
            const leadStatus = getLeadStatus.find((status) => status._id === row.status)?.leadStatus;
            return statusFilter.includes(leadStatus);
        });
    };

    const MissedLeadFilteredRows = getReportFilteredRows();

    // Define DataGrid columns
    const columns = [
        { field: "number", headerName: "Number", width: 100 },
        { field: "customerCode", headerName: "Customer Code", width: 150 },
        { field: "customerName", headerName: "Customer Name", width: 200 },
        { field: "mobile", headerName: "Mobile", width: 150 },
        { field: "createdBy", headerName: "Created By", width: 150 },
        { field: "transferTo", headerName: "Transfer To", width: 200 }
    ];

    // Prepare rows for DataGrid
    const rows = MissedLeadFilteredRows.map((row, index) => {
        const customer = getCustomerCreation.find((customer) => customer._id === row.name);
        const createdBy = getUser.find((user) => user._id === row.createdBy);
        const transfer = row.followup.map((followup) => {
            const transferCustomer = getUser.find((user) => user._id === followup.transferTo);
            return transferCustomer?.name || "";
        });

        return {
            id: row._id,
            number: index + 1,
            customerCode: customer?.customerCode || "",
            customerName: customer?.name || "",
            mobile: customer?.mobile || "",
            createdBy: createdBy?.name || "",
            transferTo: transfer.join(", ")
        };
    });

    const handleTableSearchChange = (event) => {
        setSearchMissedLeadProdTableData(event.target.value);
    };

    const matchesMissedLeadSearchTerm = (row) => {
        const item = row || {};

        const name = (item.customerName || '').toLowerCase();
        const customerCode = (item.customerCode || '').toString().toLowerCase();
        const mobile = (item.mobile || '').toString().toLowerCase();
        const createdBy = (item.createdBy || '').toLowerCase();
        const transferTo = (item.transferTo || '').toLowerCase();

        const searchValue = searchMissedLeadTableData.toLowerCase();

        return (
            name.includes(searchValue) ||
            customerCode.includes(searchValue) ||
            mobile.includes(searchValue) ||
            transferTo.includes(searchValue) ||
            createdBy.includes(searchValue)
        );
    };

    const filteredMissedLeads = searchMissedLeadTableData
        ? rows.filter(matchesMissedLeadSearchTerm)
        : rows;


    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredMissedLeads);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "MissedLeads");
        XLSX.writeFile(workbook, "Missed_Leads.xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>
                    Missed Lead
                </h3>
            </div>
            {/* Filters */}
            <div className="row">
                <div className="col-md-3">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <div className="form-group">
                        <label htmlFor="inlineFormInputName2">Status Category</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                        >
                            <option value="FollowUp">FollowUp</option>
                            <option value="Non Converted Leads">Non Converted Leads</option>
                            <option value="Converted">Converted Leads</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSearchClick}
                    >
                        Search
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger mt-3"
                            style={{ marginLeft: "10px" }}
                        >
                            &times;
                        </button>
                    )}
                </div>
            </div>
            <TextField
                variant="outlined"
                placeholder="Search"
                value={searchMissedLeadTableData}
                onChange={handleTableSearchChange}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: '50%',
                    marginLeft: 'auto',
                    display: 'flex',
                    padding: '10px'
                }}
            />
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title">List of Missed Leads</h4>
                                </div>
                                <div>
                                    <button onClick={downloadExcel} className="btn btn-success mb-3">
                                        Download Excel
                                    </button>
                                </div>
                            </div>
                            <Paper sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={filteredMissedLeads}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    sx={{ border: 0 }}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MissingLead;
