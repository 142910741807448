import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import api from "../Api";
import "../MediaQuery.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import StarRatings from "react-star-ratings";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import QuotationGenerator from "./QuotationGenerator";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const markers = [
  {
    id: 1,
    name: "Qobustan",
    position: { lat: 40.0709493, lng: 49.3694411 },
  },
];


const LeadLiveMonitoring = ({ adminId, adminName }) => {
  const [getProductCategory, setGetProductCategory] = useState([]);
  const [getLeadCancelReason, setGetLeadCancelReason] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [showAssignedToName, setShowAssignedToName] = useState("")
  const [userAdminRole, setUserAdminRole] = useState("");
  const [customerCount, setCustomerCount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [getTabValue, setTabValue] = useState("1");
  const [getAddTabValue, setAddTabValue] = useState("1");
  const [getCustomerCreation, setGetCustomerCreation] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [getLeadSource, setGetLeadSource] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [searchReference, setSearchReference] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedRefId, setSelectedRefId] = useState(null);
  const [showRefButton, setShowRefButton] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [getServices, setGetServices] = useState([]);
  const [searchService, setSearchService] = useState("");
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [selectedAdditionalName, setSelectedAdditionalName] = useState([]);
  const [searchAdditionalCustomer, setSearchAdditionalCustomer] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [getPincode, setGetPincode] = useState([]);
  const [getProductType, setGetProductType] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedProductType, setSelectedproductType] = useState([]);
  const [getUnit, setGetUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cameraStream, setCameraStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState([]);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showLiveForm, setShowLiveForm] = useState(false);
  const [getLiveMarketing, setGetLiveMarketing] = useState([]);
  const [editingLiveMarketing, setEditingLiveMarketing] = useState(null);
  const [getBuildingStructure, setGetBuildingStructure] = useState([]);
  const [selectedBuildStructure, setSelectedBuildStructure] = useState(null);
  const [buildingRating, setBuildingRating] = useState(0);
  const [customerRating, setCustomerRating] = useState(0);
  const [projectValue, setProjectValue] = useState("");
  const [getImage, setGetImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [projectDuration, setProjectDuration] = useState("");
  const [isProjectChecked, setIsProjectChecked] = useState("");
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isEscalateChecked, setIsEscalateChecked] = useState(
    Array(additionalFields.length).fill(false));
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(true);
  const [selectedEscalationUser, setSelectedEscalationUser] = useState("");
  const [getUser, setGetUser] = useState([]);
  const [getTeams, setGetTeams] = useState([]);
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [getLeadStatus, setGetLeadStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [rows, setGridRows] = useState([]);
  const [selectedAddCustomerType, setSelectedAddCustomerType] = useState([]);
  const [givenPrice, setGivenPrice] = useState("");
  const [expectedPrice, setExpectedPrice] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState([]);
  const [address, setAddress] = useState("");
  const [getFollowUp, setGetFollowUp] = useState([]);
  const [getFollowUpLead, setGetFollowUpLead] = useState([]);
  const [getTransferUser, setGetTransferUser] = useState([]);
  const [getImageFile, setImageFile] = useState();
  const [cancelStatusId, setCancelStatusId] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabAddIndex, setAddTabIndex] = useState(0);
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [searchClicked1, setSearchClicked1] = useState(false);
  const [filteredRows1, setFilteredRows1] = useState([]);
  const [TLFilteredRows1, setTLFilteredRows1] = useState([]);

  const [fromDate2, setFromDate2] = useState("");
  const [toDate2, setToDate2] = useState("");
  const [searchClicked2, setSearchClicked2] = useState(false);
  const [filteredRows2, setFilteredRows2] = useState([]);
  const [TLFilteredRows2, setTLFilteredRows2] = useState([]);

  const [fromDate3, setFromDate3] = useState("");
  const [toDate3, setToDate3] = useState("");
  const [searchClicked3, setSearchClicked3] = useState(false);
  const [filteredRows3, setFilteredRows3] = useState([]);
  const [TLFilteredRows3, setTLFilteredRows3] = useState([]);

  const [fromDate4, setFromDate4] = useState("");
  const [toDate4, setToDate4] = useState("");
  const [searchClicked4, setSearchClicked4] = useState(false);
  const [filteredRows4, setFilteredRows4] = useState([]);
  const [TLFilteredRows4, setTLFilteredRows4] = useState([]);

  const [fromDate5, setFromDate5] = useState("");
  const [toDate5, setToDate5] = useState("");
  const [searchClicked5, setSearchClicked5] = useState(false);
  const [filteredRows5, setFilteredRows5] = useState([]);
  const [TLFilteredRows5, setTLFilteredRows5] = useState([]);
  const [quotationStatusId, setQuotationStatusId] = useState(null);


  const [isSampleChecked, setIsSampleChecked] = useState(
    Array(additionalFields.length).fill(false)
  );
  const [QModal, setQModal] = useState(false);
  const [get24hrsTransferUser, setGet24hrsTransferUser] = useState([]);
  const [showQuotationHistory, setShowQuotationHistory] = useState(false);
  const [quotationalHistories, setQuotationalHistories] = useState([]);
  const [getLeadOnline, setGetLeadOnline] = useState([]);
  const [modalImage, setModalImage] = useState('');
  const [TLTeamUsers, setTLTeamUsers] = useState([]);


  const handleChangeTab = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const fetchProductCategory = async () => {
      try {
        const response = await api.get("/productCategory");
        setGetProductCategory(response.data);
      } catch (error) {
        //-console.error("Error fetching product categories:", error);
      }
    };
    fetchProductCategory();
  }, []);

  useEffect(() => {
    const fetchLeadOnline = async () => {
      try {
        const response = await api.get("/leadCreationData");
        setGetLeadOnline(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchLeadOnline();
  }, []);

  useEffect(() => {
    const fetchLeadCancelReason = async () => {
      try {
        const response = await api.get("/cancelReason");
        setGetLeadCancelReason(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchLeadCancelReason();
  }, []);



  const { isLoaded: loadScriptLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDPgQr91arVKr9GYB4_-1dSzaCA1XdXckA'
  })

  useEffect(() => {
    const fetchTransferUser = async () => {
      try {
        const response = await api.get("/transferUser");
        //-console.log("transfer", response.data);
        setGetTransferUser(response.data.transferTo);
        //-console.log(getTransferUser);
      } catch (error) {
        //-console.error("Error fetching transfer user:", error);
      }
    };
    fetchTransferUser();
  }, []);

  useEffect(() => {
    const setDefaultFromToDates = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      setFromDate1(currentDateFormatted);
      setFromDate2(currentDateFormatted);
      setFromDate3(currentDateFormatted);
      setFromDate4(currentDateFormatted);
      setFromDate5(currentDateFormatted);
      setToDate1(currentDateFormatted);
      setToDate2(currentDateFormatted);
      setToDate3(currentDateFormatted);
      setToDate4(currentDateFormatted);
      setToDate5(currentDateFormatted);
    };

    setDefaultFromToDates();
  }, []);


  useEffect(() => {
    const fetchImageAtLive = async () => {
      try {
        const response = await api.get("/liveMarketing");
        setGetImage(response.data.image);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchImageAtLive();
  }, []);

  const fetchFollowUp = async () => {
    try {
      const response = await api.get("/findFollowUpDataLive");
      setGetFollowUp(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    fetchFollowUp();
  }, []);

  useEffect(() => { }, [getFollowUp]);

  const fetchFollowUpLead = async () => {
    try {
      const response = await api.get("/findFollowUpData");
      setGetFollowUpLead(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    fetchFollowUpLead();
  }, []);

  useEffect(() => { }, [getFollowUpLead]);

  // After 24 hrs the live transfer to next team users

  const fetchTransferUser = async () => {
    try {
      const response = await api.get("/transferUser");
      setGet24hrsTransferUser(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    fetchTransferUser();
  }, []);

  useEffect(() => { }, [get24hrsTransferUser]);


  const handlePincodeChange = async (e) => {
    const enteredPincode = e.target.value;
    if (/^\d{6}$/.test(enteredPincode)) {
      try {
        const response = await api.get(`/pincode/${enteredPincode}`);
        //-console.log(response.data);
        const { city, state, areas } = response.data;

        setCity(city);
        setState(state);
        setAreas(areas);
      } catch (error) {
        //-console.error('Error fetching pincode data:', error);
      }
    } else {
      setCity('');
      setState('');
      setAreas([]);
    }
  };

  useEffect(() => {
    const fetchLeadStatus = async () => {
      try {
        const response = await api.get("/leadStatus");
        const leadStatuses = response.data;

        // Find the lead status object with leadStatus 'quotation'
        const quotationStatus = leadStatuses.find(status => status.leadStatus === 'quotation');
        const cancelationStatus = leadStatuses.find(status => status.leadStatus === 'cancel');

        if (cancelationStatus) {
          // If found, you can access its _id
          const cancelationStatusId = cancelationStatus._id;
          setCancelStatusId(cancelationStatusId);
        }

        if (quotationStatus) {
          // If found, you can access its _id
          const quotationStatusId = quotationStatus._id;
          setQuotationStatusId(quotationStatusId);
          //-console.log(quotationStatusId);
        } else {
          //-console.log("Lead status 'quotation' not found.");
        }

        setGetLeadStatus(leadStatuses);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchLeadStatus();
  }, []);


  const toggleLiveForm = () => {
    setShowLiveForm(!showLiveForm);
    setEditingLiveMarketing(null);
  };

  const getCityAndState = (pincode) => {
    const pincodeEntry = getPincode.find((entry) => entry.pincode === pincode);

    if (pincodeEntry) {
      return {
        city: pincodeEntry.city.city,
        state: pincodeEntry.state.state,
      };
    } else {
      return { city: "Unknown", state: "Unknown" };
    }
  };

  const getCurrentLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          //-console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          //-console.error("Error getting current location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const renderMap = () => {
    return (
      <GoogleMap
        center={currentLocation || { lat: 40.3947365, lng: 49.6898045 }}
        zoom={currentLocation ? 15 : 10}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: '100%', height: '90vh' }}
      >
        {/* Display current location marker if currentLocation is set */}
        {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{
              url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }}
          />
        )}
      </GoogleMap>
    );
  };



  useEffect(() => {
    const fetchProductType = async () => {
      try {
        const response = await api.get("/productTypes");
        setGetProductType(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchProductType();
  }, []);

  useEffect(() => {
    const fetchBuildingStructre = async () => {
      try {
        const response = await api.get("/buildingStructure");
        setGetBuildingStructure(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchBuildingStructre();
  }, []);

  const fetchLiveMarketing = async () => {
    try {
      const response = await api.get("/liveMarketing");
      setGetLiveMarketing(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    const fetchCount = async () => {
      if (selectedCustomerId) {
        try {
          const response = await api.get(`/customerCount/${selectedCustomerId}`);
          setCustomerCount(response.data.count);
        } catch (error) {
          console.error('Error fetching customer count:', error);
        }
      }
    };

    if (selectedCustomerId) {
      fetchCount();
    }
  }, [selectedCustomerId]);


  useEffect(() => {
    fetchLiveMarketing();
  }, []);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/unit");
        setGetUnit(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchUnits();
  }, []);

  useEffect(() => {
    const fetchCustomerCreation = async () => {
      try {
        const response = await api.get("/customerCreation");
        setGetCustomerCreation(response.data);
      } catch (error) {
        //-console.log("Error fetching customer creation:", error);
      }
    };
    const fetchLeadSources = async () => {
      try {
        const response = await api.get("/leadSource");
        setGetLeadSource(response.data);
      } catch (error) {
        //-console.error("Error fetching lead sources:", error);
      }
    };
    const fetchServices = async () => {
      try {
        const response = await api.get("/services");
        setGetServices(response.data);
      } catch (error) {
        //-console.error("Error fetching services:", error);
      }
    };

    fetchCustomerCreation();
    fetchLeadSources();
    fetchServices();
  }, []);

  const filteredCustomers = getCustomerCreation.filter(
    (customer) =>
      customer?.name?.toLowerCase().includes(searchName.toLowerCase()) ||
      customer?.alterMobile?.includes(searchName) ||
      customer?.mobile?.includes(searchName)
  );

  const handleNameSelect = (customer) => {
    setSearchName(
      `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
    );
    setSelectedCustomerId(customer._id);
  };

  const filteredRefCustomers = getCustomerCreation.filter((reference) =>
    reference.name
      ? reference.name.toLowerCase().includes(searchName.toLowerCase())
      : "" || reference.alterMobile
        ? reference.alterMobile.includes(searchName)
        : "" || reference.mobile
          ? reference.mobile.includes(searchName)
          : ""
  );

  const handleRefSelect = (customer) => {
    setSearchReference(
      `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
    );
    setSelectedRefId(customer._id);
  };

  const clearName = (searchName) => {
    if (searchName) {
      setSearchName("");
    }
  };
  const clearRef = (searchReference) => {
    if (searchReference) {
      setSearchReference("");
    }
  };


  const filteredCustomerServices = getServices.filter(
    (service) =>
      (service.customerCategory && service.customerCategory.customerCategory
        .toLowerCase()
        .includes(searchService.toLowerCase())) ||
      (service.workNature && service.workNature.workNature
        .toLowerCase()
        .includes(searchService.toLowerCase())) ||
      (service.services && service.services.toLowerCase().includes(searchService.toLowerCase()))
  );

  const handleCustomerServicesClick = (serviceId) => {
    const service = getServices.find(
      (customerService) => customerService._id === serviceId
    );
    const combinedService = `${service.customerCategory.customerCategory} - ${service.workNature.workNature} - ${service.services}`;

    setSelectedServiceList((prevList) => {
      const updatedList = [...prevList, combinedService];
      //-console.log("Selected Service List:", updatedList);
      return [...prevList, combinedService];
    });

    setSelectedServiceType((prevTypes) => {
      const updatedTypes = [...prevTypes, service._id];
      //-console.log("Selected Service Types:", updatedTypes);
      return [...prevTypes, service._id];
    });

    setSearchService("");
    addDropdown();
  };

  useEffect(() => { }, [selectedServiceList]);

  const addDropdown = () => {
    setDropdowns([...dropdowns, { id: dropdowns.length }]);
  };

  const removeDropdown = (indexToRemove) => {
    setDropdowns(dropdowns.filter((_, index) => index !== indexToRemove));
    const updatedSelectedService = selectedServiceList.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedServiceList(updatedSelectedService);
    const updatedSelectedAdditionalName = selectedAdditionalName.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedAdditionalName(updatedSelectedAdditionalName);
  };

  const filteredAddCustomers = getCustomerCreation.filter((customer) => {
    if (typeof searchAdditionalCustomer === "string") {
      return (
        customer.name
          .toLowerCase()
          .includes(searchAdditionalCustomer.toLowerCase()) ||
        customer.alterMobile.includes(searchAdditionalCustomer) ||
        customer.mobile.includes(searchAdditionalCustomer)
      );
    } else {
      return false;
    }
  });

  const handleAdditionalNameSelect = (customerId) => {
    const addCustomer = getCustomerCreation.find(
      (customer) => customer._id === customerId
    );

    setSelectedAdditionalName((prevList) => {
      const updatedList = [...prevList, addCustomer];
      return [...prevList, addCustomer];
    });

    setSelectedAddCustomerType((prevTypes) => {
      const updatedTypes = [...prevTypes, addCustomer._id];
      return [...prevTypes, addCustomer._id];
    });

    setSearchAdditionalCustomer("");
  };

  const filteredProductType = getProductType.filter(product => {
    // Check if the product object and its nested properties exist
    if (
      product &&
      product.product &&
      product.product.product &&
      product.productTypes &&
      product.brand &&
      product.brand.brand &&
      product.productDescription
    ) {
      // Find the product category that matches the product's category ID
      const productCategoryValue = getProductCategory.find(
        (productCat) => product.product.productCategory === productCat._id
      );

      // Debugging: Log the product category value and other properties
      //-console.log('Product Category ID:', product.product.ProductCategory);
      //-console.log('Product Category Value:', productCategoryValue);

      const productCategory = productCategoryValue ? productCategoryValue.productCategory.toLowerCase() : '';
      const productProduct = product.product.product.toLowerCase();
      const productTypes = product.productTypes.toLowerCase();
      const brand = product.brand.brand.toLowerCase();
      const productDescription = product.productDescription.toLowerCase();
      const searchTerm = searchProduct.toLowerCase();

      // Check if the search term is included in any of the product fields
      return (
        productCategory.includes(searchTerm) ||
        productProduct.includes(searchTerm) ||
        productTypes.includes(searchTerm) ||
        brand.includes(searchTerm) ||
        productDescription.includes(searchTerm)
      );
    }

    // If the product or its nested properties are missing, return false
    return false;
  });

  const handleProductSelect = (selectedProduct) => {

    const productDetail = getProductType.find(
      (producttype) => producttype._id === selectedProduct
    );
    //-console.log("productDetail", productDetail);


    if (!productDetail) {
      //-console.error("Product not found");
      return;
    }
    const productCategoryValue = getProductCategory.find(
      (productCat) => productDetail.product?.productCategory === productCat._id
    );
    //-console.log("productCategoryValue", productCategoryValue);


    const selectedProductString = `${productCategoryValue?.productCategory || ''} - ${productDetail.product?.product || ''} - ${productDetail.productTypes || ''} - ${productDetail.brand?.brand || ''}`;

    // Update the selected products and types
    setSelectedProductList((prevList) => [...prevList, selectedProductString]);
    setSelectedproductType((prevTypes) => [...prevTypes, productDetail._id]);

    // Clear search input
    setSearchProduct("");
  };

  useEffect(() => { }, [selectedProductList]);

  const startCamera = async (facingMode) => {
    try {
      const constraints = {
        video: {
          facingMode: facingMode
        }
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      setCameraStream(stream);
      setIsCameraActive(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      //-console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
      setIsCameraActive(false);
    }
  };

  useEffect(() => {
    return () => stopCamera();
  }, []);

  const captureImage = async () => {
    closeQModal();
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL("image/jpeg");
      setCapturedImage([...capturedImage, imageDataURL]);
      stopCamera();
    }
  };

  const toggleCamera = () => {
    const newFacingMode = isFrontCamera ? "environment" : "user";
    setIsFrontCamera(!isFrontCamera);
    stopCamera();
    startCamera(newFacingMode);
  };

  const removeCapturedImage = (indexToRemove) => {
    const updatedCapturedImage = capturedImage.filter(
      (_, index) => index !== indexToRemove
    );
    setCapturedImage(updatedCapturedImage);
  };


  const handleUpload = async (index) => {
    const formData = new FormData();
    formData.append("file", getImageFile);
    formData.append("customerid", adminId);
    formData.append("format", "image");
    const response = await api.post("/uploadAudio", formData);
    const updatedFields = [...additionalFields];
    updatedFields[index].imageSource = response.data;
    setAdditionalFields(updatedFields);
    //-console.log(additionalFields);
  };


  const handleEditingLiveMarketing = (item) => {
    //-console.log(item);

    setSelectedLeadSource(item.leadSource ? item.leadSource._id : "");
    const searchName = item.name
      ? `${item.name.name || ''} ${item.name.mobile || '-'} ${item.name.alterMobile || '-'}`
      : '';

    setSearchName(searchName);
    const searchReferenceName = item.leadReference
      ? `${item.leadReference.name || ''} ${item.leadReference.mobile || '-'} ${item.leadReference.alterMobile || '-'}`
      : '';

    setSearchReference(searchReferenceName);
    setSelectedCustomerId(item.name ? item.name._id : "");
    setShowLiveForm(true);
    setEditingLiveMarketing(item);
    setSelectedImage(item.image);
    setSelectedStatus(item.status ? item.status._id : "N/A");
    setSelectedBuildStructure(item.buildingStructure || "");
    setBuildingRating(item.buildingRating || 0);
    setCustomerRating(item.customerRating || 0);
    setProjectValue(item.projectValue || "");
    setProjectDuration(item.projectDuration || "");
    setIsProjectChecked(item.project === 1 ? 1 : 0);
    setPincode(pincode.pincode || "");
    setSelectedAreaName(pincode.area || "");
    setCity(pincode.district ? pincode.district.district : "");
    setState(pincode.state ? pincode.state.state : "");
    setAddress(item.name ? item.name.address : "-");

    // Process and set quotationalHistories
    const history = item.quotationalHistories.map(innerArray =>
      Object.values(innerArray).map(product => ({
        productType: `${product.productType?.ProductCategory?.productCategory || ''} ${product.productType?.product?.product || ''} ${product.productType?.productTypes || ''} ${product.productType?.brand?.brand || ''}`,
        unit: product.unit.unit,
        quantity: product.quantity,
        givenPrice: product.givenPrice,
        expectedPrice: product.expectedPrice,
      }))
    );

    setQuotationalHistories(history);

    // Process and set selectedServices
    const selectedServices = item.serviceList
      ? item.serviceList.map(service => service.serviceType ? service.serviceType.services : "N/A")
      : [];
    setSelectedServiceList(selectedServices);

    // Process and set selectedSerType
    const selectedSerType = item.serviceList
      ? item.serviceList.map(service => service.serviceType ? service.serviceType._id : "N/A")
      : [];
    setSelectedServiceType(selectedSerType);

    // Process and set selectedNames
    const selectedNames = item.serviceList
      ? item.serviceList.map(service => ({
        name: service.customerType ? service.customerType.name : "",
        mobile: service.customerType ? service.customerType.mobile : "",
      }))
      : [];
    setSelectedAdditionalName(selectedNames);

    // Process and set selectedAddCustomerType
    const selectedAddCustomerType = item.serviceList
      ? item.serviceList.map(service => service.customerType ? service.customerType._id : "N/A")
      : [];
    setSelectedAddCustomerType(selectedAddCustomerType);

    // Process and set additionalFields
    const additionalFields = item.followup
      ? item.followup.map(followup => ({
        id: followup._id,
        nextFollowupDate: followup.nextFollowUpDate ? new Date(followup.nextFollowUpDate) : null,
        imageSource: followup.imageSource || "",
        remarks: followup.remark || "",
      }))
      : [];
    setAdditionalFields(additionalFields);

    // Set project checked status
    setIsProjectChecked(item.project === 1 ? 1 : 0);
  };


  const ExQuotationHistLength = editingLiveMarketing ? editingLiveMarketing.quotationalHistories.length : 0;

  const handleBuildingRatingChange = (newRating) => {
    setBuildingRating(newRating);
  };
  const handleCustomerRatingChange = (newRating) => {
    setCustomerRating(newRating);
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await api.get("/team");
        setGetTeams(response.data);
      } catch (error) {
        //-console.log("Error fetching Teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/user");
        setGetUser(response.data);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);


  // Function to find the teamName of adminId
  const findUserTeamName = (adminId) => {
    const foundUser = getUser.find(user => user._id === adminId);
    if (foundUser && foundUser.team) {
      const foundTeam = getTeams.find(team => team._id === foundUser.team._id);
      return foundTeam ? foundTeam._id : null;
    }
    return null;
  };

  // get TL team users

  //-console.log("row", rows);

  // Function to find users by team
  const findUsersByTeam = (teamName) => {
    const teamNameUsers = rows.filter(user =>
      user.item?.assignedTo?.team === teamName
    );
    setTLTeamUsers(teamNameUsers);
  };

  // Main component logic for fetching team name
  useEffect(() => {
    const teamName = findUserTeamName(adminId);
    if (teamName) {
      findUsersByTeam(teamName);
    }
  }, [adminId, findUserTeamName]);
  useEffect(() => {
    // console.log("TLTeamUsers", TLTeamUsers);
  }, [TLTeamUsers]);


  // get adminId of the roleName
  useEffect(() => {
    const findLoginByAdmin = (adminId, users, adminRoles) => {
      const foundLoginUser = users.find(user => user._id === adminId);

      if (foundLoginUser) {
        const userRole = adminRoles.find(role => role._id === foundLoginUser.adminRole._id);
        if (userRole && userRole.roleName === "Admin") {
          setUserAdminRole(userRole.roleName);
        } else {
          setUserAdminRole("not an admin");
        }
      }
    };

    findLoginByAdmin(adminId, getUser, getAdminRole);
  }, [adminId, getUser, getAdminRole]);




  const addAdditionalField = () => {
    const newField = {
      id: uuidv4(),
      nextfollowUp: null,
      remarks: "",
      imageSource: "",
    };
    setAdditionalFields([...additionalFields, newField]);
  };
  // Function to remove an additional field by index
  const removeAdditionalField = (index) => {
    removeCapturedImage(index);
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  // Function to update remarks in additional fields
  const handleRemarksChange = (index, remarks) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].remarks = remarks;
    setAdditionalFields(updatedFields);
  };

  const handleEscalationChange = (index, value) => {
    const updatedEscalation = [...selectedEscalationUser];
    updatedEscalation[index] = value;
    setSelectedEscalationUser(updatedEscalation);
  };

  const handleDateChange = (date, index, fieldName) => {
    const updatedFields = [...additionalFields];

    if (fieldName === "nextFollowup") {
      updatedFields[index] = {
        ...updatedFields[index],
        nextFollowupDate: date,
      };
    }

    setAdditionalFields(updatedFields);
  };

  const handleEscaltionCheckbox = (index, checked) => {
    const updatedEscalationCheck = [...isEscalateChecked];
    updatedEscalationCheck[index] = checked;
    setIsEscalateChecked(updatedEscalationCheck);
    setIsDropdownDisabled(!checked);
  };

  const handleProjectCheckboxChange = (event) => {
    setIsProjectChecked(event.target.checked);
    if (event.target.checked) {

    }
  };

  const handleSampleCheckbox = (index, checked) => {
    const updatedSample = [...isSampleChecked];
    updatedSample[index] = checked;
    setIsSampleChecked(updatedSample);
  };

  const handleUnitChange = (index, value) => {
    const updatedUnits = [...selectedUnit];
    updatedUnits[index] = value;
    setSelectedUnit(updatedUnits);
  };

  // Example handler for updating quantity state
  const handleQuantityChange = (index, value) => {
    const updatedQuantities = [...quantity];
    updatedQuantities[index] = value;
    setQuantity(updatedQuantities);
  };

  const handleGivePrice = (index, value) => {
    const updatedGivePrice = [...givenPrice];
    updatedGivePrice[index] = value;
    setGivenPrice(updatedGivePrice);
  };

  const handleExpectedPrice = (index, value) => {
    const updatedExpectedPrice = [...expectedPrice];
    updatedExpectedPrice[index] = value;
    setExpectedPrice(updatedExpectedPrice);
  };

  const toggleQuotationHistory = () => {
    setShowQuotationHistory(!showQuotationHistory);
  };

  const handleAddChangeTab = (index) => {
    setAddTabIndex(index);
  };

  const handleCreateQuotationalHistory = () => {
    const selectedProductsForQuotation = selectedProductList.map((product, index) => {
      const selectedUnitObject = getUnit.find(unit => unit._id === selectedUnit[index]);

      return {
        productType: product,
        unit: selectedUnitObject ? selectedUnitObject.unit : '',
        quantity: quantity[index],
        givenPrice: givenPrice[index],
        expectedPrice: expectedPrice[index],
      };
    });

    // Update state with the new quotational history
    setQuotationalHistories(prevHistories => [...prevHistories, selectedProductsForQuotation]);

    // Clear form fields and toggle the quotation history view
    toggleQuotationHistory();
    setSelectedProductList([]);
    setSelectedUnit([]);
    setQuantity([]);
    setGivenPrice([]);
    setExpectedPrice([]);
    setSearchProduct('');
  };

  const quotationalHistoriesLength = quotationalHistories.length;


  // get unit

  const openModal = () => {
    startCamera("user");
    setShowModal(true);
  };
  // Quotation Modal
  const closeQModal = () => {
    setQModal(false);
  };

  const openQModal = () => {
    setQModal(true);
  };


  const handleImageClick = (imageSrc) => {
    //-console.log(imageSrc);
    setModalImage(imageSrc);

  };

  useEffect(() => {
    //-console.log('modalImage:', modalImage);
  }, [modalImage]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddTabChange = (event, newValue) => {
    setAddTabValue(newValue);
  };


  useEffect(() => {
    let temp_rows = [];
    getLiveMarketing.map((item, index) => {
      const quotationalHistories = [];
      item.quotationalHistories.map((product, index) => {
        quotationalHistories.push({
          productType: product.productType,
          unit: product.unit,
          quantity: product.quantity,
          givenPrice: product.givenPrice,
          expectedPrice: product.expectedPrice,
        });
      });
      temp_rows.push(
        createData(
          item.name ? item.name.name : "",
          item.leadSource?.leadSource ?? "",
          item.createdAt,
          getCityAndState(item.pincode).city,
          item.pincode,
          quotationalHistories,
          item
        )
      );
    });
    getLeadOnline.map((item, index) => {
      const quotationalHistories = [];
      item.quotationalHistories.map((product, index) => {
        quotationalHistories.push({
          productType: product.productType,
          unit: product.unit,
          quantity: product.quantity,
          givenPrice: product.givenPrice,
          expectedPrice: product.expectedPrice,
        });
      });
      temp_rows.push(
        createData(
          item.name ? item.name.name : "",
          item.leadSource?.leadSource ?? "",
          item.createdAt,
          getCityAndState(item.pincode).city,
          item.pincode,
          quotationalHistories,
          item
        )
      );
    });
    //-//-console.log(temp_rows);
    setGridRows(temp_rows);
  }, [getLiveMarketing, getLeadOnline]);



  const handleFromDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setFromDate1(e.target.value);
        break;
      case 1:
        setFromDate2(e.target.value);
        break;
      case 2:
        setFromDate3(e.target.value);
        break;
      case 3:
        setFromDate4(e.target.value);
        break;
      case 4:
        setFromDate5(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleToDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setToDate1(e.target.value);
        break;
      case 1:
        setToDate2(e.target.value);
        break;
      case 2:
        setToDate3(e.target.value);
        break;
      case 3:
        setToDate4(e.target.value);
        break;
      case 4:
        setToDate5(e.target.value);
        break;
      default:
        break;
    }
  };

  // Handle search click
  const handleSearchClick = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setSearchClicked1(true);
        break;
      case 1:
        setSearchClicked2(true);
        break;
      case 2:
        setSearchClicked3(true);
        break;
      case 3:
        setSearchClicked4(true);
        break;
      case 4:
        setSearchClicked5(true);
        break;
      default:
        break;
    }
  };

  // Handle clear filter
  const handleClearFilter = (tabIndex) => {
    const setDefaultFromDate = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      switch (tabIndex) {
        case 0:
          setFromDate1(currentDateFormatted);
          setSearchClicked1(false);
          setToDate1(currentDateFormatted);
          setFilteredRows1([]);
          setTLFilteredRows1([]);
          break;
        case 1:
          setFromDate2(currentDateFormatted);
          setSearchClicked2(false);
          setToDate2(currentDateFormatted);
          setFilteredRows2([]);
          setTLFilteredRows2([]);
          break;
        case 2:
          setFromDate3(currentDateFormatted);
          setSearchClicked3(false);
          setToDate3(currentDateFormatted);
          setFilteredRows3([]);
          setTLFilteredRows3([]);
          break;
        case 3:
          setFromDate4(currentDateFormatted);
          setSearchClicked4(false);
          setToDate4(currentDateFormatted);
          setFilteredRows4([]);
          setTLFilteredRows4([]);
          break;
        case 4:
          setFromDate5(currentDateFormatted);
          setSearchClicked5(false);
          setToDate5(currentDateFormatted);
          setFilteredRows5([]);
          setTLFilteredRows5([]);
          break;
        default:
          break;
      }
    };

    setDefaultFromDate();
  };

  useEffect(() => {
    const filterRowsByDate = (tabIndex) => {
      let fromDate, toDate, setFilteredRows, statusFilter, setTLFilteredRows;
      switch (tabIndex) {
        case 0:
          fromDate = fromDate1;
          toDate = toDate1;
          setFilteredRows = setFilteredRows1;
          setTLFilteredRows = setTLFilteredRows1;
          statusFilter = "open";
          break;
        case 1:
          fromDate = fromDate2;
          toDate = toDate2;
          setFilteredRows = setFilteredRows2;
          setTLFilteredRows = setTLFilteredRows2;
          statusFilter = "quotation";
          break;
        case 2:
          fromDate = fromDate3;
          toDate = toDate3;
          setFilteredRows = setFilteredRows3;
          setTLFilteredRows = setTLFilteredRows3;
          statusFilter = "escalation";
          break;
        case 3:
          fromDate = fromDate4;
          toDate = toDate4;
          setFilteredRows = setFilteredRows4;
          setTLFilteredRows = setTLFilteredRows4;
          statusFilter = "order success";
          break;
        case 4:
          fromDate = fromDate5;
          toDate = toDate5;
          setFilteredRows = setFilteredRows5;
          setTLFilteredRows = setTLFilteredRows5;
          statusFilter = "order cancel";
          break;
        default:
          break;
      }

      //-console.log(`fromDate: ${fromDate}, toDate: ${toDate}`);


      if (fromDate && toDate) {
        const filtered = rows.filter((row) => {
          const createdAt = moment(row.item.createdAt, "YYYY-MM-DD hh:mm A");
          // console.log(`Row createdAt: ${row.item.createdAt}, fromDate: ${fromDate}, toDate: ${toDate}`);

          const isWithinDateRange = createdAt.isBetween(
            moment(fromDate).startOf("day"),
            moment(toDate).endOf("day"),
            null,
            "[]"
          );

          // console.log(`Lead status: ${row.item.status.leadStatus}, Expected: ${statusFilter}`);
          return isWithinDateRange && row.item.status.leadStatus === statusFilter;
        });

        // console.log(`Filtered rows for tab ${tabIndex}:`, filtered);
        setFilteredRows(filtered);
      }

      if (fromDate && toDate) {
        const filteredTL = TLTeamUsers.filter((row) => {
          // console.log("TLTeamUsers", TLTeamUsers);

          const createdAt = moment(row.item.createdAt, "YYYY-MM-DD hh:mm A");
          // console.log(`Row createdAt: ${row.item.createdAt}, fromDate: ${fromDate}, toDate: ${toDate}`);

          const isWithinDateRange = createdAt.isBetween(
            moment(fromDate).startOf("day"),
            moment(toDate).endOf("day"),
            null,
            "[]"
          );

          // console.log(`Lead status: ${row.item.status.leadStatus}, Expected: ${statusFilter}`);
          return isWithinDateRange && row.item.status.leadStatus === statusFilter;
        });

        // console.log(`Filtered rows for tab ${tabIndex}:`, filteredTL);
        setTLFilteredRows(filteredTL);
      }
    };

    switch (tabIndex) {
      case 0:
        if (searchClicked1) {
          filterRowsByDate(0);
        }
        break;
      case 1:
        if (searchClicked2) {
          filterRowsByDate(1);
        }
        break;
      case 2:
        if (searchClicked3) {
          filterRowsByDate(2);
        }
        break;
      case 3:
        if (searchClicked4) {
          filterRowsByDate(3);
        }
        break;
      case 4:
        if (searchClicked5) {
          filterRowsByDate(4);
        }
        break;
      default:
        break;
    }
  }, [
    fromDate1,
    toDate1,
    searchClicked1,
    fromDate2,
    toDate2,
    searchClicked2,
    fromDate3,
    toDate3,
    searchClicked3,
    fromDate4,
    toDate4,
    searchClicked4,
    fromDate5,
    toDate5,
    searchClicked5,
    tabIndex,
    rows,
  ]);

  const filteredEscalationUsers = getUser.filter(
    (user) => user.name !== adminName
  );

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const hasLeadSource = !row.lead_source;
    const quotationalHistories = row.item.quotationalHistories;
    //-console.log("productList", quotationalHistories);
    const lastQuotationalHistories = quotationalHistories[quotationalHistories.length - 1]
    //-console.log("lastQuotationalHistories", lastQuotationalHistories);

    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          onDoubleClick={() => handleEditingLiveMarketing(row.item)}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {hasLeadSource ? (
              <LocationOnIcon color="primary" />
            ) : (
              ""
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.cus_name}
          </TableCell>
          <TableCell>{row.lead_source ? row.lead_source : "-"}</TableCell>
          <TableCell>{row.item.assignedTo ? row.item.assignedTo.name : "-"}</TableCell>
          <TableCell>{row.item.createdAt}</TableCell>
          <TableCell>{row.item.buildingRating}</TableCell>
          <TableCell>{row.item.customerRating}</TableCell>
          <TableCell>{row.item.pincode ? row.item.pincode.pincode : ""}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Products
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-center">Product Name</TableCell>
                      <TableCell className="text-center">Unit</TableCell>
                      <TableCell className="text-center">Quantity</TableCell>
                      <TableCell className="text-center">Given Price</TableCell>
                      <TableCell className="text-center">Expected Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(lastQuotationalHistories) && lastQuotationalHistories.length > 0 ? (
                      lastQuotationalHistories.map((productList, index) => (
                        <TableRow key={index}>
                          <TableCell className="text-center" component="th" scope="row">
                            {productList?.productType?.product?.product || 'N/A'} {productList?.productType?.brand?.brand || 'N/A'} {productList?.productType?.productTypes || 'N/A'}
                          </TableCell>
                          <TableCell className="text-center">{productList?.unit?.unit || 'N/A'}</TableCell>
                          <TableCell className="text-center">{productList?.quantity || 'N/A'}</TableCell>
                          <TableCell className="text-center" >{productList?.givenPrice || 'N/A'}</TableCell>
                          <TableCell className="text-center">{productList?.expectedPrice || 'N/A'}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} className="text-center">No product available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="container">
        {/* header */}
        <div class="page-header">
          <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
              <i class="fa fa-eye"></i>
            </span>{" "}
            Lead Monitoring
          </h3>
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>

        {/* off canvas  */}
        <div
          className={`offcanvas offcanvas-end   ${showLiveForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              Lead Live Marketing Form
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleLiveForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="card">
              <div className="card-body">
                <form
                  className="form-inline"
                  style={{
                    display:
                      showLiveForm || (editingLiveMarketing ? "block" : "none"),
                  }}
                >
                  <div className="row">
                    {/* Customer Name */}
                    <div className="col-md-3">
                      <div className="d-flex gap-2">
                        <div className="form-group w-100">
                          <label>Customer Name</label>
                          <input
                            type="text"
                            placeholder="Search a name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            className="form-control mb-2"
                            onClick={() => setShowClearButton(true)}
                            required
                            disabled
                          />
                          {searchName && (
                            <div className="form-group">
                              <ul>
                                {filteredCustomers.map((customer) => (
                                  <li
                                    key={customer._id}
                                    onClick={() => handleNameSelect(customer)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {`${customer.name} - ${customer.alterMobile} - ${customer.mobile}`}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          {showClearButton && (
                            <button
                              onClick={() => clearName(searchName)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              type="button"
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </div>
                    </div>


                    {/* Lead Reference */}
                    <div className="col-md-3">
                      <div className="d-flex gap-2">
                        <div className="form-group w-100">
                          <label htmlFor="pincode">Lead Reference</label>
                          <input
                            type="text"
                            value={searchReference}
                            placeholder="Search a lead reference name"
                            onChange={(e) => setSearchReference(e.target.value)}
                            className="form-control mb-2"
                            onClick={() => setShowRefButton(true)}
                            disabled
                          />
                          {/* filter */}
                          {searchReference && (
                            <div className="form-group">
                              {filteredRefCustomers.map((customer) => (
                                <div
                                  key={customer._id}
                                  onClick={() => handleRefSelect(customer)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {customer.name} - {customer.alterMobile} -{" "}
                                  {customer.mobile}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        {/* lead count */}
                        <div className="col-md-2 mb-2">
                          <div className="form-group">
                            <label htmlFor="customerCount">Lead Count</label>
                            <div className="d-flex">
                              <input
                                className="form-control"
                                id="customerCount"
                                value={customerCount !== null ? customerCount : ''}
                                onChange={(e) => setCustomerCount(e.target.value)}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        {/* btn */}
                        <div>
                          {showRefButton && (
                            <button
                              onClick={() => clearRef(searchReference)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              type="button"
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Additional Customers Data */}
                  <div className="row">
                    {/* Customer Category */}
                    <div className="col-md-4">
                      <div className="form-group w-100">
                        <label>Customer Category</label>
                        <input
                          type="text"
                          placeholder="Search a Category"
                          value={searchService}
                          onChange={(e) => setSearchService(e.target.value)}
                          className="form-control mb-2"
                          onClick={() => setShowClearButton(true)}
                          disabled
                        />
                        {searchService && (
                          <select
                            className="js-example-basic-multiple"
                            multiple
                            style={{ width: "100%" }}
                          >
                            {filteredCustomerServices.map((service) => (
                              <option
                                key={service._id}
                                value={service._id}
                                onClick={() =>
                                  handleCustomerServicesClick(service._id)
                                }
                              >
                                {`${service.customerCategory.customerCategory} - ${service.workNature.workNature} - ${service.services}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>

                    {/* Search Additional Customer Name */}
                    <div className="col-md-4">
                      <div className="form-group w-100">
                        <label>Customer Name</label>
                        <input
                          type="text"
                          placeholder="Search a name"
                          value={searchAdditionalCustomer}
                          onChange={(e) =>
                            setSearchAdditionalCustomer(e.target.value)
                          }
                          className="form-control mb-2"
                          onClick={() => setShowClearButton(true)}
                          disabled
                        />
                        {searchAdditionalCustomer && (
                          <select
                            className="js-example-basic-multiple"
                            multiple
                            style={{ width: "100%" }}
                          >
                            {filteredAddCustomers.map((addCustomer) => (
                              <option
                                key={addCustomer._id}
                                value={addCustomer._id}
                                onClick={() =>
                                  handleAdditionalNameSelect(addCustomer._id)
                                }
                              >
                                {`${addCustomer.name} - ${addCustomer.alterMobile} - ${addCustomer.mobile}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Dynamic Dropdowns */}
                  <div>
                    {selectedServiceType.map((service, index) => (
                      <div key={service.id}>
                        <div className="d-flex gap-3">
                          {/* Selected Service */}
                          <div className="form-group col-md-4">
                            <label htmlFor={`selectedService-${index}`}>
                              Service
                            </label>
                            <input
                              type="text"
                              id={`selectedService-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedServiceList[index]}
                              disabled
                            />
                          </div>
                          {/* Selected Additional Customer Name */}
                          <div className="form-group col-md-3">
                            <label htmlFor={`selectedAdditionalName-${index}`}>
                              Name
                            </label>
                            <input
                              type="text"
                              id={`selectedAdditionalName-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedAdditionalName[index]?.name || ""}
                              disabled
                            />
                          </div>
                          {/* Corresponding Mobile Number */}
                          <div className="form-group col-md-3">
                            <label htmlFor={`correspondingMobile-${index}`}>
                              Mobile
                            </label>
                            <input
                              type="text"
                              id={`correspondingMobile-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedAdditionalName[index]?.mobile || ""}
                              disabled
                            />
                          </div>
                          {/* Remove Button */}
                          <div>
                            <button
                              onClick={() => removeDropdown(index)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              disabled
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {/* Building Structure */}
                    <div className="col-md-4 mb-2">
                      <div className="form-group">
                        <label>Building Structure</label>
                        <select
                          className="form-select form-control"
                          value={selectedBuildStructure}
                          onChange={(e) =>
                            setSelectedBuildStructure(e.target.value)
                          }
                          disabled
                        >
                          {getBuildingStructure.map((build) => (
                            <option key={build._id} value={build._id}>
                              {build.buildingStructure}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Building Rating */}
                    <div className="col-md-4 mb-2">
                      <label>Building Rating</label>
                      <StarRatings
                        rating={buildingRating}
                        starRatedColor="blue"
                        changeRating={handleBuildingRatingChange}
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        disabled
                      />
                    </div>
                    {/* customer rating */}
                    <div className="col-md-4 mb-2">
                      <label>Customer Rating</label>
                      <StarRatings
                        rating={customerRating}
                        starRatedColor="blue"
                        changeRating={handleCustomerRatingChange}
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        disabled
                      />
                    </div>
                    {/* Project value */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project value</label>
                        <div className="d-flex">
                          <input
                            className="form-control"
                            id="projectValue"
                            value={projectValue}
                            placeholder="Enter a project value"
                            onChange={(e) => setProjectValue(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    {/* Project duration*/}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project duration</label>
                        <input
                          className="form-control"
                          id="projectValue"
                          value={projectDuration}
                          placeholder="Enter a project duration"
                          onChange={(e) => setProjectDuration(e.target.value)}
                          disabled
                        />
                      </div>
                    </div>

                    {/* selected status */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status</label>
                        <select
                          className="form-select form-control"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          required
                          disabled
                        >
                          <option value="">Select a status</option>
                          {getLeadStatus.map((status) => (
                            <option key={status._id} value={status._id}>
                              {status.leadStatus}
                            </option>
                          ))}
                          {getLeadStatus.some(status =>
                            status._id === "order success" &&
                            editingLiveMarketing &&
                            editingLiveMarketing.quotationApproval.some(approval =>
                              approval.approvalStatus === "approval"
                            )
                          ) && (
                              <option value="order-success">Order Success</option>
                            )}
                        </select>
                      </div>
                    </div>
                    {selectedStatus === cancelStatusId && (
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          {/* Form */}
                          <label htmlFor="inlineFormInputName2">Cancel reason</label>
                          <select
                            className="form-select form-control"
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            required
                            disabled
                          >
                            <option value="">Select a Reason</option>
                            {getLeadCancelReason[0]?.cancelReason.map((reason, index) => (
                              <option key={index} value={reason}>
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project</label>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            onChange={handleProjectCheckboxChange}
                            checked={isProjectChecked}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* Pincode */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            placeholder="Enter a pincode"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            onKeyUp={handlePincodeChange}
                            disabled
                          />
                        </div>
                      </div>
                      {/* City */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="city"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            onKeyUp={handlePincodeChange}
                            disabled
                          />
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="state"
                            placeholder="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            onKeyUp={handlePincodeChange}
                            disabled
                          />
                        </div>
                      </div>
                      {/* Area Name */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="city">Area Name</label>
                          <select
                            id="customerCredits"
                            className="form-select form-control"
                            value={selectedAreaName}
                            onChange={(e) => setSelectedAreaName(e.target.value)}
                            disabled
                          >
                            <option value="">Select Area</option>
                            {areas.map((area, index) => (
                              <option key={index} value={area}>
                                {area}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* address */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <input
                            className="form-control"
                            rows={4}
                            id="address"
                            value={address}
                            placeholder="Enter a address"
                            onChange={(e) => setAddress(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    {/* image */}
                    {selectedImage && (
                      <div>
                        <label>Captured image</label>
                        <img
                          src={selectedImage}
                          alt="Selected Image"
                          className="d-block m-3 ms-0"
                        />
                      </div>
                    )}
                  </div>

                  {/* Map */}
                  <div>
                    <button onClick={getCurrentLocation}>Get Current Location</button>
                    {loadScriptLoaded ? renderMap() : <p>Loading...</p>}
                    {loadError && <p>Error loading map!</p>}
                  </div>

                  <canvas ref={canvasRef} style={{ display: "none" }} />

                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={getAddTabValue}>
                      <Box>
                        <TabList
                          onChange={handleAddTabChange}
                          aria-label="lab API tabs example"
                          textColor="secondary"
                          indicatorColor="secondary"
                        >
                          <Tab label="FollowUp" value="1" />
                          <Tab label="Quotation History's" value="2" />
                        </TabList>
                      </Box>
                      {/* Add followUP */}
                      <TabPanel value="1" onClick={() => handleAddChangeTab(0)}>
                        <div className="col-md-2">
                          <button
                            type="button"
                            onClick={addAdditionalField}
                            className="btn btn-gradient-success btn-rounded btn-icon"
                          >
                            <i class="fa fa-calendar"></i>
                          </button>
                          &nbsp; New Follow
                        </div>
                        {/* Additional field */}

                        <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                          FollowUp History
                        </h4>
                        <h4
                          className="text-info"
                          style={{ marginBottom: "5px" }}
                        >{`Total FollowUps - ${additionalFields.length}`}</h4>
                        {additionalFields.map((field, index) => (
                          <div key={field.id} className="card follow-cards shadow">
                            <div class="card-header">
                              <div class="row">
                                <div class="col">
                                  <label class="badge badge-info me-4">
                                    Follow {index + 1}
                                  </label>
                                  <label class="badge badge-info me-4">
                                    {new Date().toLocaleString() + ""}
                                  </label>
                                  <label class="badge badge-info me-4">
                                    {adminName}
                                  </label>
                                </div>
                                <div className="col text-end">
                                  {/* Remove button */}
                                  <button
                                    type="button"
                                    onClick={() => removeAdditionalField(index)}
                                    className="btn btn-sm badge-gradient-danger"
                                    aria-label="Close"
                                    disabled
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div class="row">
                                {/* Next follow-up date picker */}
                                <div className="col-md-4">
                                  <label>Next Follow-Up</label>
                                  <input
                                    type="datetime-local"
                                    class="form-control"
                                    placeholder="Next Follow Date"
                                    value={
                                      field.nextFollowupDate
                                        ? moment(field.nextFollowupDate).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                        : ""
                                    }
                                    required
                                    onChange={(e) =>
                                      handleDateChange(
                                        new Date(e.target.value),
                                        index,
                                        "nextFollowup"
                                      )
                                    }
                                    disabled
                                  />
                                </div>
                                {/* Remarks */}
                                <div className="col-md-4">
                                  <label htmlFor={`remarks-${index}`}>Remarks</label>
                                  <textarea
                                    id={`remarks-${index}`}
                                    className="form-control"
                                    placeholder="Enter remarks"
                                    rows="3"
                                    value={field.remarks}
                                    required
                                    onChange={(e) =>
                                      handleRemarksChange(index, e.target.value)
                                    }
                                    disabled
                                  ></textarea>
                                </div>

                                {/* Upload Image 1*/}
                                {/* <div className="col-md-4">
                                <label>Add Photo</label>
                                <br />
                                {!capturedImage[index] && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={openModal}
                                  >
                                    Camera
                                  </button>
                                )}
                                {capturedImage[index] && (
                                  <div className="image-preview row">
                                    <div className="image-preview-item col-md-4">
                                      {!capturedImage[index]
                                        .toString()
                                        .match("data") ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_DATA_SERVER +
                                            capturedImage[index]
                                          }
                                          alt={`Captured ${index}`}
                                        />
                                      ) : (
                                        <img
                                          src={capturedImage[index]}
                                          alt={`Captured ${index}`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div> */}
                                {/* Upload Image 2 */}
                                <div className="col-md-4">
                                  {!field.imageSource && (
                                    <>
                                      <input className="form-control" type="file" accept="image/*" onChange={(e) => setImageFile(e.target.files[0])}></input>
                                      <button type="button" className="btn btn-outline-success btn-icon-text" onClick={() => handleUpload(index)} disabled>
                                        <i className="mdi mdi-upload btn-icon-prepend"></i>
                                        Upload Image
                                      </button>
                                    </>
                                  )}
                                  {field.imageSource && (
                                    <>
                                      <a href={process.env.REACT_APP_DATA_SERVER + field.imageSource} target="_blank" rel="noopener noreferrer">
                                        <img
                                          src={process.env.REACT_APP_DATA_SERVER + field.imageSource}
                                          width="150px"
                                          alt="captured image"
                                          className="d-block m-3 ms-0"
                                          onClick={() => handleImageClick(process.env.REACT_APP_DATA_SERVER + field.imageSource)}
                                          disabled
                                        />
                                      </a>
                                    </>
                                  )}
                                  {/* Image model */}


                                </div>
                              </div>
                              <div class="row">
                                <div className="col-md-4 text-center align-self-center">
                                  <div class="form-check form-check-success">
                                    <label class="form-check-label text-start">
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        onChange={(e) =>
                                          handleEscaltionCheckbox(
                                            index,
                                            e.target.checked
                                          )
                                        }
                                        checked={isEscalateChecked[index] || false}
                                        disabled
                                      ></input>
                                      Escalation <i class="input-helper"></i>
                                    </label>
                                  </div>
                                </div>
                                {/* Excalation */}
                                <div className="col-4">
                                  {/* Form */}
                                  <h4 className="card-title"></h4>
                                  <div className="form-group">
                                    <label htmlFor="inlineFormInputName2">
                                      Escalation To
                                    </label>
                                    <select
                                      className="form-select form-control"
                                      value={selectedEscalationUser[index]}
                                      onChange={(e) =>
                                        handleEscalationChange(index, e.target.value)
                                      }
                                      disabled={isDropdownDisabled}
                                    >
                                      <option value="">Select Team User</option>
                                      {filteredEscalationUsers.map((teamUser) => (
                                        <option key={teamUser._id} value={teamUser._id}>
                                          {teamUser.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {/* sample  */}
                                <div className="col-md-4 text-center align-self-center">
                                  <div class="form-check form-check-success">
                                    <label class="form-check-label text-start">
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        onChange={(e) =>
                                          handleSampleCheckbox(index, e.target.checked)
                                        }
                                        checked={isSampleChecked[index] || false}
                                        disabled
                                      ></input>
                                      Sample issues <i class="input-helper"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </TabPanel>
                      {/* Quotational history */}
                      <TabPanel value="2" onClick={() => handleAddChangeTab(1)}>
                        {/* Products */}
                        <div className="col-md-4">
                          <div className="form-group w-100">
                            <label>Search Product</label>
                            <input
                              type="text"
                              placeholder="Search by product"
                              value={searchProduct}
                              onChange={(e) => setSearchProduct(e.target.value)}
                              className="form-control"
                            />
                            {searchProduct && (
                              <select
                                className="js-example-basic-multiple"
                                multiple
                                style={{ width: '100%' }}
                              >
                                {filteredProductType.map((product) => {
                                  const productCategory = getProductCategory.find(
                                    (cat) => cat._id === product.product.productCategory
                                  )?.productCategory || 'Unknown';

                                  return (
                                    <option
                                      key={product._id}
                                      value={product._id}
                                      onClick={() => handleProductSelect(product._id)}
                                      disabled
                                    >
                                      {productCategory} - {product.product.product} - {product.productTypes} - {product.brand.brand}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        </div>
                        <div className="">
                          {selectedProductList.length > 0 && (
                            <div className="">
                              {selectedProductList.map((product, index) => (
                                <div key={index} className="row">
                                  <div className="form-group col-md-3">
                                    <label>Product Category</label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={product}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label htmlFor="workNature">Unit</label>
                                    <select
                                      className="form-select"
                                      value={selectedUnit[index]}
                                      onChange={(e) =>
                                        handleUnitChange(index, e.target.value)
                                      }
                                      disabled
                                    >

                                      <option value="">selected a unit</option>
                                      {getUnit.map((unit) => (
                                        <option key={unit._id} value={unit._id}>
                                          {unit.unit}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-2">
                                    <label>Quantity</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={quantity[index]}
                                      onChange={(e) =>
                                        handleQuantityChange(index, e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-md-2">
                                    <label>Given Price</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={givenPrice[index]}
                                      onChange={(e) =>
                                        handleGivePrice(index, e.target.value)
                                      }
                                      disabled
                                    />
                                  </div>
                                  {/* expected price */}
                                  <div className="form-group col-md-2">
                                    <label>Asked price</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={expectedPrice[index]}
                                      onChange={(e) =>
                                        handleExpectedPrice(index, e.target.value)
                                      }
                                      disabled
                                    />
                                  </div>

                                  <div className="form-group col-md-1">
                                    <button
                                      onClick={() => removeDropdown(index)}
                                      className="btn  btn-sm badge-gradient-danger"
                                      aria-label="Close"
                                      style={{ marginTop: "30px" }}
                                      disabled
                                    >
                                      &times;
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="button"
                            onClick={handleCreateQuotationalHistory}
                            className="btn btn-gradient-success btn-rounded btn-icon"
                            disabled
                          >
                            <i className="fa fa-calendar"></i>
                          </button>
                          &nbsp; Add Quotational
                        </div>
                        <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                          Quotational History
                        </h4>
                        {/* Quotational history */}
                        <div className="col-md-12">
                          {quotationalHistories && quotationalHistories.map((quotationalHistory, index) => (
                            <div key={index} className="mt-4">
                              <div className="card follow-cards shadow">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Product Category</th>
                                      <th>Unit</th>
                                      <th>Quantity</th>
                                      <th>Given Price</th>
                                      <th>Expected Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {quotationalHistory.map((product, idx) => (
                                      <tr key={idx}>
                                        <td>{product.productType}</td>
                                        <td>{product.unit}</td>
                                        <td>{product.quantity}</td>
                                        <td>{product.givenPrice}</td>
                                        <td>{product.expectedPrice}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                                {/* Compute the last approval status */}
                                {editingLiveMarketing && editingLiveMarketing.quotationApproval.length > 0 ? (
                                  (() => {
                                    const lastApproval = editingLiveMarketing.quotationApproval[editingLiveMarketing.quotationApproval.length - 1];
                                    return lastApproval.approvalStatus === "approved" ? (
                                      <button
                                        type="button"
                                        onClick={openQModal}
                                        className="btn btn-sm btn-success"
                                      >
                                        Generate Quotation
                                      </button>
                                    ) : (
                                      <p className="ps-2 fw-2" style={{ "color": "green" }}>{lastApproval.approvalStatus}</p>
                                    );
                                  })()
                                ) : (
                                  <p>No quotation available</p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>

                  {/* submit button */}
                  <div className="row">
                    <div className="col-md-2">
                      <button type="submit" className="btn btn-sm btn-primary">
                        {" "}
                        {editingLiveMarketing
                          ? "Update LiveMarketing"
                          : "Add LiveMarketing"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div >
        </div >
        {/* download quotation */}
        {
          QModal && (
            <div
              className="modal quotation-generator"
              tabIndex="-1"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body bg-white">
                    <button
                      type="button"
                      className="btn-close float-end"
                      onClick={closeQModal}
                      aria-label="Close"
                    ></button>
                    <QuotationGenerator quotation_Data={editingLiveMarketing} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* Tab */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={getTabValue}>
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                width: '100%',
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ display: 'flex', minWidth: 'max-content' }}
              >
                <Tab label="Open Leads" value="1" />
                <Tab label="Quotation" value="2" />
                <Tab label="Esclation" value="3" />
                <Tab label="Converted" value="4" />
                <Tab label="Cancelled" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(0)}>
              <div className="container px-0">
                {/* Table - live marketing */}
                <div className="row">
                  <div className="col-md-4">
                    <label>From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fromDate1}
                      onChange={(e) => handleFromDateChange(0, e)}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={toDate1}
                      onChange={(e) => handleToDateChange(0, e)}
                    />
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn btn-primary mt-4"
                      onClick={() => handleSearchClick(0)}
                    >
                      Search date
                    </button>
                    {searchClicked1 && (
                      <button
                        onClick={() => handleClearFilter(0)}
                        className="btn btn-sm badge-gradient-danger"
                        aria-label="Close"
                        style={{ marginLeft: "10px", marginTop: "30px" }}
                      >
                        &times;
                      </button>
                    )}
                  </div>
                </div>

                {/* OPEN */}
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell></TableCell>
                        <TableCell>Customer Name</TableCell>
                        <TableCell>Lead Source</TableCell>
                        <TableCell>Assigned To</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Customer Rating</TableCell>
                        <TableCell>Building Rating</TableCell>
                        <TableCell>Pincode</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userAdminRole !== "not an admin" ? (
                        // If the user is an admin
                        searchClicked1 ? (
                          filteredRows1
                            .filter(row => row.item.status.leadStatus === "open")
                            .map(row => (
                              <Row key={row.name} row={row} />
                            ))
                        ) : null
                      ) : (
                        // If the user is not an admin
                        searchClicked1 ? (
                          TLFilteredRows1
                            .filter(row => row.item.status.leadStatus === "open")
                            .map(row => (
                              <Row key={row.name} row={row} />
                            ))
                        ) : null
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </TabPanel>
            {/* Quotation */}
            <TabPanel value="2" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(1)}>
              {/* Quotation */}
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate2}
                    onChange={(e) => handleFromDateChange(1, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate2}
                    onChange={(e) => handleToDateChange(1, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(1)}
                  >
                    Search date
                  </button>
                  {searchClicked2 && (
                    <button
                      onClick={() => handleClearFilter(1)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell></TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Lead Source</TableCell>
                      <TableCell>Assigned To</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer Rating</TableCell>
                      <TableCell>Building Rating</TableCell>
                      <TableCell>Pincode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userAdminRole !== "not an admin" ? (
                      // If the user is an admin
                      searchClicked2 ? (
                        filteredRows2
                          .filter(row => row.item.status.leadStatus === "quotation")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null
                    ) : (
                      // If the user is not an admin
                      searchClicked2 ? (
                        TLFilteredRows2
                          .filter(row => row.item.status.leadStatus === "quotation")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            {/* escalation */}
            <TabPanel value="3" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(2)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate3}
                    onChange={(e) => handleFromDateChange(2, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate3}
                    onChange={(e) => handleToDateChange(2, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(2)}
                  >
                    Search date
                  </button>
                  {searchClicked3 && (
                    <button
                      onClick={() => handleClearFilter(2)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell></TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Lead Source</TableCell>
                      <TableCell>Assigned To</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer Rating</TableCell>
                      <TableCell>Building Rating</TableCell>
                      <TableCell>Pincode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userAdminRole !== "not an admin" ? (
                      // If the user is an admin
                      searchClicked3 ? (
                        filteredRows3
                          .filter(row => row.item.status.leadStatus === "escalation")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null
                    ) : (
                      // If the user is not an admin
                      searchClicked3 ? (
                        TLFilteredRows3
                          .filter(row => row.item.status.leadStatus === "escalation")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            {/* order success */}
            <TabPanel value="4" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(3)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate4}
                    onChange={(e) => handleFromDateChange(3, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate4}
                    onChange={(e) => handleToDateChange(3, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(3)}
                  >
                    Search date
                  </button>
                  {searchClicked4 && (
                    <button
                      onClick={() => handleClearFilter(3)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell></TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Lead Source</TableCell>
                      <TableCell>Assigned To</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer Rating</TableCell>
                      <TableCell>Building Rating</TableCell>
                      <TableCell>Pincode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userAdminRole !== "not an admin" ? (
                      // If the user is an admin
                      searchClicked4 ? (
                        filteredRows4
                          .filter(row => row.item.status.leadStatus === "order success")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null
                    ) : (
                      // If the user is not an admin
                      searchClicked4 ? (
                        TLFilteredRows4
                          .filter(row => row.item.status.leadStatus === "order success")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            {/* cancel */}
            <TabPanel value="5" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(4)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate5}
                    onChange={(e) => handleFromDateChange(4, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate5}
                    onChange={(e) => handleToDateChange(4, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(4)}
                  >
                    Search date
                  </button>
                  {searchClicked5 && (
                    <button
                      onClick={() => handleClearFilter(4)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell></TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Lead Source</TableCell>
                      <TableCell>Assigned To</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer Rating</TableCell>
                      <TableCell>Building Rating</TableCell>
                      <TableCell>Pincode</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userAdminRole !== "not an admin" ? (
                      // If the user is an admin
                      searchClicked5 ? (
                        filteredRows5
                          .filter(row => row.item.status.leadStatus === "cancel")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null // If searchClicked5 is false, render nothing for admins
                    ) : (
                      // If the user is not an admin
                      searchClicked5 ? (
                        TLFilteredRows5
                          .filter(row => row.item.status.leadStatus === "cancel")
                          .map(row => (
                            <Row key={row.name} row={row} />
                          ))
                      ) : null // If searchClicked5 is false, render nothing for non-admins
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </React.Fragment >
  );
};

function createData(
  cus_name,
  lead_source,
  lead_date,
  city,
  pincode,
  quotationalHistories,
  item
) {
  return {
    cus_name,
    lead_source,
    lead_date,
    city,
    pincode,
    quotationalHistories,
    item,
  };
}

export default LeadLiveMonitoring;
