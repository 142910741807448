import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import api from "../Api";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckIcon from '@mui/icons-material/Check';
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import "../MediaQuery.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import StarRatings from "react-star-ratings";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import QuotationGenerator from "./QuotationGenerator";
import Loader from './Loader';
import './LiveMarketing.css';
import CustomerCreation from "./CustomerCreation";

const markers = [
  {
    id: 1,
    name: "Qobustan",
    position: { lat: 40.0709493, lng: 49.3694411 },
  },
];


const LiveMarketing = ({ adminId, adminName }) => {
  const [lastDate, setLastDate] = useState("")
  const [searchTableData, setSearchTableData] = useState('');
  const [getProductCategory, setGetProductCategory] = useState([]);
  const [getLeadCancelReason, setGetLeadCancelReason] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [showProdSuggestModal, setShowProdSuggestModal] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(true);
  const [customerCount, setCustomerCount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [getTabValue, setTabValue] = useState("1");
  const [getAddTabValue, setAddTabValue] = useState("1");
  const [getCustomerCreation, setGetCustomerCreation] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [getLeadSource, setGetLeadSource] = useState([]);
  const [searchReference, setSearchReference] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedRefId, setSelectedRefId] = useState(null);
  const [showRefButton, setShowRefButton] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [showAddClearButton, setShowAddClearButton] = useState(false);
  const [getServices, setGetServices] = useState([]);
  const [searchService, setSearchService] = useState("");
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [selectedAdditionalName, setSelectedAdditionalName] = useState([]);
  const [searchAdditionalCustomer, setSearchAdditionalCustomer] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [getPincode, setGetPincode] = useState([]);
  const [getProductType, setGetProductType] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState([]);
  const [selectedProdCatProduct, setSelectedProdCatProduct] = useState([]);
  const [selectedProductType, setSelectedproductType] = useState([]);
  const [selectedProductDescription, setSelectedProductDescription] = useState([]);
  const [alertMessages, setAlertMessages] = useState([]);
  const [getUnit, setGetUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cameraStream, setCameraStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState([]);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showLiveForm, setShowLiveForm] = useState(false);
  const [getLiveMarketing, setGetLiveMarketing] = useState([]);
  const [editingLiveMarketing, setEditingLiveMarketing] = useState(null);
  const [deleteLiveMarketing, setDeleteLiveMarketing] = useState(null);
  const [getBuildingStructure, setGetBuildingStructure] = useState([]);
  const [selectedBuildStructure, setSelectedBuildStructure] = useState(null);
  const [buildingRating, setBuildingRating] = useState(0);
  const [customerRating, setCustomerRating] = useState(0);
  const [projectValue, setProjectValue] = useState("");
  const [getImage, setGetImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [projectDuration, setProjectDuration] = useState("");
  const [isProjectChecked, setIsProjectChecked] = useState("");
  const [additionalFields, setAdditionalFields] = useState([]);
  const [approvalby, setApprovalBy] = useState("");
  const [adminApproval, setAdminApproval] = useState("");
  const [isEscalateChecked, setIsEscalateChecked] = useState(
    Array(additionalFields.length).fill(false));
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(true);
  const [selectedEscalationUser, setSelectedEscalationUser] = useState("");
  const [getUser, setGetUser] = useState([]);
  const [getTeams, setGetTeams] = useState([]);
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [getLeadStatus, setGetLeadStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProjectDuration, setSelectedProjectDuration] = useState("");
  const [rows, setGridRows] = useState([]);
  const [selectedAddCustomerType, setSelectedAddCustomerType] = useState([]);
  const [givenPrice, setGivenPrice] = useState("");
  const [expectedPrice, setExpectedPrice] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [address, setAddress] = useState("");
  const [getFollowUp, setGetFollowUp] = useState([]);
  const [getTransferUser, setGetTransferUser] = useState([]);
  const [getImageFile, setImageFile] = useState();
  const [cancelStatusId, setCancelStatusId] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabAddIndex, setAddTabIndex] = useState(0);
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [searchClicked1, setSearchClicked1] = useState(false);
  const [filteredRows1, setFilteredRows1] = useState([]);

  const [fromDate2, setFromDate2] = useState("");
  const [toDate2, setToDate2] = useState("");
  const [searchClicked2, setSearchClicked2] = useState(false);
  const [filteredRows2, setFilteredRows2] = useState([]);

  const [fromDate3, setFromDate3] = useState("");
  const [toDate3, setToDate3] = useState("");
  const [searchClicked3, setSearchClicked3] = useState(false);
  const [filteredRows3, setFilteredRows3] = useState([]);

  const [fromDate4, setFromDate4] = useState("");
  const [toDate4, setToDate4] = useState("");
  const [searchClicked4, setSearchClicked4] = useState(false);
  const [filteredRows4, setFilteredRows4] = useState([]);

  const [fromDate5, setFromDate5] = useState("");
  const [toDate5, setToDate5] = useState("");
  const [searchClicked5, setSearchClicked5] = useState(false);
  const [filteredRows5, setFilteredRows5] = useState([]);
  const [quotationStatusId, setQuotationStatusId] = useState(null);

  const [isSampleChecked, setIsSampleChecked] = useState(
    Array(additionalFields.length).fill(false)
  );
  const [QModal, setQModal] = useState(false);
  const [get24hrsTransferUser, setGet24hrsTransferUser] = useState([]);
  const [showQuotationHistory, setShowQuotationHistory] = useState(false);
  const [quotationalHistories, setQuotationalHistories] = useState([]);
  const [modalImage, setModalImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [getPincodeObjectId, setGetPincodeObjectId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const handleChangeTab = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const fetchLeadCancelReason = async () => {
      try {
        const response = await api.get("/cancelReason");
        setGetLeadCancelReason(response.data);
      } catch (error) {
        //-//-console.log(error);
      }
    };
    fetchLeadCancelReason();
  }, []);

  useEffect(() => {
    const fetchProductCategory = async () => {
      try {
        const response = await api.get("/productCategory");
        setGetProductCategory(response.data);
      } catch (error) {
        //-console.error("Error fetching product categories:", error);
      }
    };
    fetchProductCategory();
  }, []);

  const handleProductSuggest = () => {
    setShowProdSuggestModal(true);

  };


  const handleClose = () => {
    setShowProdSuggestModal(false);
  };


  const { isLoaded: loadScriptLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDPgQr91arVKr9GYB4_-1dSzaCA1XdXckA'
  })

  useEffect(() => {
    const fetchTransferUser = async () => {
      try {
        const response = await api.get("/transferUser");
        //-//-console.log("transfer", response.data);
        setGetTransferUser(response.data.transferTo);
        //-//-console.log(getTransferUser);
      } catch (error) {
        //-console.error("Error fetching transfer user:", error);
      }
    };
    fetchTransferUser();
  }, []);

  useEffect(() => {
    const setDefaultFromToDates = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      setFromDate1(currentDateFormatted);
      setFromDate2(currentDateFormatted);
      setFromDate3(currentDateFormatted);
      setFromDate4(currentDateFormatted);
      setFromDate5(currentDateFormatted);
      setToDate1(currentDateFormatted);
      setToDate2(currentDateFormatted);
      setToDate3(currentDateFormatted);
      setToDate4(currentDateFormatted);
      setToDate5(currentDateFormatted);
    };

    setDefaultFromToDates();
  }, []);

  useEffect(() => {
    const fetchImageAtLive = async () => {
      try {
        const response = await api.get("/liveMarketing");
        setGetImage(response.data.image);
      } catch (error) {
        //-//-console.log(error);
      }
    };
    fetchImageAtLive();
  }, []);

  const fetchFollowUp = async () => {
    try {
      const response = await api.get("/findFollowUpDataLive");
      setGetFollowUp(response.data);
    } catch (error) {
      //-//-console.log(error);
    }
  };

  useEffect(() => {
    fetchFollowUp();
  }, []);

  useEffect(() => { }, [getFollowUp]);

  // After 24 hrs the live transfer to next team users

  const fetchTransferUser = async () => {
    try {
      const response = await api.get("/transferUser");
      setGet24hrsTransferUser(response.data);
    } catch (error) {
      //-//-console.log(error);
    }
  };

  useEffect(() => {
    fetchTransferUser();
  }, []);

  useEffect(() => { }, [get24hrsTransferUser]);


  const fetchPincodeData = async (enteredPincode) => {
    if (/^\d{6}$/.test(enteredPincode)) {
      try {
        const response = await api.get(`/pincode/${enteredPincode}`);
        const { city, state, areas } = response.data;

        setCity(city);
        setState(state);
        setAreas(areas);
      } catch (error) {
        console.error('Error fetching pincode data:', error);
        setCity('');
        setState('');
        setAreas([]);
      }
    } else {
      setCity('');
      setState('');
      setAreas([]);
    }
  };


  const handlePincodeChange = (e) => {
    const enteredPincode = e.target.value;
    setPincode(enteredPincode);
  };

  const handlePincodeBlur = () => {
    fetchPincodeData(pincode);
  };

  const selectedAreaObjectId = async () => {
    try {
      const response = await api.get(`/selectedArea/${selectedAreaName}`);
      setGetPincodeObjectId(response.data.pincodeObjectIds);
    } catch (error) {
      //-console.error("Error fetching pincode object ID:", error);
    }
  };

  useEffect(() => {
    if (selectedAreaName) {
      selectedAreaObjectId();
    }
  }, [selectedAreaName]);


  useEffect(() => {
    const fetchLeadStatus = async () => {
      try {
        const response = await api.get("/leadStatus");
        const leadStatuses = response.data;

        // Find the lead status object with leadStatus 'quotation'
        const quotationStatus = leadStatuses.find(status => status.leadStatus === 'quotation');
        const cancelationStatus = leadStatuses.find(status => status.leadStatus === 'cancel');

        if (cancelationStatus) {
          // If found, you can access its _id
          const cancelationStatusId = cancelationStatus._id;
          setCancelStatusId(cancelationStatusId);
        }

        if (quotationStatus) {
          // If found, you can access its _id
          const quotationStatusId = quotationStatus._id;
          setQuotationStatusId(quotationStatusId);
          //-//-console.log(quotationStatusId);
        } else {
          //-//-console.log("Lead status 'quotation' not found.");
        }

        setGetLeadStatus(leadStatuses);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchLeadStatus();
  }, []);


  const toggleLiveForm = () => {
    setShowLiveForm(!showLiveForm);
    clearForm();
    setShowProdSuggestModal(false);
    setSelectedProdCatProduct([]);
    setSelectedProductCategory([])
    setEditingLiveMarketing(null);
  };

  const toggleCustomerForm = () => {
    setShowCustomerForm(true);
  };


  const getCurrentLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          //-//-console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          //-console.error("Error getting current location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const renderMap = () => {
    return (
      <GoogleMap
        center={currentLocation || { lat: 40.3947365, lng: 49.6898045 }}
        zoom={currentLocation ? 15 : 10}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: '100%', height: '90vh' }}
      >
        {/* Display current location marker if currentLocation is set */}
        {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{
              url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }}
          />
        )}
      </GoogleMap>
    );
  };



  useEffect(() => {
    const fetchProductType = async () => {
      try {
        const response = await api.get("/productTypes");
        setGetProductType(response.data);
      } catch (error) {
        //-//-console.log(error);
      }
    };
    fetchProductType();
  }, []);

  useEffect(() => {
    const fetchBuildingStructre = async () => {
      try {
        const response = await api.get("/buildingStructure");
        setGetBuildingStructure(response.data);
      } catch (error) {
        //-//-console.log(error);
      }
    };
    fetchBuildingStructre();
  }, []);

  const fetchLiveMarketing = async () => {
    try {
      const response = await api.get("/liveMarketing");
      setGetLiveMarketing(response.data);
    } catch (error) {
      //-//-console.log(error);
    }
  };


  useEffect(() => {
    const fetchCount = async () => {
      if (selectedCustomerId) {
        try {
          const response = await api.get(`/customerCount/${selectedCustomerId}`);
          setCustomerCount(response.data.count);
          setLastDate(response.data.createdAt);
        } catch (error) {
          console.error('Error fetching customer count:', error);
        }
      }
    };

    if (selectedCustomerId) {
      fetchCount();
    }
  }, [selectedCustomerId]);


  useEffect(() => {
    fetchLiveMarketing();
  }, []);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/unit");
        setGetUnit(response.data);
      } catch (error) {
        //-//-console.log(error);
      }
    };
    fetchUnits();
  }, []);


  useEffect(() => {
    const fetchLeadSources = async () => {
      try {
        const response = await api.get("/leadSource");
        setGetLeadSource(response.data);
      } catch (error) {
        //-console.error("Error fetching lead sources:", error);
      }
    };

    fetchLeadSources();

  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("/services");
        setGetServices(response.data);
      } catch (error) {
        //-console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  }, [searchService]);

  useEffect(() => {
    const fetchCustomerCreation = async () => {
      try {
        const response = await api.get("/customerCreation");
        setGetCustomerCreation(response.data);
      } catch (error) {
        // //-console.log("Error fetching customer creation:", error);
      }
    };

    fetchCustomerCreation();
  }, []);


  const filteredCustomers = getCustomerCreation.filter((customer) => {
    // Ensure searchName is a string
    if (typeof searchName !== "string") {
      return false;
    }

    // Convert searchName to lowercase once
    const searchLower = searchName.toLowerCase();

    const name = customer?.name?.toLowerCase() || '';
    const alterMobile = customer?.alterMobile || '';
    const mobile = customer?.mobile || '';
    const address = customer.address ? customer.address : '';
    return (
      name.includes(searchLower) ||
      alterMobile.includes(searchName) ||
      mobile.includes(searchName) ||
      address.includes(searchName)
    );
  });

  //-console.log("filteredCustomers", filteredCustomers);



  const handleNameSelect = (customer) => {
    try {
      setSearchName(
        `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
      );
      setSelectedCustomerId(customer._id);
      setAddress(customer.address);

      const pincode = customer.pincode?.pincode || "";
      const state = customer.pincode?.state?.state || "";
      const city = customer.pincode?.district?.district || "";
      const area = customer.pincode?.area || "";
      setPincode(pincode);
      setState(state);
      setCity(city);
      setSelectedAreaName(area);
    } catch (error) {
      console.error("Error selecting customer name:", error.message);
    }
  };


  const filteredRefCustomers = getCustomerCreation.filter((customer) => {

    if (typeof searchReference !== "string") {
      return false;
    }

    const searchLower = searchReference.toLowerCase();

    const name = customer?.name?.toLowerCase() || '';
    const alterMobile = customer?.alterMobile || '';
    const mobile = customer?.mobile || '';


    return (
      name.includes(searchLower) ||
      alterMobile.includes(searchReference) ||
      mobile.includes(searchReference)

    );
  });


  const handleRefSelect = (customer) => {
    setSearchReference(
      `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
    );
    setSelectedRefId(customer._id);
  };

  const clearName = (searchName) => {
    if (searchName) {
      setSearchName("");
      setAddress("");
    }

  };

  const clearRef = (searchReference) => {
    if (searchReference) {
      setSearchReference("");
    }
  };

  const filteredCustomerServices = getServices.filter(
    (service) =>
      (service.customerCategory && service.customerCategory.customerCategory
        .toLowerCase()
        .includes(searchService.toLowerCase())) ||
      (service.workNature && service.workNature.workNature
        .toLowerCase()
        .includes(searchService.toLowerCase())) ||
      (service.services && service.services.toLowerCase().includes(searchService.toLowerCase()))
  );


  const handleCustomerServicesClick = (serviceId) => {
    const service = getServices.find(
      (customerService) => customerService._id === serviceId
    );

    if (service) {
      const customerCategory = service.customerCategory?.customerCategory || 'Unknown Category';
      const workNature = service.workNature?.workNature || 'Unknown Work Nature';
      const serviceName = service.services || 'Unknown Service';

      const combinedService = `${customerCategory} - ${workNature} - ${serviceName}`;

      // Update selected service list
      setSelectedServiceList((prevList) => {
        const updatedList = [...prevList, combinedService];
        return updatedList;
      });

      // Update selected service type
      setSelectedServiceType((prevTypes) => {
        const updatedTypes = [...prevTypes, service._id];
        return updatedTypes;
      });

      // Clear search input
      setSearchService("");
    } else {
      //-console.error(`Service with ID ${serviceId} not found.`);
    }
  };


  useEffect(() => { }, [selectedServiceList]);


  const removeDropdown = (indexToRemove) => {
    setDropdowns(dropdowns.filter((_, index) => index !== indexToRemove));
    const updatedSelectedProductList = selectedProductList.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedProductList(updatedSelectedProductList);
  };

  const removeCustomerDropdown = (indexToRemove) => {
    setSelectedServiceType((prev) => prev.filter((_, index) => index !== indexToRemove));
    setSelectedServiceList((prev) => prev.filter((_, index) => index !== indexToRemove));
    setSelectedAdditionalName((prev) => prev.filter((_, index) => index !== indexToRemove));
  };


  const filteredAddCustomers = getCustomerCreation.filter((customer) => {

    if (typeof searchAdditionalCustomer !== "string") {
      return false;
    }

    const searchLower = searchAdditionalCustomer.toLowerCase();

    const name = customer?.name?.toLowerCase() || '';
    const alterMobile = customer?.alterMobile || '';
    const mobile = customer?.mobile || '';


    return (
      name.includes(searchLower) ||
      alterMobile.includes(searchAdditionalCustomer) ||
      mobile.includes(searchAdditionalCustomer)

    );
  });

  const handleAdditionalNameSelect = (customerId) => {
    const addCustomer = getCustomerCreation.find(
      (customer) => customer._id === customerId
    );

    setSelectedAdditionalName((prevList) => {
      const updatedList = [...prevList, addCustomer];
      return [...prevList, addCustomer];
    });

    setSelectedAddCustomerType((prevTypes) => {
      const updatedTypes = [...prevTypes, addCustomer._id];
      return [...prevTypes, addCustomer._id];
    });

    setSearchAdditionalCustomer("");
  };

  const filteredProductType = getProductType.filter(product => {
    // Check if the product object and its nested properties exist
    if (
      product &&
      product.product &&
      product.product.product &&
      product.productTypes &&
      product.brand &&
      product.brand.brand &&
      product.productDescription
    ) {
      // Find the product category that matches the product's category ID
      const productCategoryValue = getProductCategory.find(
        (productCat) => product.product.productCategory === productCat._id
      );

      // Debugging: Log the product category value and other properties
      //-//-console.log('Product Category ID:', product.product.ProductCategory);
      //-//-console.log('Product Category Value:', productCategoryValue);

      const productCategory = productCategoryValue ? productCategoryValue.productCategory.toLowerCase() : '';
      const productProduct = product.product.product.toLowerCase();
      const productTypes = product.productTypes.toLowerCase();
      const brand = product.brand.brand.toLowerCase();
      const productDescription = product.productDescription.toLowerCase();
      const searchTerm = searchProduct.toLowerCase();

      // Check if the search term is included in any of the product fields
      return (
        productCategory.includes(searchTerm) ||
        productProduct.includes(searchTerm) ||
        productTypes.includes(searchTerm) ||
        brand.includes(searchTerm) ||
        productDescription.includes(searchTerm)
      );
    }

    // If the product or its nested properties are missing, return false
    return false;
  });
  //-//-console.log("Filtered Product Type:", filteredProductType);



  const handleProductSelect = (selectedProduct) => {
    //-console.log("handleProductSelect called with", selectedProduct);

    const productDetail = getProductType.find(
      (producttype) => producttype._id === selectedProduct
    );

    if (!productDetail) {
      console.error("Product not found");
      return;
    }

    const productCategoryValue = getProductCategory.find(
      (productCat) => productDetail.product?.productCategory === productCat._id
    );

    const selectedProductString = `${productCategoryValue?.productCategory || ''} - ${productDetail.product?.product || ''} - ${productDetail.productTypes || ''} - ${productDetail.brand?.brand || ''}`;
    const productCategoryFromString = productDetail.productCategory?.productCategory || '';
    const productCategoryId = productDetail.product.productCategory;

    //-console.log("productCategoryId", productCategoryId);

    // Update the selected products and types
    setSelectedProductList((prevList) => [...prevList, selectedProductString]);
    setSelectedproductType((prevTypes) => [...prevTypes, productDetail._id]);
    setSelectedProductDescription((prevDescription) => [
      ...prevDescription,
      productDetail.productDescription
    ]);
    setAlertMessages((prevAlerts) => [
      {
        product: selectedProductString,
        description: productDetail.productDescription
      },
      ...prevAlerts
    ]);

    // Use the extracted product category
    setSelectedProductCategory((prevCategory) => {
      const updatedCategories = [...prevCategory, productCategoryFromString];
      const uniqueCategories = [...new Set(updatedCategories)];
      return uniqueCategories;
    });

    // Filter the getProductType collection based on the selected product category
    const matchedProducts = getProductType.filter(
      (producttype) => producttype.product?.productCategory === productCategoryId
    );
    //-console.log("matchedProducts", matchedProducts);

    setSelectedProdCatProduct((prevProdCat) => {
      const updatedList = [...prevProdCat, ...matchedProducts];
      const uniqueMap = new Map(updatedList.map(item => [item._id, item]));
      const uniqueList = Array.from(uniqueMap.values());

      return uniqueList;
    });
    setShowProdSuggestModal(false);

    // Clear search input
    setSearchProduct("");
  };



  useEffect(() => {
    //-console.log("category", selectedProductCategory);
    //-console.log("matchedProdCat", selectedProdCatProduct);


  }, [selectedProductList, selectedProductType, selectedProdCatProduct]);




  const startCamera = async (facingMode) => {
    try {
      const constraints = {
        video: {
          facingMode: facingMode
        }
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      setCameraStream(stream);
      setIsCameraActive(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      //-console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
      setIsCameraActive(false);
    }
  };

  useEffect(() => {
    return () => stopCamera();
  }, []);

  const captureImage = async () => {
    closeQModal();
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL("image/jpeg");
      setCapturedImage([...capturedImage, imageDataURL]);
      stopCamera();
    }
  };

  const toggleCamera = () => {
    const newFacingMode = isFrontCamera ? "environment" : "user";
    setIsFrontCamera(!isFrontCamera);
    stopCamera();
    startCamera(newFacingMode);
  };

  const removeCapturedImage = (indexToRemove) => {
    const updatedCapturedImage = capturedImage.filter(
      (_, index) => index !== indexToRemove
    );
    setCapturedImage(updatedCapturedImage);
  };


  const handleUpload = async (index) => {
    const formData = new FormData();
    formData.append("file", getImageFile);
    formData.append("customerid", adminId);
    formData.append("format", "image");
    const response = await api.post("/uploadAudio", formData);
    const updatedFields = [...additionalFields];
    updatedFields[index].imageSource = response.data;
    setAdditionalFields(updatedFields);
    //-//-console.log(additionalFields);
  };

  const handleLiveMarketingSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Function to get user role from adminId
      const getUserRole = (adminId) => {
        const user = getUser.find((user) => user._id === adminId);
        return user
          ? getAdminRole.find((role) => role._id === user.adminRole._id)?.roleName
          : null;
      };

      // Function to get TL and Admin IDs
      const getTLAndAdminIds = () => {
        const teamName = findUserTeamName(adminId);
        const usersInTeamTL = findUsersByTeamAndRole(
          getUser,
          getTeams,
          getAdminRole,
          teamName,
          "TL"
        );
        const usersTeamAdmin = findLeadUsersByAdmin(getUser, getAdminRole, "Admin");

        const TL = usersInTeamTL.length > 0 ? usersInTeamTL[0]._id : null;
        //-//-console.log("TL", TL);

        const Admin = usersTeamAdmin.length > 0 ? usersTeamAdmin[0]._id : null;

        return { TL, Admin };
      };

      const { TL, Admin } = getTLAndAdminIds();
      const userRole = getUserRole(adminId);
      //-//-console.log("userRole", userRole);

      let approvalby = TL;
      let adminApprovalBy = Admin;

      if (userRole === "TL" || userRole === "Admin") {
        approvalby = adminId;
      } else if (!TL) {
        approvalby = TL;
      }

      // Common data mapping logic
      const serviceList = selectedServiceList.map((customerId, index) => ({
        serviceType: selectedServiceType[index],
        customerType: selectedAddCustomerType[index],
      }));



      if (editingLiveMarketing) {

        let quotationApproval = [...editingLiveMarketing.quotationApproval];

        const now = moment().format("YYYY-MM-DD HH:mm:ss");

        if (selectedStatus === quotationStatusId && (quotationalHistories.length > 0)) {
          if (userRole === "Admin") {
            if (isProjectChecked) {
              // Admin with isProjectChecked = 1: Add both objects with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
            } else {
              // Admin without isProjectChecked = 1: Add only the first object with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
            }
          } else if (userRole === "TL") {
            if (isProjectChecked) {
              // TL with isProjectChecked = 1: Add "forAdminApproval" and "waiting" objects
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "forAdminApproval",
                approvalDate: now,
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: now,
                approvalStatus: "waiting",
                approvalDate: now,
              });
            } else {
              // TL without isProjectChecked = 1: Add only the "approval" object
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
            }
          } else {
            // For roles other than "TL" or "Admin": Add only the "waiting" object
            quotationApproval.push({
              approvalby: approvalby,
              submissionDate: now,
              approvalStatus: "waiting",
              approvalDate: null,
            });
          }
        }
        //-//-console.log("quotationApproval:", quotationApproval);

        const mappedFollowup = additionalFields.map((field, index) => ({
          followupDate: moment(field.followupDate).format("YYYY-MM-DD HH:mm:ss"),
          nextFollowUpDate: field.nextFollowupDate
            ? moment(field.nextFollowupDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
          remark: field.remarks,
          imageSource: field.imageSource,
          createdBy: adminId,
          escalationTo: selectedEscalationUser[index],
          sampleIssues: isSampleChecked[index] ? 1 : 0,
        }));

        //-//-console.log("mappedFollowup", mappedFollowup);

        // Update existing entry logic
        let UpdateassignedTo = adminId;
        mappedFollowup.forEach((followupItem) => {
          if (followupItem.escalationTo) {
            UpdateassignedTo = followupItem.escalationTo;
          }
        });

        const mappedQuotationalHistories = quotationalHistories.map((history, index) =>
          history.map((product, prodIndex) => {
            const unit = getUnit.find(unit => unit.unit === product.unit) || {};
            return {
              productType: product.productTypeId,
              unit: unit._id || '',
              quantity: product.quantity,
              givenPrice: product.givenPrice,
              expectedPrice: product.expectedPrice,
            };
          })
        );

        let leadCancelation = [...editingLiveMarketing.leadCancelation];
        if (selectedStatus === cancelStatusId) {
          leadCancelation.push({
            doneBy: null,
            submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "pending",
            statusDate: null,
          });
        }

        const updatedData = {
          name: selectedCustomerId,
          leadReference: selectedRefId,
          serviceList,
          quotationalHistories: mappedQuotationalHistories,
          followup: mappedFollowup,
          status: selectedStatus,
          cancelReason: selectedReason,
          quotationApproval,
          leadCancelation,
          buildingStructure: selectedBuildStructure,
          buildingRating,
          customerRating,
          projectValue,
          projectDuration: selectedProjectDuration,
          project: isProjectChecked ? 1 : 0,
          assignedTo: UpdateassignedTo,
          pincode: getPincodeObjectId,
          address
        };

        //-//-console.log("Updated Data:", updatedData);

        const response = await api.put(`/liveMarketing/${editingLiveMarketing._id}`, updatedData);
        if (response.status === 200) {
          setShowLiveForm(false);
          setEditingLiveMarketing(null);
          fetchLiveMarketing();
          fetchFollowUp();
          setIsLoading(false);
          setShowAlert(true);
        } else {
          //-console.error("Failed to submit live marketing data. Server returned status:", response.status);
          //-console.error("Response data:", response.data);
          throw new Error("Failed to submit live marketing data. Please try again later.");
        }

      } else {
        // Create new entry logic
        let quotationApproval = [];

        if (selectedStatus === quotationStatusId && (quotationalHistories.length > 0)) {
          if (userRole === "Admin") {
            if (isProjectChecked) {
              // Admin with isProjectChecked = 1: Add both objects with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            } else {
              // Admin without isProjectChecked = 1: Add only the first object with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            }
          } else if (userRole === "TL") {
            if (isProjectChecked) {
              // TL with isProjectChecked = 1: Add "forAdminApproval" and "waiting" objects
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "forAdminApproval",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "waiting",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            } else {
              // TL without isProjectChecked = 1: Add only the "approval" object
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            }
          } else {
            // For roles other than "TL" or "Admin": Add only the "waiting" object
            quotationApproval.push({
              approvalby: approvalby,
              submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              approvalStatus: "waiting",
              approvalDate: null,
            });
          }
        }
        //-//-console.log("quotationApproval:", quotationApproval);

        let leadCancelation = [];
        if (selectedStatus === cancelStatusId) {
          leadCancelation = [
            {
              doneBy: "",
              submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              status: "pending",
              statusDate: null,
            },
          ];
        }

        const mappedFollowup = additionalFields.map((field, index) => ({
          followupDate: moment(field.followupDate).format("YYYY-MM-DD HH:mm:ss"),
          nextFollowUpDate: field.nextFollowupDate
            ? moment(field.nextFollowupDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
          remark: field.remarks,
          imageSource: field.imageSource,
          createdBy: adminId,
          escalationTo: selectedEscalationUser[index],
          sampleIssues: isSampleChecked[index] ? 1 : 0,
        }));

        //-//-console.log("mappedFollowup", mappedFollowup);

        const mappedQuotationalHistories = quotationalHistories.map((history) =>
          history.map((product) => {
            const unit = getUnit.find((unit) => unit.unit === product.unit) || {};
            return {
              productType: product.productTypeId,
              unit: unit._id || "",
              quantity: product.quantity,
              givenPrice: product.givenPrice,
              expectedPrice: product.expectedPrice,
            };
          })
        );

        //-//-console.log("mappedQuotationalHistories", mappedQuotationalHistories);

        const totalCustomerCount = customerCount + 1
        const leadData = {
          name: selectedCustomerId,
          leadReference: selectedRefId,
          serviceList,
          quotationalHistories: mappedQuotationalHistories,
          leadCancelation,
          followup: mappedFollowup,
          status: selectedStatus,
          cancelReason: selectedReason,
          quotationApproval,
          buildingStructure: selectedBuildStructure,
          buildingRating,
          customerRating,
          project: isProjectChecked ? 1 : 0,
          projectValue,
          projectDuration: selectedProjectDuration,
          pincode: getPincodeObjectId,
          address,
          createdBy: adminId,
          assignedTo: adminId,
          leadCount: totalCustomerCount,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        };

        //-console.log("Submitting leadData:", leadData);

        const response = await api.post("/liveMarketing", leadData);
        //-console.log(response.data);

        if (response.status === 200) {
          clearForm();
          setShowLiveForm(false);
          setIsLoading(false);
          setShowAlert(true);
          fetchLiveMarketing();
          fetchFollowUp();
          setAdditionalFields([]);
          setQuotationalHistories([]);
          setAlertMessages([]);
        } else {
          //-console.error("Failed to create lead. Server returned status:", response.status);
          //-console.error("Response data:", response.data);
          throw new Error("Failed to create lead. Please try again later.");
        }
      }
      setQuotationalHistories([]);
    } catch (error) {
      //-console.error("Error submitting live marketing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // delete
  const handleDeleteLiveMarketing = async () => {


    // Confirm the deletion action
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }

    try {

      setIsLoading(true);

      // Make the API call to delete the item
      const response = await api.delete(`/liveMarketing/${deleteLiveMarketing._id}`);

      if (response.status === 200) {
        setShowLiveForm(false);
        setEditingLiveMarketing(null);
        fetchLiveMarketing();
        fetchFollowUp();
        setIsLoading(false);
        setShowAlert(true);
        setAlertMessages([]);
      } else {
        //-console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
      setShowAlert(true);
      setAlertMessages(["Failed to delete the item. Please try again later."]);
    } finally {
      setIsLoading(false);
    }
  };


  const clearForm = () => {
    setLastDate("")
    setSearchService("");
    setCustomerCount("")
    setSearchName("");
    setSelectedProductList([]);
    setSelectedProjectDuration("");
    setSelectedAreaName("");
    setQuotationalHistories([]);
    setSelectedproductType([]);
    setQuantity([]);
    setState("");
    setSelectedStatus("");
    setSelectedBuildStructure("");
    setCity("");
    setPincode("");
    setAddress("");
    setSearchReference("");
    setAdditionalFields([]);
    setBuildingRating(0);
    setCustomerRating(0);
    setSelectedStatus("");
    setProjectDuration("");
    setProjectValue("");
    setShowClearButton(false);
    setShowAddClearButton(false);
    setShowRefButton(false);
    setSelectedServiceList([]);
    setAdditionalFields([]);
  };

  const handleEditingLiveMarketing = (item) => {
    //-//-console.log("item", item);
    setIsLoading(false);
    setShowProdSuggestModal(false);
    setSelectedReason(item.cancelReason ? item.cancelReason : "");
    setCustomerCount(item.leadCount ? item.leadCount : "");
    const searchName = item.name
      ? `${item.name.name || ''}${item.name.mobile || '-'}${item.name.alterMobile || '-'}`
      : '';

    setSearchName(searchName);
    const searchReferenceName = item.leadReference
      ? `${item.leadReference?.name ?? ''} ${item.leadReference?.mobile ?? '-'} ${item.leadReference?.alterMobile ?? '-'}`
      : "";

    setSearchReference(searchReferenceName);
    setSelectedCustomerId(item.name._id);
    setShowLiveForm(true);
    setDeleteLiveMarketing(item);
    setEditingLiveMarketing(item);
    setSelectedImage(item.image);
    setSelectedStatus(item.status ? item.status._id : "N/A");
    setSelectedBuildStructure(item.buildingStructure || "");
    setBuildingRating(item.buildingRating || 0);
    setCustomerRating(item.customerRating || 0);
    setProjectValue(item.projectValue || "");
    setSelectedProjectDuration(item.projectDuration || "");
    const history = item.quotationalHistories.map(innerArray =>
      Object.values(innerArray).map(product => {
        // Log the product to the console
        //-//-console.log("product", product);
        // Find the product category value based on the product's category ID
        const productCategoryValue = getProductCategory.find(
          (productCat) => product.productType?.product?.productCategory === productCat._id
        );

        // Return a new object with the required properties
        return {
          productType: `${productCategoryValue?.productCategory || ''} ${product.productType?.product?.product || ''} ${product.productType?.productTypes || ''} ${product.productType?.brand?.brand || ''}`,
          unit: product.unit?.unit || '',
          quantity: product.quantity || 0,
          givenPrice: product.givenPrice || 0,
          expectedPrice: product.expectedPrice || 0,
          productTypeId: product.productType?._id || '',
          productDescription: product.productDescription || ''
        };
      })
    );

    setQuotationalHistories(history);

    const selectedServices = item.serviceList.map((service) =>
      service.serviceType ? service.serviceType.services : { services: "N/A" }
    );
    setSelectedServiceList(selectedServices);
    const selectedSerType = item.serviceList.map((service) =>
      service.serviceType ? service.serviceType._id : { services: "N/A" }
    );
    setSelectedServiceType(selectedSerType);
    const selectedNames = item.serviceList.map((service) => ({
      name: service.customerType ? service.customerType.name : "",
      mobile: service.customerType ? service.customerType.mobile : "",
    }));
    setSelectedAdditionalName(selectedNames);
    setSelectedAddCustomerType(
      item.serviceList.map((service) =>
        service.customerType ? service.customerType._id : { services: "N/A" }
      )
    );


    setAdditionalFields(

      item.followup.map((followup) => ({

        id: followup._id,
        nextFollowupDate: followup.nextFollowUpDate
          ? new Date(followup.nextFollowUpDate)
          : null,
        imageSource: followup.imageSource,
        remarks: followup.remark || "",
      }))
    );
    //-//-console.log("additionalFields", additionalFields);
    setIsProjectChecked(item.project === 1 ? 1 : 0);
    const pincode = item.pincode || {};
    setPincode(pincode.pincode || "");
    setSelectedAreaName(pincode.area || "");
    setCity(pincode.district ? pincode.district.district : "");
    setState(pincode.state ? pincode.state.state : "");
    setAddress(item.name ? item.address : "-");
  };

  const ExQuotationHistLength = editingLiveMarketing ? editingLiveMarketing.quotationalHistories.length : 0;

  const handleBuildingRatingChange = (newRating) => {
    setBuildingRating(newRating);
  };
  const handleCustomerRatingChange = (newRating) => {
    setCustomerRating(newRating);
  };

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])


  useEffect(() => {
    if (alertMessages.length) {
      const timer = setTimeout(() => {
        setAlertMessages((prev) => prev.slice(1));
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [alertMessages]);



  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await api.get("/team");
        setGetTeams(response.data);
      } catch (error) {
        //-//-console.log("Error fetching Teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/user");
        setGetUser(response.data);
        //-//-console.log(response.data);

      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  // Function to find the teamName of adminId
  const findUserTeamName = (adminId) => {
    const foundUser = getUser.find(user => user._id === adminId);
    if (foundUser && foundUser.team) {
      const foundTeam = getTeams.find(team => team._id === foundUser.team._id);
      return foundTeam ? foundTeam.teamName : null;
    }
    return null;
  };

  // Function to find a user role by adminId
  const findUserRole = (adminId, adminRoles) => {
    const foundUser = getUser.find(user => user._id === adminId);
    if (foundUser) {
      const userRole = adminRoles.find(role => role._id === foundUser.adminRole._id);
      return userRole ? userRole.roleName : null;
    }
    return null;
  };

  // Function to find users by team TL
  const findUsersByTeamAndRole = (users, teams, adminRoles, teamName, roleName) => {
    return users.filter(user => {
      const userTeam = teams.find(team => team._id === user.team?._id);
      //-//-console.log("userTeam", userTeam);
      return userTeam && userTeam.teamName === teamName;
    }).filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id);
      //-//-console.log("userRole", userRole);

      return userRole && userRole.roleName === roleName;
    });
  };

  // access based on role ---------------------------
  useEffect(() => {

    const getLoginUserRole = (adminId) => {
      //-//-console.log('Searching for user with adminId:', adminId);
      const user = getUser.find((user) => user._id === adminId);
      //-//-console.log('User found:', user);

      if (user) {
        //-//-console.log('Searching for role with roleId:', user.adminRole._id);
        const loginuserRole = getAdminRole.find((role) => role._id === user.adminRole._id);
        //-//-console.log('Role found:', loginuserRole);
        setUserRole(loginuserRole);
        return loginuserRole?.roleName || null;
      } else {
        //-//-console.log('No user found with the given adminId');
        return null;
      }
    };

    // Fetch the userRole based on adminId
    const LoginRole = getLoginUserRole(adminId);
  }, [adminId, getUser, getAdminRole]);

  const findLeadUsersByAdmin = (users, adminRoles, roleName) => {
    return users.filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id && role.roleName === roleName);
      return !!userRole;
    });
  };

  useEffect(() => {
    //-//-console.log("adminId:", adminId);
    //-//-console.log("getUser:", getUser);
    //-//-console.log("getTeams:", getTeams);
    //-//-console.log("getAdminRole:", getAdminRole);

    const userRole = findUserRole(adminId, getAdminRole);
    //-//-console.log("User Role:", userRole);

    const teamName = findUserTeamName(adminId);
    //-//-console.log("Team Name:", teamName);

    const usersInTeamTL = findUsersByTeamAndRole(getUser, getTeams, getAdminRole, teamName, 'TL');
    //-//-console.log("usersInTeamTL:", usersInTeamTL);

    const usersTeamAdmin = findLeadUsersByAdmin(getUser, getAdminRole, 'Admin');
    //-//-console.log("usersTeamAdmin:", usersTeamAdmin);

    if (usersInTeamTL.length > 0) {
      const TL = usersInTeamTL[0]._id;
      const Admin = usersTeamAdmin[0] ? usersTeamAdmin[0]._id : null;
      //-//-console.log("TL:", TL);
      //-//-console.log("Admin:", Admin);
      setApprovalBy(TL);
      setAdminApproval(Admin);
    } else {
      setApprovalBy(null);
      setAdminApproval(null);
    }
  }, [adminId, getTeams, getUser, getAdminRole, setApprovalBy, setAdminApproval]);


  const addAdditionalField = () => {
    const newField = {
      id: uuidv4(),
      nextfollowUp: null,
      remarks: "",
      imageSource: "",
    };
    setAdditionalFields([...additionalFields, newField]);
  };
  // Function to remove an additional field by index
  const removeAdditionalField = (index) => {
    removeCapturedImage(index);
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  // Function to update remarks in additional fields
  const handleRemarksChange = (index, remarks) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].remarks = remarks;
    setAdditionalFields(updatedFields);
  };

  const handleEscalationChange = (index, value) => {
    const updatedEscalation = [...selectedEscalationUser];
    updatedEscalation[index] = value;
    setSelectedEscalationUser(updatedEscalation);
  };

  const handleDateChange = (date, index, fieldName) => {
    const updatedFields = [...additionalFields];

    if (fieldName === "nextFollowup") {
      updatedFields[index] = {
        ...updatedFields[index],
        nextFollowupDate: date,
      };
    }

    setAdditionalFields(updatedFields);
  };

  const handleEscaltionCheckbox = (index, checked) => {
    const updatedEscalationCheck = [...isEscalateChecked];
    updatedEscalationCheck[index] = checked;
    setIsEscalateChecked(updatedEscalationCheck);
    setIsDropdownDisabled(!checked);
  };


  const handleProjectCheckboxChange = (event) => {
    setIsProjectChecked(event.target.checked);
    if (event.target.checked) {

    }
  };

  const handleSampleCheckbox = (index, checked) => {
    const updatedSample = [...isSampleChecked];
    updatedSample[index] = checked;
    setIsSampleChecked(updatedSample);
  };

  const handleUnitChange = (index, value) => {
    const updatedUnits = [...selectedUnit];
    updatedUnits[index] = value;
    setSelectedUnit(updatedUnits);
  };

  // Example handler for updating quantity state
  const handleQuantityChange = (index, value) => {
    const updatedQuantities = [...quantity];
    updatedQuantities[index] = value;
    setQuantity(updatedQuantities);
  };

  const handleGivePrice = (index, value) => {
    const updatedGivePrice = [...givenPrice];
    updatedGivePrice[index] = value;
    setGivenPrice(updatedGivePrice);
  };

  const handleExpectedPrice = (index, value) => {
    const updatedExpectedPrice = [...expectedPrice];
    updatedExpectedPrice[index] = value;
    setExpectedPrice(updatedExpectedPrice);
  };

  const toggleQuotationHistory = () => {
    setShowQuotationHistory(!showQuotationHistory);
  };

  const handleAddChangeTab = (index) => {
    setAddTabIndex(index);
  };

  const handleCreateQuotationalHistory = () => {

    // Map selected products to the desired format
    const selectedProductsForQuotation = selectedProductList.map((product, index) => {
      const selectedUnitObject = getUnit.find(unit => unit._id === selectedUnit[index]);

      return {
        productTypeId: selectedProductType[index],
        productType: product,
        unit: selectedUnitObject ? selectedUnitObject.unit : '',
        quantity: quantity[index] || '',
        givenPrice: givenPrice[index] || '',
        expectedPrice: expectedPrice[index] || '',
        // productDescription: selectedProductDescription[index] || '',
      };
    });

    //-//-console.log('Selected products for quotation:', selectedProductsForQuotation);

    setQuotationalHistories(prevHistories => {
      const newHistories = [...prevHistories, selectedProductsForQuotation];
      //-//-console.log("Updated quotationalHistories:", newHistories);
      return newHistories;
    });

    // Clear form fields
    toggleQuotationHistory();
    setSelectedProductList([]);
    setSelectedproductType([]);
    setSelectedUnit([]);
    setQuantity([]);
    setGivenPrice([]);
    setExpectedPrice([]);
    setSearchProduct('');
  };



  useEffect(() => {

    if (editingLiveMarketing && (editingLiveMarketing.quotationalHistories?.length ?? false) > 0) {
      setShouldShowButton(false);

      if (editingLiveMarketing.quotationApproval && editingLiveMarketing.quotationApproval.length > 0) {
        const lastApprovalStatus = editingLiveMarketing.quotationApproval[editingLiveMarketing.quotationApproval.length - 1].approvalStatus;
        //-//-console.log("checkLastApproval:", lastApprovalStatus);
        setShouldShowButton(lastApprovalStatus !== "waiting");
      }
    }

  }, [editingLiveMarketing]);


  const quotationalHistoriesLength = quotationalHistories.length;



  // get unit

  const openModal = () => {
    startCamera("user");
    setShowModal(true);
  };
  // Quotation Modal
  const closeQModal = () => {
    setQModal(false);
  };

  const openQModal = () => {
    setQModal(true);
  };


  const handleImageClick = (imageSrc) => {
    //-//-console.log(imageSrc);
    setModalImage(imageSrc);

  };

  useEffect(() => {
    //-//-console.log('modalImage:', modalImage);
  }, [modalImage]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddTabChange = (event, newValue) => {
    setAddTabValue(newValue);
  };

  useEffect(() => {
    let temp_rows = [];
    getLiveMarketing.map((item, index) => {
      const quotationalHistories = [];
      item.quotationalHistories.map((product, index) => {
        quotationalHistories.push({
          productType: product.productType,
          unit: product.unit,
          quantity: product.quantity,
          givenPrice: product.givenPrice,
          expectedPrice: product.expectedPrice,
        });
      });
      temp_rows.push(
        createData(
          item.name ? item.name.name : "",
          item,
          item.createdAt,
          item.pincode,
          quotationalHistories,
        )
      );
    });


    setGridRows(temp_rows);

  }, [getLiveMarketing]);


  const handleFromDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setFromDate1(e.target.value);
        break;
      case 1:
        setFromDate2(e.target.value);
        break;
      case 2:
        setFromDate3(e.target.value);
        break;
      case 3:
        setFromDate4(e.target.value);
        break;
      case 4:
        setFromDate5(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleToDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setToDate1(e.target.value);
        break;
      case 1:
        setToDate2(e.target.value);
        break;
      case 2:
        setToDate3(e.target.value);
        break;
      case 3:
        setToDate4(e.target.value);
        break;
      case 4:
        setToDate5(e.target.value);
        break;
      default:
        break;
    }
  };

  // Handle search click
  const handleSearchClick = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setSearchClicked1(true);
        break;
      case 1:
        setSearchClicked2(true);
        break;
      case 2:
        setSearchClicked3(true);
        break;
      case 3:
        setSearchClicked4(true);
        break;
      case 4:
        setSearchClicked5(true);
        break;
      default:
        break;
    }
  };

  // Handle clear filter
  const handleClearFilter = (tabIndex) => {
    const setDefaultFromDate = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      switch (tabIndex) {
        case 0:
          setFromDate1(currentDateFormatted);
          setSearchClicked1(false);
          setToDate1(currentDateFormatted);
          setFilteredRows1([]);
          break;
        case 1:
          setFromDate2(currentDateFormatted);
          setSearchClicked2(false);
          setToDate2(currentDateFormatted);
          setFilteredRows2([]);
          break;
        case 2:
          setFromDate3(currentDateFormatted);
          setSearchClicked3(false);
          setToDate3(currentDateFormatted);
          setFilteredRows3([]);
          break;
        case 3:
          setFromDate4(currentDateFormatted);
          setSearchClicked4(false);
          setToDate4(currentDateFormatted);
          setFilteredRows4([]);
          break;
        case 4:
          setFromDate5(currentDateFormatted);
          setSearchClicked5(false);
          setToDate5(currentDateFormatted);
          setFilteredRows5([]);
          break;
        default:
          break;
      }
    };

    setDefaultFromDate();
  };

  useEffect(() => {
    const filterRowsByDate = (tabIndex) => {
      let fromDate, toDate, setFilteredRows, statusFilter;
      switch (tabIndex) {
        case 0:
          fromDate = fromDate1;
          toDate = toDate1;
          setFilteredRows = setFilteredRows1;
          statusFilter = "open";
          break;
        case 1:
          fromDate = fromDate2;
          toDate = toDate2;
          setFilteredRows = setFilteredRows2;
          statusFilter = "quotation";
          break;
        case 2:
          fromDate = fromDate3;
          toDate = toDate3;
          setFilteredRows = setFilteredRows3;
          statusFilter = "escalation";
          break;
        case 3:
          fromDate = fromDate4;
          toDate = toDate4;
          setFilteredRows = setFilteredRows4;
          statusFilter = "order success";
          break;
        case 4:
          fromDate = fromDate5;
          toDate = toDate5;
          setFilteredRows = setFilteredRows5;
          statusFilter = "cancel";
          break;
        default:
          break;
      }

      //-//-console.log(`fromDate: ${fromDate}, toDate: ${toDate}`);

      if (fromDate && toDate) {
        const filtered = rows.filter((row) => {
          const followup = row.item.followup;
          const assignedTo = row.item.assignedTo ? row.item.assignedTo._id : null;

          //-//-console.log(assignedTo);

          // date field to use based on the status
          const dateToCompare = statusFilter === "escalation"
            ? moment(row.item.createdAt)
            : moment(followup?.[0]?.nextFollowUpDate);

          if (followup && assignedTo === adminId && followup.length > 0) {
            const isWithinDateRange = dateToCompare.isBetween(moment(fromDate).startOf('day'), moment(toDate).endOf('day'), null, '[]');
            return isWithinDateRange && row.item.status.leadStatus === statusFilter;
          }
          return false;
        });
        setFilteredRows(filtered);
      } else {
        setFilteredRows([]);
      }
    };

    switch (tabIndex) {
      case 0:
        if (searchClicked1) {
          filterRowsByDate(0);
        }
        break;
      case 1:
        if (searchClicked2) {
          filterRowsByDate(1);
        }
        break;
      case 2:
        if (searchClicked3) {
          filterRowsByDate(2);
        }
        break;
      case 3:
        if (searchClicked4) {
          filterRowsByDate(3);
        }
        break;
      case 4:
        if (searchClicked5) {
          filterRowsByDate(4);
        }
        break;
      default:
        break;
    }
  }, [
    fromDate1,
    toDate1,
    searchClicked1,
    fromDate2,
    toDate2,
    searchClicked2,
    fromDate3,
    toDate3,
    searchClicked3,
    fromDate4,
    toDate4,
    searchClicked4,
    fromDate5,
    toDate5,
    searchClicked5,
    tabIndex,
    rows,
  ]);


  const filteredEscalationUsers = getUser.filter(
    (user) => user.name !== adminName
  );

  const generateQuotation = (editingLiveMarketing, index) => {
    //-//-console.log("New Tab");

    const data = { leadData: editingLiveMarketing, quote: index }; // Your data object
    localStorage.setItem("generateQuotation", JSON.stringify(data));
    const url = "/quotation";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const currentDateTime = moment().format('YYYY-MM-DDTHH:mm');


  const handleTableSearchChange = (event) => {
    setSearchTableData(event.target.value);
  };

  const transformRows = (rows) => {
    return rows.map(row => {
      //-console.log("Transforming row", row);

      const item = row.item || {};
      const pincode = item.pincode || {};

      return {
        id: item._id || '',
        cus_name: item.name?.name || '',
        lead_source: item.leadSource?.leadSource || '',
        lead_date: row.lead_date || '',
        pincode: pincode.pincode || '',
        area: pincode.district?.district || '',
        quotationApproval: item.quotationApproval || [],
        item: row.item
      };
    });
  };

  const matchesSearchTerm = (row) => {
    const item = row.item || {};
    const name = item.name?.name?.toLowerCase() || '';
    const leadSource = item.leadSource?.leadSource?.toLowerCase() || '';
    const pincodeData = item.pincode || {};
    const pincode = pincodeData.pincode?.toString() || '';
    const city = pincodeData.district?.district?.toLowerCase() || '';

    return (
      name.includes(searchTableData.toLowerCase()) ||
      leadSource.includes(searchTableData.toLowerCase()) ||
      pincode.includes(searchTableData.toLowerCase()) ||
      city.includes(searchTableData.toLowerCase())
    );
  };



  const filteredOpenRows = () => {
    // Function to filter rows based on lead status, assigned user, and escalation data
    const filterRows = (row) => {
      const { status, followup, _id } = row.item;

      // Check for followUp and lead assignment
      if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
        const followUp = getFollowUp.pipeLine.find(follow => follow.leadId === _id);
        if (followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "open") {
          return true;
        }
      }

      // Check if the row has escalation data for the current admin
      const escalationData = followup.filter(follow => follow.escalationTo === adminId);
      return escalationData.length > 0;
    };

    // First filter: based on search or predefined rows
    const rowsToFilter = searchClicked1 ? filteredRows1 : rows.filter(filterRows);

    // Second filter: based on search term
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;

    // Transform and return filtered rows
    return transformRows(rowsFilteredBySearch);
  };



  const filteredQuotationRows = () => {
    const rowsToFilter =
      searchClicked2 ? filteredRows2
        : rows.filter(row => {
          const { status } = row.item;
          if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
            const followUp = getFollowUp.pipeLine.find(follow => follow.leadId === row.item._id);
            return followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "quotation";
          }
          return null;
        });
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;

    // //-console.log("Rows before transformation", rowsToTransform); 
    return transformRows(rowsFilteredBySearch);
  };

  const filteredEscalationRows = () => {
    const rowsToFilter =
      searchClicked3 ? filteredRows3
        : rows.filter(row => {
          const { status } = row.item;
          if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
            const followUp = getFollowUp.pipeLine.find(follow => follow.leadId === row.item._id);
            return followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "escalation";
          }
          return null;
        });
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;

    // //-console.log("Rows before transformation", rowsToTransform); 
    return transformRows(rowsFilteredBySearch);
  };


  const filteredOrderSuccessRows = () => {
    const rowsToFilter =
      searchClicked4 ? filteredRows4
        : rows.filter(row => {
          const { status } = row.item;
          if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
            const followUp = getFollowUp.pipeLine.find(follow => follow.leadId === row.item._id);
            return followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "order success";
          }
          return null;
        });
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;


    // //-console.log("Rows before transformation", rowsToTransform); 
    return transformRows(rowsFilteredBySearch);
  };

  const filteredCancelRows = () => {
    const rowsToFilter =
      searchClicked5 ? filteredRows5
        : rows.filter(row => {
          const { status } = row.item;
          if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
            const followUp = getFollowUp.pipeLine.find(follow => follow.leadId === row.item._id);
            return followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "cancel";
          }
          return null;
        });

    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;


    // //-console.log("Rows before transformation", rowsToTransform); 
    return transformRows(rowsFilteredBySearch);
  };


  const columns = [
    { field: 'cus_name', headerName: 'Customer Name', width: 300 },
    { field: 'lead_source', headerName: 'Lead Source', width: 150 },
    { field: 'lead_date', headerName: 'Lead Date', width: 200 },
    { field: 'pincode', headerName: 'Pincode', width: 150 },
    { field: 'area', headerName: 'Area', width: 200 },
    {
      field: 'approvalIcon',
      headerName: 'Approval Status',
      width: 150,
      renderCell: (params) => {
        const { quotationApproval } = params.row;
        let approvalIcon = null;

        if (quotationApproval.length > 0) {
          const lastApproval = quotationApproval[quotationApproval.length - 1];
          if (lastApproval.approvalStatus === 'approved') {
            approvalIcon = <CheckIcon color="success" />;
          } else if (lastApproval.approvalStatus === 'declined') {
            approvalIcon = <CancelIcon color="error" />;
          } else if (lastApproval.approvalStatus === 'waiting') {
            approvalIcon = <RefreshIcon color="info" />;
          }
        }

        return approvalIcon ? <TableCell>{approvalIcon}</TableCell> : null;
      }
    }
    // Add additional columns as needed
  ];


  return (
    <React.Fragment>
      {/* camera model */}
      <div>
        {showModal && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Camera</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeQModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {/* Camera */}
                  <div className="camera-container">
                    {cameraStream && (
                      <>
                        <video
                          ref={videoRef}
                          autoPlay
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "10px 0 0 0",
                          }}
                          playsInline
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="modal-footer row justify-content-evenly">
                  <button
                    type="button"
                    className="btn btn-gradient-primary col-auto"
                    onClick={startCamera}
                  >
                    <i
                      class="fa fa-power-off"
                      style={{ "font-size": "20px" }}
                    ></i>
                  </button>

                  {/* Conditionally render Capture Image button */}
                  <button
                    className="btn btn-gradient-success col-auto"
                    onClick={captureImage}
                  >
                    <FontAwesomeIcon
                      icon={faCameraRetro}
                      style={{ height: "20px" }}
                    />
                  </button>

                  {/* switch camera */}
                  <button
                    type="button"
                    className="btn btn-info col-auto"
                    onClick={toggleCamera}
                  >
                    <FontAwesomeIcon
                      icon={faExchangeAlt}
                      style={{ height: "20px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showModal && <div className="modal-backdrop fade show"></div>}
      </div>

      <div className="container">
        {/* header */}
        <div class="page-header">
          <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
              <i class="fa fa-automobile"></i>
            </span>{" "}
            Live Marketing
          </h3>
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>

        {/* off canvas  */}
        <div
          className={`offcanvas offcanvas-end   ${showLiveForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header d-flex justify-content-between align-items-center">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              Live Form
            </h5>
            <div className="d-flex align-items-center">
              <button
                onClick={toggleCustomerForm}
                className="btn btn-gradient-success mb-2 me-4"
                type="button"
              >
                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                Add Customer
              </button>
              <button
                type="button"
                className="btn-close text-reset"
                onClick={toggleLiveForm}
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="offcanvas-body">
            <div className="card">
              <div className="card-body">
                <form
                  className="form-inline"
                  onSubmit={handleLiveMarketingSubmit}
                  style={{
                    display:
                      showLiveForm || (editingLiveMarketing ? "block" : "none"),
                  }}
                >
                  <div className="row">
                    {/* Customer Name */}
                    <div className="col-md-3">
                      <div className="d-flex gap-2">
                        <div className="form-group w-100">
                          <label>Customer Name</label>
                          <input
                            type="text"
                            placeholder="Search a customer name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            className="form-control mb-2"
                            onClick={() => setShowClearButton(true)}
                            required
                          />
                          {searchName && (
                            <div className="form-group">
                              <ul>
                                {filteredCustomers.map((customer) => (
                                  <li
                                    key={customer._id}
                                    onClick={() => handleNameSelect(customer)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {`${customer.name} - ${customer.alterMobile} - ${customer.mobile}`}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          {showClearButton && (
                            <button
                              onClick={() => clearName(searchName)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              type="button"
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Lead Reference */}
                    <div className="col-md-3">
                      <div className="d-flex gap-2">
                        <div className="form-group w-100">
                          <label>Reference Name</label>
                          <input
                            type="text"
                            placeholder="Search a reference name"
                            value={searchReference}
                            onChange={(e) => setSearchReference(e.target.value)}
                            className="form-control mb-2"
                            onClick={() => setShowRefButton(true)}
                            required
                          />
                          {searchReference && (
                            <div className="form-group">
                              <ul>
                                {filteredRefCustomers.map((customer) => (
                                  <li
                                    key={customer._id}
                                    onClick={() => handleRefSelect(customer)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {`${customer.name} - ${customer.alterMobile} - ${customer.mobile}`}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          {showRefButton && (
                            <button
                              onClick={() => clearRef(searchReference)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              type="button"
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* lead count */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="customerCount">Lead Count</label>
                        <div className="d-flex">
                          <input
                            className="form-control"
                            id="customerCount"
                            value={customerCount !== null && lastDate ? `${customerCount} (${lastDate})` : ''}
                            onChange={(e) => setCustomerCount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Additional Customers Data */}
                  <div className="row">
                    {/* Customer Category */}
                    <div className="col-md-4">
                      <div className="form-group w-100">
                        <label>Customer Category</label>
                        <input
                          type="text"
                          placeholder="Search a Category"
                          value={searchService}
                          onChange={(e) => setSearchService(e.target.value)}
                          className="form-control mb-2"
                          onClick={() => setShowAddClearButton(true)}
                        />
                        {searchService && (
                          <div className="search-results">
                            {filteredCustomerServices.map((service) => {
                              const customerCategory = service?.customerCategory?.customerCategory || 'Unknown Category';
                              const workNature = service?.workNature?.workNature || 'Unknown Work Nature';
                              const serviceType = service?.services || 'Unknown Service';

                              return (
                                <div
                                  key={service._id}
                                  className="search-item"
                                  onClick={() => handleCustomerServicesClick(service._id)}
                                  style={{
                                    cursor: 'pointer',
                                    padding: '10px',
                                    borderBottom: '1px solid #ddd',
                                  }}
                                >
                                  {`${customerCategory} - ${workNature} - ${serviceType}`}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Search Additional Customer Name */}
                    <div className="col-md-4">
                      <div className="form-group w-100">
                        <label>Customer Name</label>
                        <input
                          type="text"
                          placeholder="Search a name"
                          value={searchAdditionalCustomer}
                          onChange={(e) => setSearchAdditionalCustomer(e.target.value)}
                          className="form-control mb-2"
                          onClick={() => setShowAddClearButton(true)}
                        />
                        {searchAdditionalCustomer && (
                          <div className="search-results">
                            {filteredAddCustomers.map((addCustomer) => {
                              const name = addCustomer.name || 'Unknown Name';
                              const alterMobile = addCustomer.alterMobile || 'No Alt Mobile';
                              const mobile = addCustomer.mobile || 'No Mobile';

                              return (
                                <div
                                  key={addCustomer._id}
                                  className="search-item"
                                  onClick={() => handleAdditionalNameSelect(addCustomer._id)}
                                  style={{
                                    cursor: 'pointer',
                                    padding: '10px',
                                    borderBottom: '1px solid #ddd',
                                  }}
                                >
                                  {`${name} - ${alterMobile} - ${mobile}`}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Dynamic Dropdowns */}
                  <div>
                    {selectedServiceType.map((service, index) => (
                      <div key={service.id}>
                        <div className="d-lg-flex gap-3">
                          {/* Selected Service */}
                          <div className="form-group col-12 col-lg-3">
                            <label htmlFor={`selectedService-${index}`}>
                              Service
                            </label>
                            <input
                              type="text"
                              id={`selectedService-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedServiceList[index]}
                            />
                          </div>
                          {/* Selected Additional Customer Name */}
                          <div className="form-group col-12 col-lg-3">
                            <label htmlFor={`selectedAdditionalName-${index}`}>
                              Name
                            </label>
                            <input
                              type="text"
                              id={`selectedAdditionalName-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedAdditionalName[index]?.name || ""}
                            />
                          </div>
                          {/* Corresponding Mobile Number */}
                          <div className="form-group col-12 col-lg-3">
                            <label htmlFor={`correspondingMobile-${index}`}>
                              Mobile
                            </label>
                            <input
                              type="text"
                              id={`correspondingMobile-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedAdditionalName[index]?.mobile || ""}
                            />
                          </div>
                          {/* Remove Button */}
                          <div className="text-end mt-0 pt-0 mt-lg-4">
                            <button
                              type="button"
                              onClick={() => removeCustomerDropdown(index)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {/* Building Structure */}
                    <div className="col-md-4 mb-2">
                      <div className="form-group">
                        <label>Building Structure</label>
                        <select
                          className="form-select form-control"
                          value={selectedBuildStructure}
                          onChange={(e) =>
                            setSelectedBuildStructure(e.target.value)
                          }
                        >
                          <option value="">Select a building structure</option>
                          {getBuildingStructure.map((build) => (
                            <option key={build._id} value={build._id}>
                              {build.buildingStructure}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Building Rating */}
                    <div className="col-md-4 mb-2">
                      <label>Building Rating</label>
                      <div>
                        <StarRatings
                          rating={buildingRating}
                          starRatedColor="blue"
                          changeRating={handleBuildingRatingChange}
                          numberOfStars={5}
                          name="rating"
                          starDimension="30px"
                        />
                      </div>
                    </div>
                    {/* customer rating */}
                    <div className="col-md-4 mb-2">
                      <label>Customer Rating</label>
                      <div>
                        <StarRatings
                          rating={customerRating}
                          starRatedColor="blue"
                          changeRating={handleCustomerRatingChange}
                          numberOfStars={5}
                          name="rating"
                          starDimension="30px"
                        />
                      </div>
                    </div>
                    {/* Project value */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project value</label>
                        <div className="d-flex">
                          <input
                            className="form-control"
                            id="projectValue"
                            value={projectValue}
                            placeholder="Enter a project value"
                            onChange={(e) => setProjectValue(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* selected project duration */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label>
                          Project Duration
                        </label>
                        <select
                          className="form-select form-control"
                          value={selectedProjectDuration}
                          onChange={(e) => setSelectedProjectDuration(e.target.value)}
                          required
                        >
                          <option value="">Select duration</option>
                          <option value="Days">Days</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Months">Months</option>
                          <option value="Year">Year</option>
                        </select>
                      </div>
                    </div>
                    {/* selected status */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status</label>
                        <select
                          className="form-select form-control"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          required
                        >
                          <option value="">Select a status</option>
                          {getLeadStatus.map((status) => (
                            <option key={status._id} value={status._id}>
                              {status.leadStatus}
                            </option>
                          ))}
                          {getLeadStatus.some(status =>
                            status._id === "order success" &&
                            editingLiveMarketing &&
                            editingLiveMarketing.quotationApproval.some(approval =>
                              approval.approvalStatus === "approved"
                            )
                          ) && (
                              <option value="order-success">Order Success</option>
                            )}
                        </select>
                      </div>
                    </div>
                    {/* selected cancel reason */}
                    {selectedStatus === cancelStatusId && (
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          {/* Form */}
                          <label htmlFor="inlineFormInputName2">Cancel reason</label>
                          <select
                            className="form-select form-control"
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            required
                          >
                            <option value="">Select a Reason</option>
                            {getLeadCancelReason.map((reason, index) => (
                                <option key={index} value={reason}>
                                  {reason.cancelReason}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="col-md-3 mb-2">
                      <div class="form-check form-check-success mb-5">
                        <label class="form-check-label text-start mt-4 mt-lg-0">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={handleProjectCheckboxChange}
                            checked={isProjectChecked}
                          />
                          Project <i class="input-helper"></i>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      {/* Pincode */}
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            placeholder="Enter a pincode"
                            value={pincode}
                            onChange={handlePincodeChange}
                            onKeyUp={handlePincodeBlur}
                          />
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="state"
                            placeholder="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* City */}
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="city"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Area Name */}
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label htmlFor="city">Area Name</label>
                          <select
                            id="customerCredits"
                            className="form-select form-control"
                            value={selectedAreaName}
                            onChange={(e) => setSelectedAreaName(e.target.value)}
                          >
                            <option value="">Select Area</option>
                            {areas.map((area, index) => (
                              <option key={index} value={area}>
                                {area}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* address */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <textarea
                            id="address"
                            className="form-control"
                            placeholder="Enter an address"
                            rows="4"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <hr />

                    {/* image */}
                    {selectedImage && (
                      <div>
                        <label>Captured image</label>
                        <img
                          src={selectedImage}
                          alt="Selected Image"
                          className="d-block m-3 ms-0"
                        />
                      </div>
                    )}
                  </div>

                  {/* Map */}
                  <div>
                    <button type="button" onClick={getCurrentLocation}>Get Current Location</button>
                    {loadScriptLoaded ? renderMap() : <p>Loading...</p>}
                    {loadError && <p>Error loading map!</p>}
                  </div>

                  <canvas ref={canvasRef} style={{ display: "none" }} />

                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={getAddTabValue}>
                      <Box>
                        <TabList
                          onChange={handleAddTabChange}
                          aria-label="lab API tabs example"
                          textColor="secondary"
                          indicatorColor="secondary"
                        >
                          <Tab label="FollowUp" value="1" />
                          <Tab label="Quotation" value="2" />
                        </TabList>
                      </Box>
                      {/* Add followUP */}
                      <TabPanel value="1" onClick={() => handleAddChangeTab(0)}>
                        <div className="col-md-2">
                          <button
                            type="button"
                            onClick={addAdditionalField}
                            className="btn btn-gradient-success btn-rounded btn-icon"
                          >
                            <i class="fa fa-calendar"></i>
                          </button>
                          &nbsp; New Follow
                        </div>
                        {/* Additional field */}

                        <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                          FollowUp History
                        </h4>
                        <h4
                          className="text-info"
                          style={{ marginBottom: "5px" }}
                        >{`Total FollowUps - ${additionalFields.length}`}</h4>
                        {additionalFields.map((field, index) => (
                          <div key={field.id} className="card follow-cards shadow">
                            <div class="card-header">
                              <div class="row">
                                <div class="col">
                                  <label class="badge badge-info me-4">
                                    Follow {index + 1}
                                  </label>
                                  <label class="badge badge-info me-4">
                                    {new Date().toLocaleString() + ""}
                                  </label>
                                  <label class="badge badge-info me-4">
                                    {adminName}
                                  </label>
                                </div>
                                <div className="col text-end">
                                  {/* Remove button */}
                                  <button
                                    type="button"
                                    onClick={() => removeAdditionalField(index)}
                                    className="btn btn-sm badge-gradient-danger"
                                    aria-label="Close"
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div class="row">
                                {/* Next follow-up date picker */}
                                <div className="col-md-4">
                                  <label>Next Follow-Up</label>
                                  <input
                                    type="datetime-local"
                                    class="form-control"
                                    placeholder="Next Follow Date"
                                    value={
                                      field.nextFollowupDate
                                        ? moment(field.nextFollowupDate).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                        : ""
                                    }
                                    required
                                    min={currentDateTime}
                                    onChange={(e) =>
                                      handleDateChange(
                                        new Date(e.target.value),
                                        index,
                                        "nextFollowup"
                                      )
                                    }
                                  />
                                </div>
                                {/* Remarks */}
                                <div className="col-md-4">
                                  <label htmlFor={`remarks-${index}`}>Remarks</label>
                                  <textarea
                                    id={`remarks-${index}`}
                                    className="form-control"
                                    placeholder="Enter remarks"
                                    rows="3"
                                    value={field.remarks}
                                    required
                                    onChange={(e) =>
                                      handleRemarksChange(index, e.target.value)
                                    }
                                  ></textarea>
                                </div>

                                {/* Upload Image 1*/}
                                {/* <div className="col-md-4">
                                <label>Add Photo</label>
                                <br />
                                {!capturedImage[index] && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={openModal}
                                  >
                                    Camera
                                  </button>
                                )}
                                {capturedImage[index] && (
                                  <div className="image-preview row">
                                    <div className="image-preview-item col-md-4">
                                      {!capturedImage[index]
                                        .toString()
                                        .match("data") ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_DATA_SERVER +
                                            capturedImage[index]
                                          }
                                          alt={`Captured ${index}`}
                                        />
                                      ) : (
                                        <img
                                          src={capturedImage[index]}
                                          alt={`Captured ${index}`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div> */}
                                {/* Upload Image 2 */}
                                <div className="col-md-4">
                                  {!field.imageSource && (
                                    <>
                                      <input className="form-control" type="file" accept="image/*" onChange={(e) => setImageFile(e.target.files[0])}></input>
                                      <button type="button" className="btn btn-outline-success btn-icon-text" onClick={() => handleUpload(index)}>
                                        <i className="mdi mdi-upload btn-icon-prepend"></i>
                                        Upload Image
                                      </button>
                                    </>
                                  )}
                                  {field.imageSource && (
                                    <>
                                      <a href={process.env.REACT_APP_DATA_SERVER + field.imageSource} target="_blank" rel="noopener noreferrer">
                                        <img
                                          src={process.env.REACT_APP_DATA_SERVER + field.imageSource}
                                          width="150px"
                                          alt="captured image"
                                          className="d-block m-3 ms-0"
                                          onClick={() => handleImageClick(process.env.REACT_APP_DATA_SERVER + field.imageSource)}
                                        />
                                      </a>
                                    </>
                                  )}
                                  {/* Image model */}


                                </div>
                              </div>
                              <div class="row">
                                <div className="col-md-4 text-center align-self-center">
                                  <div class="form-check form-check-success">
                                    <label class="form-check-label text-start">
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        onChange={(e) =>
                                          handleEscaltionCheckbox(
                                            index,
                                            e.target.checked
                                          )
                                        }
                                        checked={isEscalateChecked[index] || false}
                                      ></input>
                                      Escalation <i class="input-helper"></i>
                                    </label>
                                  </div>
                                </div>
                                {/* Excalation */}
                                <div className="col-md-4">
                                  {/* Form */}
                                  <h4 className="card-title"></h4>
                                  <div className="form-group">
                                    <label htmlFor="inlineFormInputName2">
                                      Escalation To
                                    </label>
                                    <select
                                      className="form-select form-control"
                                      value={selectedEscalationUser[index]}
                                      onChange={(e) =>
                                        handleEscalationChange(index, e.target.value)
                                      }
                                      disabled={isDropdownDisabled}
                                    >
                                      <option value="">Select Team User</option>
                                      {filteredEscalationUsers.map((teamUser) => (
                                        <option key={teamUser._id} value={teamUser._id}>
                                          {teamUser.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {/* sample  */}
                                <div className="col-md-4 text-center align-self-center">
                                  <div class="form-check form-check-success">
                                    <label class="form-check-label text-start">
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        onChange={(e) =>
                                          handleSampleCheckbox(index, e.target.checked)
                                        }
                                        checked={isSampleChecked[index] || false}
                                      ></input>
                                      Sample issues <i class="input-helper"></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </TabPanel>
                      {/* Quotational history */}
                      <TabPanel value="2" onClick={() => handleAddChangeTab(1)}>
                        {/* Products */}
                        {shouldShowButton && (
                          <div className="col-md-4">
                            <div className="form-group w-100">
                              <label>Search Product</label>
                              <input
                                type="text"
                                placeholder="Search by product"
                                value={searchProduct}
                                onChange={(e) => setSearchProduct(e.target.value)}
                                className="form-control"
                              />
                              {searchProduct && (
                                <div className="search-results">
                                  {filteredProductType.map((product) => {
                                    const productCategory = getProductCategory.find(
                                      (cat) => cat._id === product.product.productCategory
                                    )?.productCategory || 'Unknown';

                                    return (
                                      <div
                                        key={product._id}
                                        className="search-item"
                                        onClick={() => handleProductSelect(product._id)}
                                        style={{
                                          cursor: 'pointer',
                                          padding: '10px',
                                          borderBottom: '1px solid #ddd',
                                        }}
                                      >
                                        {productCategory} - {product.product.product} - {product.productTypes} - {product.brand.brand}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {shouldShowButton && (
                          <div className="">
                            {selectedProductList.length > 0 && (
                              <div className="">
                                {selectedProductList.map((product, index) => (
                                  <div key={index} className="row">
                                    <div className="form-group col-md-3">
                                      <label>Product Category</label>
                                      <input
                                        type="text"
                                        readOnly
                                        className="form-control"
                                        value={product}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <label htmlFor="workNature">Unit</label>
                                      <select
                                        className="form-select"
                                        value={selectedUnit[index]}
                                        onChange={(e) => handleUnitChange(index, e.target.value)}
                                      >
                                        <option value="">Select a unit</option>
                                        {getUnit.map((unit) => (
                                          <option key={unit._id} value={unit._id}>
                                            {unit.unit}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div className="form-group col-md-2">
                                      <label>Quantity</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={quantity[index]}
                                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                                      />
                                    </div>
                                    <div className="form-group col-md-2">
                                      <label>Given Price</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={givenPrice[index]}
                                        onChange={(e) => handleGivePrice(index, e.target.value)}
                                      />
                                    </div>
                                    {/* expected price */}
                                    <div className="form-group col-md-2">
                                      <label>Asked Price</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={expectedPrice[index]}
                                        onChange={(e) => handleExpectedPrice(index, e.target.value)}
                                      />
                                    </div>
                                    <div className="form-group col-md-1">
                                      <button
                                        type="button"
                                        onClick={() => removeDropdown(index)}
                                        className="btn btn-sm badge-gradient-danger"
                                        aria-label="Close"
                                        style={{ marginTop: "30px" }}
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  </div>
                                ))}

                                {/* Add Quotational Button */}

                                <div className="col-md-6">
                                  <button
                                    type="button"
                                    onClick={handleCreateQuotationalHistory}
                                    className="btn btn-gradient-success btn-rounded btn-icon"
                                  >
                                    <i className="fa fa-calendar"></i>
                                  </button>
                                  &nbsp; Create Quotation
                                </div>

                              </div>
                            )}
                          </div>
                        )}

                        <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                          Quotational History
                        </h4>
                        {/* Quotational history */}
                        <div className="col-md-12">
                          {quotationalHistories && quotationalHistories.map((quotationalHistory, index) => {
                            let approvalStatus;

                            if (editingLiveMarketing && editingLiveMarketing.quotationApproval && editingLiveMarketing.quotationApproval.length > 0) {
                              let history = 0;
                              let lastIndex = 0;
                              for (let i = 0; i < editingLiveMarketing.quotationApproval.length; i++) {
                                const quotationApproval = editingLiveMarketing.quotationApproval[i];
                                if (
                                  quotationApproval?.approvalStatus !== "transfer" &&
                                  quotationApproval?.approvalStatus !== "forAdminApproval" &&
                                  i >= lastIndex
                                ) {
                                  if (index === history) {
                                    approvalStatus = quotationApproval?.approvalStatus ?? "pending";
                                    lastIndex = i;
                                    //-console.log("i", i);
                                    //-console.log("history", history);
                                    //-console.log("history & Index", history, index);
                                    //-console.log("approvalStatus", approvalStatus);
                                    break;
                                  }
                                  history++;
                                }
                              }
                            } else {
                              approvalStatus = 'pending';
                            }


                            return (
                              <div key={index} className="mt-4">
                                <div className="card follow-cards shadow">
                                  <div className="card-header">
                                    <div className="row">
                                      <div className="col">
                                        <label className="badge badge-info me-4">
                                          Quotation {index + 1}
                                        </label>
                                        <label className="badge badge-info me-4">
                                          {new Date().toLocaleString()}
                                        </label>
                                        <label className="badge badge-info me-4">
                                          {adminName}
                                        </label>
                                      </div>
                                      <div className="col text-end">
                                        {/* Generate button */}
                                        {approvalStatus === "approved" ? (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              generateQuotation(editingLiveMarketing, index);
                                            }}
                                            className="btn btn-sm btn-success"
                                          >
                                            Generate Quotation
                                          </button>
                                        ) : (
                                          <p className="ps-2 fw-2" style={{ color: "green" }}>
                                            {approvalStatus}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Product Category</th>
                                        <th>Unit</th>
                                        <th>Quantity</th>
                                        <th>Given Price</th>
                                        <th>Expected Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {quotationalHistory.map((product, idx) => (
                                        <tr key={idx}>
                                          <td>{product.productType}</td>
                                          <td>{product.unit}</td>
                                          <td>{product.quantity}</td>
                                          <td>{product.givenPrice}</td>
                                          <td>{product.expectedPrice}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>

                  <div className="fixed-alerts-bottom-right">
                    <div className="alert-container">
                      {alertMessages.length > 0 && (
                        alertMessages.map((alert, index) => (
                          <div key={index} className="container-fluid text-start mb-2">
                            <div className="alert alert-info alert-dismissible fade show" role="alert">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <h6 className="d-inline">Product - </h6>
                                  <p className="d-inline">{alert.product}</p>
                                  <br />
                                  <h6 className="d-inline">Description - </h6>
                                  <p className="d-inline">{alert.description}</p>
                                </div>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="alert"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  <Loader isLoading={isLoading} />
                  {/* submit button */}
                  <div className="row text-start w-75 d-flex flex-wrap">
                    {/* First Button: Add/Update LiveMarketing */}
                    <div className="col-md-3 col-12 mb-2">
                      {userRole && userRole.pageList.find(page => page.pageName === "Live Marketing").access[2] === 1 && (
                        <button type="submit" className="btn btn-sm btn-primary w-100">
                          {editingLiveMarketing ? "Update LiveMarketing" : "Add LiveMarketing"}
                        </button>
                      )}
                    </div>

                    {/* Second Button: Product Suggest */}
                    <div className="col-md-3 col-12 mb-2">
                      <button
                        type="button"
                        onClick={handleProductSuggest}
                        className="btn btn-sm btn-success w-100"
                      >
                        Product Suggest
                      </button>
                    </div>

                    {/* Third Button: Delete (if applicable) */}
                    <div className="col-md-3 col-12 mb-2">
                      {userRole?.pageList.some(page => page.pageName === "Live Marketing" && page.access[3] === 1) && editingLiveMarketing && (
                        <button
                          type="button"
                          className="btn btn-sm btn-danger w-100"
                          onClick={handleDeleteLiveMarketing}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                  {/* model for product suggestion */}
                  {showProdSuggestModal && (
                    <div className="modal fade show d-block modelbox" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content" style={{ boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }}>
                          <div className="modal-header">
                            <h5 className="modal-title text-danger" style={{ marginLeft: "45px" }}>Product Suggestion</h5>
                            <button
                              type="button"
                              className="btn btn-sm badge-gradient-danger"
                              onClick={handleClose}
                              style={{ marginTop: "6px" }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="container">
                              <div className="row g-2">
                                {selectedProdCatProduct && selectedProdCatProduct.length > 0 &&
                                  selectedProdCatProduct.map((catProd, idx) => {
                                    const productCategoryValue = getProductCategory.find(
                                      (productCat) => productCat._id === catProd.product.productCategory
                                    );
                                    return (
                                      <div key={`${idx}-${catProd?._id}`} className="col-12 col-md-4 mb-3">
                                        <div className="card product-card">
                                          <div className="card-body d-flex">
                                            <div>
                                              {catProd?.productImage ? (
                                                <img
                                                  src={`${process.env.REACT_APP_API_SERVER}/${catProd.productImage}`}
                                                  width="100px"
                                                  height="100px"
                                                  alt="Product"
                                                  className="product-image"
                                                />
                                              ) : (
                                                <h6 className="no-image-text">No image</h6>
                                              )}
                                            </div>
                                            <div className="product-info  ms-2">
                                              <p className="card-text">
                                                <strong>Product Category:</strong> {productCategoryValue?.productCategory || 'N/A'}
                                              </p>
                                              <p className="card-text">
                                                <strong>Product Code:</strong> {catProd?.productCode || 'N/A'}
                                              </p>
                                              <p className="card-text">
                                                <strong>Product:</strong> {catProd?.product?.product || 'N/A'}
                                              </p>
                                              <p className="card-text">
                                                <strong>GST:</strong> {catProd?.productGST || 'N/A'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer py-4">

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div >
        </div >

        {/* customer creation */}
        <div>
          <div className={`offcanvas offcanvas-end w-50 ${showCustomerForm ? "show" : ""}`}>
            <div className="offcanvas-header">
              <h5 className="offcanvas-title">Customer Creation</h5>
              <button type="button" className="btn-close" onClick={() => setShowCustomerForm(false)}></button>
            </div>
            <div className="offcanvas-body">
              <CustomerCreation />
            </div>
          </div>
        </div>

        {
          showAlert && (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 1050
              }}
            >
              <strong>Submitted successfully</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )
        }


        {/* download quotation  */}
        {
          QModal && (
            <div
              className="modal quotation-generator"
              tabIndex="-1"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body bg-white">
                    <button
                      type="button"
                      className="btn-close float-end"
                      onClick={closeQModal}
                      aria-label="Close"
                    ></button>
                    <QuotationGenerator quotation_Data={editingLiveMarketing} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* Tab */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={getTabValue}>
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                width: '100%',
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ display: 'flex', minWidth: 'max-content' }}
              >
                <Tab label="Open Leads" value="1" />
                <Tab label="Quotation" value="2" />
                <Tab label="Esclation" value="3" />
                <Tab label="Converted" value="4" />
                <Tab label="Cancelled" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(0)}>
              <div className="container px-0">
                <div className="col-12">
                  {userRole && userRole.pageList.find(page => page.pageName === "Live Marketing").access[1] === 1 && (
                    <button
                      onClick={() => {
                        toggleLiveForm();
                        addAdditionalField();
                      }}
                      className="btn btn-gradient-primary mb-2"
                      type="button"
                    >
                      Create Live Marketing
                    </button>
                  )}
                </div>
                {/* collapse table */}

                <div className="row">
                  <div className="col-md-4">
                    <label>From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fromDate1}
                      onChange={(e) => handleFromDateChange(0, e)}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={toDate1}
                      onChange={(e) => handleToDateChange(0, e)}
                    />
                  </div>
                  <div className="col-md-3">
                    <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(0)}>Search date</button>
                    {searchClicked1 && (
                      <button
                        onClick={() => handleClearFilter(0)}
                        className="btn btn-sm badge-gradient-danger"
                        aria-label="Close"
                        style={{ marginLeft: "10px", marginTop: "30px" }}
                      >
                        &times;
                      </button>
                    )}
                  </div>
                </div>
                <div className="row justify-content-end m-1">
                  <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchTableData}
                    onChange={handleTableSearchChange}
                    className="col-12 col-lg-6"
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body table-responsive">
                        <h4 className="card-title">List of Open Live</h4>
                        <Paper sx={{ height: 400, width: '100%' }}>
                          <DataGrid
                            rows={filteredOpenRows()}
                            columns={columns}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                              },
                            }}
                            pageSizeOptions={[5, 10]}
                            onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                          />
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="2" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(1)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate2}
                    onChange={(e) => handleFromDateChange(1, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate2}
                    onChange={(e) => handleToDateChange(1, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(1)}>Search date</button>
                  {searchClicked2 && (
                    <button
                      onClick={() => handleClearFilter(1)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Quotation Live</h4>
                      <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={filteredQuotationRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(2)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate3}
                    onChange={(e) => handleFromDateChange(2, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate3}
                    onChange={(e) => handleToDateChange(2, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(2)}>Search date</button>
                  {searchClicked3 && (
                    <button
                      onClick={() => handleClearFilter(2)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Escalation Live</h4>
                      <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={filteredEscalationRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="4" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(3)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate4}
                    onChange={(e) => handleFromDateChange(3, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate4}
                    onChange={(e) => handleToDateChange(3, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(3)}>Search date</button>
                  {searchClicked4 && (
                    <button
                      onClick={() => handleClearFilter(3)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Order Success Live</h4>
                      <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={filteredOrderSuccessRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="5" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(4)}>
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate5}
                    onChange={(e) => handleFromDateChange(4, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate5}
                    onChange={(e) => handleToDateChange(4, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(4)}>Search date</button>
                  {searchClicked5 && (
                    <button
                      onClick={() => handleClearFilter(4)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Cancel Live</h4>
                      <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={filteredCancelRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </React.Fragment >
  );
};
function createData(
  cus_name,
  item,
  lead_date,
  pincode,
  quotationalHistories,

) {
  return {
    cus_name,
    item,
    lead_date,
    pincode,
    quotationalHistories,
  };
}

export default LiveMarketing;
