import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import api from '../Api';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Loader from './Loader';

const columns = [
  { field: 'id', headerName: 'Number', width: 100 },
  { field: 'leadSource', headerName: 'Lead Source', width: 230 },
];

const CreateLeadSource = ({ adminId }) => {
  const [searchTableData, setSearchTableData] = useState('');
  const [leadSource, setLeadSource] = useState("");
  const [getLeadSource, setGetLeadSource] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingLeadSource, setEditingLeadSource] = useState(null);
  const [deleteLeadSource, setDeleteLeadSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ visible: false, message: "", type: "" });
  const [getUser, setGetUser] = useState([]);
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [userRole, setUserRole] = useState(null);

  const handleSetLeadSource = (e) => {
    setLeadSource(e.target.value);
  };

  const fetchUser = async () => {
    try {
      const response = await api.get("/user");
      setGetUser(response.data);
    } catch (error) {
      console.error("Error fetching User:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  useEffect(() => {
    const getUserRole = (adminId) => {
      const user = getUser.find((user) => user._id === adminId);
      if (user) {
        const loginuserRole = getAdminRole.find((role) => role._id === user.adminRole._id);
        setUserRole(loginuserRole);
        return loginuserRole?.roleName || null;
      } else {
        return null;
      }
    };
    const role = getUserRole(adminId);
  }, [adminId, getUser, getAdminRole]);

  const handleAddLead = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if the lead source already exists
    const isDuplicate = getLeadSource.some(source => source.leadSource === leadSource && (!editingLeadSource || source._id !== editingLeadSource._id));

    if (isDuplicate) {
      setIsLoading(false);
      setShowAlert({ visible: true, message: "Lead source already exists.", type: "danger" });
      return;
    }

    try {
      if (editingLeadSource) {
        await api.put(`leadSource/${editingLeadSource._id}`, { leadSource });
        setEditingLeadSource(null);
      } else {
        await api.post("/leadSource", { leadSource });
      }
      setIsLoading(false);
      setLeadSource("");
      fetchLeadSources();
      setShowForm(false);
      setShowAlert({ visible: true, message: "Submitted successfully", type: "success" });
    } catch (error) {
      console.error("Error adding/updating lead source:", error);
      setShowAlert({ visible: true, message: "Error adding/updating lead source.", type: "error" });
    }
  };


  const handleDeleteLeadSource = async () => {
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.delete(`/leadSource/${deleteLeadSource._id}`);
      if (response.status === 200) {
        fetchLeadSources();
        setShowForm(false);
        setIsLoading(false);
        setShowAlert({ visible: true, message: "Submitted successfully", type: "success" });
      } else {
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      setShowAlert({ visible: true, message: "Error deleting item.", type: "error" });
    } finally {
      setIsLoading(false);
    }
  };


  const fetchLeadSources = async () => {
    try {
      const response = await api.get("/leadSource");
      setGetLeadSource(response.data.map((item, index) => ({ ...item, id: index + 1 })));
    } catch (error) {
      console.error("Error fetching lead sources:", error);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setEditingLeadSource(null);
    setLeadSource("");
  };

  const handleEditingLeadSource = (leadSource) => {
    setIsLoading(false);
    setShowAlert({ visible: false, message: '', type: '' });
    setEditingLeadSource(leadSource);
    setDeleteLeadSource(leadSource);
    setLeadSource(leadSource.leadSource);
    setShowForm(true);
  };

  useEffect(() => {
    fetchLeadSources();
  }, []);

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])



  const matchesSearchTerm = (row) => {
    const item = row || {};
    const leadSource = item.leadSource.toLowerCase() || '';
    return (
      leadSource.includes(searchTableData.toLowerCase())
    );

  };

  const filteredTableRows = searchTableData
    ? getLeadSource.filter(matchesSearchTerm)
    : getLeadSource;

  const handleTableSearchChange = (event) => {
    setSearchTableData(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white me-2">
              <i className="fa fa-bullseye"></i>
            </span>{" "}
            Lead Source
          </h3>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-12">
          {userRole && userRole.pageList.find(page => page.pageName === "Lead Source").access[1] === 1 && (
            <button
              onClick={() => toggleForm()}
              className="btn btn-gradient-primary mb-2"
              type="button"
            >
              Create Lead Source
            </button>
          )}
        </div>
        <div className="row justify-content-end mx-1">
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchTableData}
            onChange={handleTableSearchChange}
            className="col-12 col-lg-6"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <div
          className={`offcanvas offcanvas-end w-50 ${showForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              {editingLeadSource ? "Edit Lead Source" : "Create Lead Source"}
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <form
              onSubmit={handleAddLead}
              style={{
                display: showForm || (editingLeadSource ? "block" : "none"),
              }}
            >
              <div className="mb-3">
                <label htmlFor="leadSource" className="form-label">
                  Lead Source
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="leadSource"
                  value={leadSource}
                  onChange={handleSetLeadSource}
                />
              </div>
              <Loader isLoading={isLoading} />
              <div className="row text-start">
                <div className="col-6 col-md-3 text-start">
                  {userRole && userRole.pageList.find(page => page.pageName === "Lead Source").access[2] === 1 && (
                    <button type="submit" className="btn btn-sm btn-primary">
                      {editingLeadSource ? "Update Lead Source" : "Add Lead Source"}
                    </button>
                  )}
                </div>
                <div className="col-6 col-md-3 align-items-center">
                  {userRole?.pageList.some(page => page.pageName === "Lead Source" && page.access[3] === 1) && editingLeadSource && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleDeleteLeadSource}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        {showAlert.visible && (
          <div
            className={`alert alert-${showAlert.type} alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1050
            }}
          >
            <strong>{showAlert.message}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert({ ...showAlert, visible: false })}
            ></button>
          </div>
        )}


        {/* DataGrid */}
        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body table-responsive">
                <h4 className="card-title">List of Lead Source</h4>
                <Paper sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={filteredTableRows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    onRowDoubleClick={(row) => handleEditingLeadSource(row.row)}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateLeadSource;
