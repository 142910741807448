import React, { useEffect, useState } from "react";
import api from "../Api";
import Paper from "@mui/material/Paper";
import { DataGrid } from '@mui/x-data-grid';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Loader from './Loader';
import * as XLSX from 'xlsx';


const CustomerCreation = ({ adminId }) => {

  const [searchTableData, setSearchTableData] = useState('');
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [alterMobile, setAlterMobile] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [address, setAddress] = useState("");
  const [showClearButton, setShowClearButton] = useState(false);
  const [getCustomerCreation, setGetCustomerCreation] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState("");
  const [getServices, setGetServices] = useState([]);
  const [selectedCustomerCategory, setSelectedCustomerCategory] = useState([]);
  // const [selectedCredits, setSelectedCredits] = useState("");
  const [dropdowns, setDropdowns] = useState([]);
  const [searchService, setSearchService] = useState("");
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  // const [getcustomerCredits, setGetcustomerCredits] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isStatusChecked, setIsStatusChecked] = useState(true);
  const [getCustomerType, setGetCustomerType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [getPincodeObjectId, setGetPincodeObjectId] = useState(null);
  const [userRole, setUserRole] = useState(null);


  const columns = [

    { field: 'number', headerName: 'Number', width: 100 },
    { field: 'name', headerName: 'Name', width: 220 },
    { field: 'mobile', headerName: 'Mobile', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'pincode', headerName: 'Pincode', width: 120 },
    { field: 'city', headerName: 'City', width: 120 },
    { field: 'area', headerName: 'Area', width: 120 },
  ];

  useEffect(() => {
    if (getCustomerCreation.length > 0) {
      //-console.log("Raw customer data:", getCustomerCreation);
      const transformedRows = getCustomerCreation.map((customer, index) => ({
        id: customer._id,
        number: index + 1,
        name: customer.name,
        mobile: customer.mobile,
        email: customer.email,
        address: customer.address,
        pincode: customer.pincode?.pincode || "-",
        city: customer.pincode?.district?.district || "-",
        area: customer.pincode?.area || "-",
        customerType: customer.customerType,
        pincodeData: customer.pincode,
        serviceList: customer.serviceList
      }));

      //-//-console.log(transformedRows);
      setRows(transformedRows);
    }
  }, [getCustomerCreation]);


  const matchesSearchTerm = (row) => {
    const item = row || {};
    const name = (item.name || '').toLowerCase();
    const mobile = item.mobile || '';
    const alterMobile = item.alterMobile || '';
    const email = (item.email || '').toLowerCase();
    const pincodeData = item.pincodeData || {};
    const pincode = (pincodeData.pincode?.toString() || '');
    const city = (pincodeData.district?.district || '').toLowerCase();
    const area = (pincodeData.area || '').toLowerCase();

    return (
      name.includes(searchTableData.toLowerCase()) ||
      email.includes(searchTableData.toLowerCase()) ||
      mobile.includes(searchTableData) ||
      alterMobile.includes(searchTableData) ||
      pincode.includes(searchTableData) ||
      city.includes(searchTableData.toLowerCase()) ||
      area.includes(searchTableData.toLowerCase())
    );
  };


  const filteredTableRows = searchTableData
    ? rows.filter(matchesSearchTerm)
    : rows;


  const handleTableSearchChange = (event) => {
    setSearchTableData(event.target.value);
  };

  const downloadExcel = async () => {
    // Set loading state to true
    setIsLoading(true);

    try {
      // Show alert message before proceeding
      // window.alert('Your download will begin shortly.');

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Define the headers
      const headers = columns.map(col => col.headerName);

      // Prepare the data
      const data = filteredTableRows.map(row => ({
        Number: row.number,
        Name: row.name,
        Mobile: row.mobile,
        Email: row.email,
        Address: row.address,
        Pincode: row.pincode,
        City: row.city,
        Area: row.area,
      }));

      // Combine headers and data
      const wsData = [headers, ...data.map(item => Object.values(item))];
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, 'Customers');

      // Write the workbook to binary string
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Create a blob and trigger download
      const blob = new Blob([s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);

      // Set the filename
      const fileName = `customers_${new Date().toISOString().split('T')[0]}.xlsx`;

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Use the filename here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Clean up the URL object
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel:', error);
    } finally {
      setIsLoading(false); // Ensure loading state is set to false
    }
  };



  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const handleAddCustomCreation = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const isDuplicate = getCustomerCreation.some(customer => {
      const enteredNumbers = [mobile, alterMobile].filter(num => num !== undefined && num !== "");

      const customerNumbers = [customer.mobile, customer.alterMobile].filter(num => num !== undefined && num !== "");

      const hasCommonNumbers = enteredNumbers.length && customerNumbers.length
        ? enteredNumbers.some(num => customerNumbers.includes(num))
        : false;

      const isEditingDifferent = editingCustomer && customer._id !== editingCustomer.id;

      return hasCommonNumbers && isEditingDifferent;
    });


    if (isDuplicate) {
      setIsLoading(false);
      setShowAlert({ visible: true, message: "Customer already exists.", type: "danger" });
      return;
    }

    try {
      const serviceList = selectedServiceList.map((serviceId, index) => ({
        serviceType: selectedServiceType[index]
      }));

      const statusValue = isStatusChecked ? 1 : 0;

      let response;
      if (editingCustomer) {
        response = await api.put(
          `/customerCreation/${editingCustomer.id}`,
          {
            name,
            mobile,
            alterMobile,
            email,
            state,
            serviceList,
            pincode: getPincodeObjectId,
            address,
            status: statusValue,
            customerType: selectedCustomerType,
          }
        );
      } else {
        response = await api.post("/customerCreation", {
          name,
          mobile,
          alterMobile,
          email,
          serviceList,
          customerType: selectedCustomerType,
          pincode: getPincodeObjectId,
          address,
          status: statusValue,
        });
      }

      if (response.status === 200) {
        clearForm();
        setShowForm(false);
        setEditingCustomer(null);
        fetchCustomerCreation();
        setIsLoading(false);
        setShowAlert({ visible: true, message: "Submitted successfully", type: "success" });
      } else {
        // Handle unexpected status codes if needed
      }
    } catch (error) {
      setShowAlert({ visible: true, message: "Error adding/updating customer", type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCustomer = async (e) => {
    e.preventDefault();


    // Confirm the deletion action
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }


    try {

      setIsLoading(true);
      // Make the API call to delete the item
      const response = await api.delete(`/customerCreation/${editingCustomer.id}`);


      if (response.status === 200) {
        setShowForm(false);
        fetchCustomerCreation();
        setIsLoading(false);
        setShowAlert({ visible: true, message: "Submitted successfully", type: "success" });
      } else {
        //-console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
    }
  };


  const fetchPincodeData = async (enteredPincode) => {
    if (/^\d{6}$/.test(enteredPincode)) {
      try {
        const response = await api.get(`/pincode/${enteredPincode}`);
        const { city, state, areas } = response.data;

        setCity(city);
        setState(state);
        setAreas(areas);
      } catch (error) {
        console.error('Error fetching pincode data:', error);
        setCity('');
        setState('');
        setAreas([]);
      }
    } else {
      setCity('');
      setState('');
      setAreas([]);
    }
  };


  const handlePincodeChange = (e) => {
    const enteredPincode = e.target.value;
    setPincode(enteredPincode);
  };

  const handlePincodeBlur = () => {
    fetchPincodeData(pincode);
  };


  const selectedAreaObjectId = async () => {
    if (!selectedAreaName) return;

    try {
      const response = await api.get(`/selectedArea/${selectedAreaName}`);
      setGetPincodeObjectId(response.data.pincodeObjectIds);
    } catch (error) {
      console.error("Error fetching pincode object ID:", error);
    }
  };

  useEffect(() => {
    if (selectedAreaName) {
      selectedAreaObjectId();
    }
  }, [selectedAreaName]);


  const clearForm = () => {
    setName("");
    setMobile("");
    setAlterMobile("");
    setEmail("");
    setState("");
    setCity("");
    setPincode("");
    setAddress("");
    setSelectedAreaName("");
    // setSelectedCredits("");
    setSelectedCustomerCategory("");
    setSelectedServiceList([]);
    setSelectedServiceType([]);
    setSearchService("");
  };


  const fetchUserRole = async () => {
    try {
      const response = await api.post("/userRole", { adminId });
      setUserRole(response.data);
    } catch (error) {
      console.error("Error fetching User Role:", error);
    }
  };

  useEffect(() => {
    if (adminId) {
      fetchUserRole();
    }
  }, [adminId]);


  const fetchServices = async () => {
    try {
      const response = await api.get(`/services`);
      setGetServices(response.data);
    } catch (error) {
      //-console.error("Error fetching services:", error);
    }
  };

  const fetchCustomerType = async () => {
    try {
      const response = await api.get(`/customerType`);
      setGetCustomerType(response.data);
    } catch (error) {
      //-console.error("Error fetching CustomerType:", error);
    }
  };


  const fetchCustomerCreation = async () => {
    try {
      const response = await api.get("/customerCreation");
      setGetCustomerCreation(response.data);
    } catch (error) {
      //-//-console.log(error);
    }
  };

  // const fetchcustomerCredits = async () => {
  //   try {
  //     const response = await api.get("/customerCredits");
  //     setGetcustomerCredits(response.data);
  //   } catch (error) {
  //     //-//-console.log(error);
  //   }
  // };

  useEffect(() => {
    fetchCustomerCreation();
    fetchServices();
    // fetchcustomerCredits();
    fetchCustomerType();
  }, []);

  // click

  const handleCustomerServicesClick = (serviceId) => {
    const service = getServices.find(
      (customerService) => customerService._id === serviceId
    );
    const combinedService = `${service.customerCategory.customerCategory} - ${service.workNature.workNature} - ${service.services}`;

    setSelectedServiceList((prevList) => {
      const updatedList = [...prevList, combinedService];
      //-//-console.log("Selected Service List:", updatedList);
      return [...prevList, combinedService];
    });

    setSelectedServiceType((prevTypes) => {
      const updatedTypes = [...prevTypes, service._id];
      //-//-console.log("Selected Service Types:", updatedTypes);
      return [...prevTypes, service._id];
    });

    setSearchService("");
    addDropdown();
  };

  useEffect(() => { }, [selectedServiceList]);

  // filter

  const filteredCustomerServices = getServices.filter((service) => {
    // Convert searchService to lowercase for case-insensitive comparison
    const searchTerm = searchService.toLowerCase();

    return (
      (service.customerCategory?.customerCategory?.toLowerCase().includes(searchTerm)) ||
      (service.workNature?.workNature?.toLowerCase().includes(searchTerm)) ||
      (service.services?.toLowerCase().includes(searchTerm))
    );
  });



  // add
  const addDropdown = () => {
    setDropdowns([...dropdowns, { id: dropdowns.length }]);
  };

  // remove
  const handleRemove = (indexToRemove) => {
    setDropdowns(dropdowns.filter((_, index) => index !== indexToRemove));
    const updatedCustomerCategory = selectedCustomerCategory.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedCustomerCategory(updatedCustomerCategory);
  };

  // handle editing the customer creation

  const handleEditingCustomer = (customer) => {
    //-console.log(customer);

    setIsLoading(false);
    setEditingCustomer(customer);
    setName(customer.name);
    setMobile(customer.mobile);
    setAlterMobile(customer.alterMobile);
    setEmail(customer.email);
    setAddress(customer.address);
    setSelectedCustomerType(customer.customerType);

    const selectedServices = customer.serviceList.map((service) =>
      service.serviceType ? service.serviceType.services : { services: "N/A" }
    );
    setSelectedServiceList(selectedServices);
    const selectedSerType = customer.serviceList.map((service) =>
      service.serviceType ? service.serviceType._id : { services: "N/A" }
    );
    setSelectedServiceType(selectedSerType);

    const pincode = customer.pincodeData || {};
    //-console.log("pincode", pincode);

    setPincode(pincode.pincode || "");
    setSelectedAreaName(pincode.area || "");
    setCity(pincode.district ? pincode.district.district : "");
    setState(pincode.state ? pincode.state.state : "");
    setShowForm(true);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setEditingCustomer(null);
    clearForm();
  };


  const handleStatusCheckboxChange = (e) => {
    setIsStatusChecked(e.target.checked);
  };

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])



  return (
    <React.Fragment>
      <div className="container">
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white me-2">
              <i className="fa fa-users"></i>
            </span>{" "}
            Customer Creation
          </h3>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-12">
          <button
            onClick={toggleForm}
            className="btn btn-gradient-primary mb-2"
            type="button"
          >
            Create Customer
          </button>
        </div>
        <div className="row justify-content-end mx-1">
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchTableData}
            onChange={handleTableSearchChange}
            className="col-12 col-lg-6"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>

        <div
          className={`offcanvas offcanvas-end w-50 ${showForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              {editingCustomer ? "Edit Customer" : "Create Customer"}
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body p-4">
            <form
              onSubmit={handleAddCustomCreation}
              style={{
                display: showForm || (editingCustomer ? "block" : "none"),
              }}
            >
              <div className="row">
                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="name">Customer Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={showCustomerCode}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter a name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      placeholder="Enter a mobile number"
                      value={mobile}
                      required
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="alterMobile">Alter Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      id="alterMobile"
                      placeholder="Enter a alter mobile"
                      value={alterMobile}
                      onChange={(e) => setAlterMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={email}
                      placeholder="Enter a email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="pincode">Pincode</label>
                    <input
                      type="text"
                      className="form-control"
                      id="pincode"
                      value={pincode}
                      placeholder="Enter a pincode"
                      onChange={handlePincodeChange}
                      onKeyUp={handlePincodeBlur}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="state">State</label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      value={state}
                      placeholder="Enter a state"
                      onChange={(e) => setState(e.target.value)}
                    // onKeyUp={handlePincodeChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={city}
                      placeholder="Enter a city"
                      onChange={(e) => setCity(e.target.value)}
                    // onKeyUp={handlePincodeChange}
                    />
                  </div>
                </div>
                {/* Area Name */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="city">Area Name</label>
                    <select
                      id="customerCredits"
                      className="form-select form-control"
                      value={selectedAreaName}
                      onChange={(e) => setSelectedAreaName(e.target.value)}
                    >
                      <option value="">Select Area</option>
                      {areas.map((area, index) => (
                        <option key={index} value={area}>
                          {area}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Search Customer Category</label>
                    <input
                      type="text"
                      placeholder="Search a Category"
                      value={searchService}
                      onChange={(e) => setSearchService(e.target.value)}
                      className="form-control mb-2"
                      onClick={() => setShowClearButton(true)}
                    />
                    {searchService && (
                      <select
                        className="js-example-basic-multiple"
                        multiple
                        style={{ width: "100%" }}
                      >
                        {filteredCustomerServices.map((service) => (
                          <option
                            key={service._id}
                            value={service._id}
                            onClick={() =>
                              handleCustomerServicesClick(service._id)
                            }
                          >
                            {service.customerCategory?.customerCategory || 'N/A'} - {service.workNature?.workNature || 'N/A'} - {service.services || 'N/A'}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  {selectedServiceType.map((service, index) => (
                    <div key={service.id} className="form-group col-md-4">
                      <div className="d-flex gap-2">
                        <div>
                          <label htmlFor="customerCategory">
                            Customer Category
                          </label>
                          <input
                            type="text"
                            id={`selectedService-${index}`}
                            readOnly
                            className="form-control"
                            value={selectedServiceList[index]}
                          />
                        </div>
                        {/* remove button */}
                        <div>
                          <div>
                            <button
                              onClick={() => handleRemove(index)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="row">
                {/* Credits */}
                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="customerCredits">Customer Credits</label>
                    <select
                      id="customerCredits"
                      className="form-select form-control"
                      value={selectedCredits}
                      onChange={(e) => setSelectedCredits(e.target.value)}
                    >
                      <option value="">Zero credit</option>
                      {getcustomerCredits.map((credit) => (
                        <option key={credit._id} value={credit._id}>
                          {credit.customerCredits}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                {/* Status */}
                <div className="col-md-3 d-flex align-items-center justify-content-center">
                  <div className="row ">
                    <div className="form-check form-switch col-12 d-flex mb-5">
                      <input
                        className="form-check-input p-1"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={isStatusChecked}
                        onChange={handleStatusCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Status
                      </label>
                    </div>
                  </div>
                </div>

                {/* Customer Type */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="customerCredits">Customer Types</label>
                    <select
                      id="customerCredits"
                      className="form-select form-control"
                      value={selectedCustomerType}
                      onChange={(e) => setSelectedCustomerType(e.target.value)}
                    >
                      <option value="">select the customerType</option>
                      {getCustomerType.map((customerType) => (
                        <option key={customerType._id} value={customerType._id}>
                          {customerType.customerType}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Address */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <textarea
                      id="address"
                      className="form-control"
                      placeholder="Enter an address"
                      rows="4"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <Loader isLoading={isLoading} />

              <div className="row text-start w-75">
                <div className="col-md-3 text-start">
                  <button type="submit" className="btn btn-sm btn-primary">
                    {" "}
                    {editingCustomer ? "Update Customer" : "Add Customer"}
                  </button>
                </div>
                <div className="col-md-3 align-items-center">
                  {userRole?.pageList.some(page => page.pageName === "Customer Creation" && page.access[3] === 1) && editingCustomer && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleDeleteCustomer}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        {showAlert.visible && (
          <div
            className={`alert alert-${showAlert.type} alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1050
            }}
          >
            <strong>{showAlert.message}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert({ ...showAlert, visible: false })}
            ></button>
          </div>
        )}

        {/* table */}
        <div className="row mt-4">
          <div className="col-lg-12">
            <button onClick={downloadExcel} className="btn btn-success mb-3">
              Download Excel
            </button>
            <div className="card">
              <div className="card-body table-responsive">
                <h4 className="card-title">List of Customers</h4>
                <Paper sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={filteredTableRows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    sx={{ border: 0 }}
                    onRowDoubleClick={(params) => handleEditingCustomer(params.row)}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};


export default CustomerCreation;
