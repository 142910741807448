import React, { useEffect, useState } from "react";
import "./Login.css";
import api from "../Api";
import { useNavigate } from "react-router-dom";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();


  const loginUser = async () => {
    try {
      const response = await api.post("/loginUser", { email, password });

      const foundUser = response.data;

      if (foundUser) {
        console.log("foundUser", foundUser);
        setEmail("");
        setPassword("");
        onLogin(foundUser);
        setMessage("");

        window.location.href = "/home";
        setTimeout(() => {
          navigate("/home");
        }, 100);
      } else {
        setMessage("Invalid email or password. Please try again.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setMessage("Login failed. Please check your credentials.");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginUser();
  };


  return (
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row flex-grow">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <img src="../../assets/images/logo.png" />
                </div>
                <h4>RPC Turbo Sales CRM</h4>
                <h6 class="font-weight-light">Authenticate yourself.</h6>
                <form
                  class="pt-3"
                  onSubmit={handleSubmit}
                  className="login-form"
                >
                  <div class="mb-2 d-grid gap-2">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      class="form-control form-control-lg"
                      placeholder="Email"
                    />
                  </div>
                  <div class="mb-2 d-grid gap-2">
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      class="form-control form-control-lg"
                      placeholder="Password"
                    />
                  </div>
                  <div class="mt-3 d-grid gap-2">
                    <button type="submit" className="btn btn-gradient-primary">
                      <i class="fa fa-rocket me-2"></i>Launch
                    </button>
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input" /> Keep
                        me signed in{" "}
                      </label>
                    </div>
                    <a href="#" class="auth-link text-primary">
                      Forgot password?
                    </a>
                  </div>
                  <div class="mb-2 d-grid gap-2">
                    <button
                      type="button"
                      class="btn btn-block btn-gradient-info auth-form-btn"
                    >
                      <i class="fa fa-telegram me-2"></i>OTP Login{" "}
                    </button>
                  </div>
                  {message && (
                    <div
                      className="d-grid gap-2"
                      class="alert alert-warning"
                      role="alert"
                    >
                      {message}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
