import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";

import * as XLSX from 'xlsx';

const IncentivePayments = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [incentiveData, setIncentiveData] = useState([]);
    const [selectedReport, setSelectedReport] = useState("Load References");
    const [selectedStaffName, setSelectedStaffName] = useState("");
    const [getUser, setGetUser] = useState([]);
    const [searchEmployee, setSearchEmployee] = useState("");
    const [searchTableData, setSearchTableData] = useState("");


    const fetchIncentiveData = async () => {
        if (!selectedReport || !selectedStaffName) return;
        try {
            const response = await api.get(`/incentive/${selectedReport}/${selectedStaffName}`);
            console.log("response", response.data);

            setIncentiveData(response.data);
            filterRowsByDate(fromDate, toDate)
        } catch (error) {
            console.error("Error fetching incentive report:", error);
        }
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        fetchIncentiveData();
    };



    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get("/user");
                setGetUser(response.data);
            } catch (error) {
                //-console.error("Error fetching User:", error);
            }
        };
        fetchUser();
    }, []);


    // Handle date changes
    const handleFromDateChange = (e) => {
        //-console.log("From date changed:", e.target.value);
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        //-console.log("To date changed:", e.target.value);
        setToDate(e.target.value);
    };



    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = incentiveData.filter((row) => {
            console.log("incentiveData", incentiveData);

            if (!row.paymentCollection || row.paymentCollection.length === 0) {
                return false;
            }

            // Get the last payment from paymentCollection

            const startDate = moment(fromDate);
            const endDate = moment(toDate).endOf('day');

            const paymentDate = moment(row.paymentCollection[row.paymentCollection.length - 1]?.paymentDate);

            const isBetween = paymentDate.isBetween(startDate, endDate, null, '[]')

            return isBetween;
        });
        console.log("filtered", filteredRows);

        setFilteredRows(filtered);
    };

    useEffect(() => {
        if (searchClicked) {
            filterRowsByDate(fromDate, toDate);
        }
    }, [incentiveData, fromDate, toDate, searchClicked]);

    useEffect(() => {
        if (selectedReport && selectedStaffName) {
            fetchIncentiveData();
        }
    }, [selectedReport, selectedStaffName]);


    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);


    const columns = [

        { field: 'number', headerName: 'Number', width: 100 },
        { field: 'staff', headerName: 'Staff', width: 220 },
        { field: 'customer', headerName: 'Customer', width: 200 },
        { field: 'date', headerName: 'Date', width: 180 },
        { field: 'report', headerName: 'Report', width: 180 },
        { field: 'quotation', headerName: 'QuotationValue', width: 140 },
        { field: 'profit', headerName: 'Profit', width: 130 },
    ];

    useEffect(() => {
        if (filteredRows.length > 0) {
            const transformedRows = filteredRows.map((item, index) => {
                console.log("item", item);
                const customer = item.name?.name || 'N/A';
                const date = item.createdAt || 'N/A';
                const quotationValue = item.loadCost[1]?.quoationValue || 0;
                const costPrice = item.loadCost[1]?.costPrice || 0;
                const profit = quotationValue - costPrice;

                return {
                    id: item._id,
                    number: index + 1,
                    staff: selectedStaffName,
                    customer: customer,
                    date: date,
                    report: selectedReport,
                    quotation: quotationValue,
                    profit: profit,
                };
            });

            console.log(transformedRows);
            setRows(transformedRows);
        } else {

            setRows([]);
        }
    }, [filteredRows, selectedReport, selectedStaffName]);


    const matchesSearchTerm = (row) => {
        // const item = row || {};
        // const name = (item.name || '').toLowerCase();
        // const mobile = item.mobile || '';
        // const alterMobile = item.alterMobile || '';
        // const email = (item.email || '').toLowerCase();
        // const pincodeData = item.pincodeData || {};
        // const pincode = (pincodeData.pincode?.toString() || '');
        // const city = (pincodeData.district?.district || '').toLowerCase();
        // const area = (pincodeData.area || '').toLowerCase();

        // return (
        //   name.includes(searchTableData.toLowerCase()) ||
        //   email.includes(searchTableData.toLowerCase()) ||
        //   mobile.includes(searchTableData) ||
        //   alterMobile.includes(searchTableData) ||
        //   pincode.includes(searchTableData) ||
        //   city.includes(searchTableData.toLowerCase()) ||
        //   area.includes(searchTableData.toLowerCase())
        // );
    };


    const filteredIncentiveReports = searchTableData
        ? rows.filter(matchesSearchTerm)
        : rows;



    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredIncentiveReports);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "EmployeeBased");
        XLSX.writeFile(workbook, "Employee_Based.xlsx");
    };

    const handleTableSearchChange = (event) => {
        setSearchTableData(event.target.value);
    };

    // // Handle clear filter
    // const handleClearFilter = () => {
    //     const currentDateFormatted = moment().format("YYYY-MM-DD");
    //     setFromDate(currentDateFormatted);
    //     setToDate(currentDateFormatted);
    //     setSearchEmployee("");
    //     setSelectedReport("Load References")
    //     setSearchClicked(false);
    //     filterRowsByDate(currentDateFormatted, currentDateFormatted);
    // };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Incentive Payment Reports
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="row">
                {/* From Date */}
                <div className="col-md-4">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-4">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Report Status</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                            required
                        >
                            <option selected value="Load References">Load References</option>
                            <option value="Lead References">Lead References</option>
                            <option value="Vendor References">Vendor References</option>
                            <option value="Collection References">Collection References
                            </option>
                        </select>
                    </div>
                </div>

                <div className="col-md-4 mb-2">
                    <div className="form-group">
                        <label htmlFor="staffNameSelect">Staff Name</label>
                        <select
                            id="staffNameSelect"
                            className="form-select form-control"
                            value={selectedStaffName}
                            onChange={(e) => {
                                const newStaffName = e.target.value;
                                console.log("Selected staff name:", newStaffName);
                                setSelectedStaffName(newStaffName);

                                if (selectedReport) {
                                    console.log("Fetching data for staff:", newStaffName);
                                    fetchIncentiveData();
                                }
                            }}
                            required
                        >
                            <option value="">Select Staff</option>
                            {getUser.map((staff) => (
                                <option key={staff._id} value={staff.name}>
                                    {staff.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-3 mt-2">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                </div>

                <div className="container">
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        value={searchTableData}
                        onChange={handleTableSearchChange}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            width: '50%',
                            marginLeft: 'auto',
                            display: 'flex',
                            padding: '10px'
                        }}
                    />
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body table-responsive">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h4 className="card-title">List of {selectedReport}</h4>
                                        </div>
                                        <div>
                                            <button onClick={downloadExcel} className="btn btn-success mb-3">
                                                Download Excel
                                            </button>
                                        </div>
                                    </div>
                                    <Paper sx={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={filteredIncentiveReports}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 5 },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            sx={{ border: 0 }}
                                        />
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default IncentivePayments;
