import React, { useEffect, useState } from "react";
import api from './Api';
import { useNavigate } from "react-router-dom";
import './Header.css';

const Header = ({ adminName, onLogout, roleName, adminId, onNotificationClick }) => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [todayQuoationalPendingLead, setTodayQuoationalPendingLead] = useState({ totalPendingQuotationalLead: 0 });
  const [todayQuoationalPendingLive, setTodayQuoationalPendingLive] = useState({ totalPendingQuotationalLive: 0 });
  const [todayQuoationalCancelLead, setTodayPendingCancelLead] = useState({ totalPendingCancelLead: 0 });
  const [todayQuoationalCancelLive, setTodayPendingCancelLive] = useState({ totalPendingCancelLive: 0 });
  const [notifications, setNotifications] = useState([]);
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  // Fetch users
  const fetchUser = async () => {
    try {
      const response = await api.get("/user");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching User:", error);
    }
  };


  // Fetch user notifications when the component mounts
  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const currentUser = users.find(user => user._id === adminId);
    if (currentUser) {
      setNotifications(currentUser.notifications || []);
    }
  }, [users, adminId]);


  useEffect(() => {
    const fetchPendingQuotationalTodayLeadData = async () => {
      if (!adminId) return;
      try {
        const openResponse = await api.get(`/findPendingQuoationalLead/${adminId}`);
        if (openResponse.data) {
          setTodayQuoationalPendingLead({
            totalPendingQuotationalLead: openResponse.data.totalQuotationalPendingLead,
          });
        }
      } catch (error) {
        console.error("Error fetching pending quotational lead count:", error);
      }
    };

    fetchPendingQuotationalTodayLeadData();
  }, [adminId]);


  useEffect(() => {
    const fetchPendingQuotationalTodayLiveData = async () => {
      if (!adminId) return;
      try {
        const openResponse = await api.get(`/findPendingQuoationalLive/${adminId}`);
        if (openResponse.data) {
          setTodayQuoationalPendingLive({
            totalPendingQuotationalLive: openResponse.data.totalPendingQuotationalLive,
          });
        }
      } catch (error) {
        console.error("Error fetching pending quotational lead count:", error);
      }
    };

    fetchPendingQuotationalTodayLiveData();
  }, [adminId]);



  useEffect(() => {
    const fetchPendingCancelTodayLeadData = async () => {
      if (!adminId) return;
      try {
        const openResponse = await api.get(`/findPendingCancelLead/${adminId}`);
        if (openResponse.data) {
          setTodayPendingCancelLead({
            totalPendingCancelLead: openResponse.data.totalPendingCancelLead,
          });
        }
      } catch (error) {
        console.error("Error fetching cancel lead count:", error);
      }
    };

    fetchPendingCancelTodayLeadData();
  }, [adminId]);

  useEffect(() => {
    const fetchPendingCancelTodayLiveData = async () => {
      if (!adminId) return;
      try {
        const openResponse = await api.get(`/findPendingCancelLive/${adminId}`);
        if (openResponse.data) {
          setTodayPendingCancelLive({
            totalPendingCancelLive: openResponse.data.totalPendingCancelLive,
          });
        }
      } catch (error) {
        console.error("Error fetching cancel lead count:", error);
      }
    };

    fetchPendingCancelTodayLiveData();
  }, [adminId]);

  const handleSignOut = () => {
    onLogout();
    navigate("/login");
  };

  // const handleNotificationClick = async (id) => {
  //   setNotifications(prevNotifications =>
  //     prevNotifications.map(notification =>
  //       notification._id === id ? { ...notification, unread: 0 } : notification
  //     )
  //   );
  // };

  const unreadCount = notifications.filter(notification => notification.unread > 0).length;

  const toggleShowAllNotifications = (event) => {
    event.stopPropagation();
    setShowAllNotifications(!showAllNotifications);
  };


  const displayedNotifications = showAllNotifications ? notifications : notifications.slice(0, 3);

  const handleNotificationClick = (notification) => {
    let activePage = "";

    switch (notification.subject) {
      case "Open Lead":
        activePage = "Lead Online";
        break;
      case "Open Live":
        activePage = "Live Marketing";
        break;
      case "Pending Quotation Live":
      case "Pending Quotation Lead":
        activePage = "Quotation Approval";
        break;
      case "Cancel Lead":
      case "Cancel Live":
        activePage = "Lead Cancelation";
        break;
      default:
        activePage = "AdminDashboard";
    }

    onNotificationClick(activePage);
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <a className="navbar-brand brand-logo">
          <img src="assets/images/logo.png" alt="logo" />
        </a>
        <a className="navbar-brand brand-logo-mini">
          <img src="assets/images/logo-mini.svg" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="mdi mdi-menu"></span>
        </button>
        <div className="search-field d-none d-md-block">
          <form className="d-flex align-items-center h-100" action="#">
            <div className="input-group">
              <div className="input-group-prepend bg-transparent">
                <i className="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
              <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects" />
            </div>
          </form>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown">
            <a className="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="nav-profile-img">
                <img src="assets/images/faces/face1.jpg" alt="image" />
                <span className="availability-status online"></span>
              </div>
              <div className="nav-profile-text">
                <p className="mb-1 text-black font-weight-bold">{adminName}</p>
                <p className="mb-0 text-muted">{roleName}</p>
              </div>
            </a>
            <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-cached me-2 text-success"></i> Activity Log
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#" onClick={handleSignOut}>
                <i className="mdi mdi-logout me-2 text-primary"></i> Signout
              </a>
            </div>
          </li>
          {/* Notification */}
          <div className="nav-item dropdown">
            <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
              <i className="mdi mdi-bell-outline bell-button" style={{ fontSize: '24px' }}></i>
              {unreadCount > 0 && (
                <span className="bell-button__badge">{unreadCount > 9 ? '9+' : unreadCount}</span>
              )}
            </a>
            <div className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
              <h6 className="p-3 mb-0">Notifications</h6>
              <div className="dropdown-divider"></div>
              {displayedNotifications.map(notification => (
                <a
                  key={notification._id}
                  className="dropdown-item preview-item d-flex align-items-center"
                  style={{ opacity: notification.unread ? 1 : 0.5 }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="preview-thumbnail" style={{ fontSize: '16px', marginRight: '10px' }}>
                    <div className={`preview-icon ${notification.unread ? 'bg-danger' : 'bg-success'}`}>
                      <i className="mdi mdi-calendar" style={{ fontSize: '16px' }}></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex justify-content-between flex-grow-1">
                    <h6 className="preview-subject font-weight-normal mb-0">{notification.subject}</h6>
                    <p className="text-gray mb-0 lead_count" style={{ marginLeft: '10px' }}>
                      {notification.unread}
                    </p>
                  </div>
                </a>
              ))}
              <div className="dropdown-divider"></div>
              <h6 className="p-3 mb-0 text-center" style={{ cursor: 'pointer' }} onClick={toggleShowAllNotifications}>
                {showAllNotifications ? 'Show less' : 'See all notifications'}
              </h6>
            </div>
          </div>
          <li className="nav-item nav-logout d-none d-lg-block">
            <a className="nav-link" href="#" onClick={handleSignOut}>
              <i className="mdi mdi-power"></i>
            </a>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Header;
