import React, { useState, useEffect } from 'react';
import api from '../Api';
import Loader from './Loader';

const Setting = () => {
    const [isTransferChecked, setIsTransferChecked] = useState(false);
    const [isIndiaMartChecked, setIsIndiaMartChecked] = useState(false);
    const [getSetting, setGetSetting] = useState(null);
    const [editingSetting, setEditingSetting] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ visible: false, message: '', type: '' });

    useEffect(() => {
        if (showAlert.visible) {
            const timer = setTimeout(() => {
                setShowAlert(prev => ({ ...prev, visible: false }));
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showAlert.visible])

    const handleTransferCheckboxChange = (event) => {
        setIsTransferChecked(event.target.checked);
    };

    const handleIndiaMartCheckboxChange = (event) => {
        setIsIndiaMartChecked(event.target.checked);
    };

    const handleSettingSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setShowAlert({ visible: true, message: '', type: '' });
        const settingData = {
            transferAssigned: isTransferChecked ? 1 : 0,
            indiaMartRequest: isIndiaMartChecked ? 1 : 0,
        };

        try {
            // Display an appropriate message before sending the request
            if (editingSetting) {
                await api.put(`/setting/${editingSetting._id}`, settingData);
                setShowAlert({ visible: true, message: 'Updated successfully!', type: 'success' });
            } else {
                const response = await api.post('/setting', settingData);
                setEditingSetting(response.data);
                setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
            }
        } catch (error) {
            setShowAlert({ visible: true, message: "Error adding/updating setting.", type: "danger" });
            console.error("Error adding/updating transferChecked in setting:", error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await api.get('/setting');
                if (response.data.length > 0) {
                    const setting = response.data[0];
                    setGetSetting(setting);
                    setIsTransferChecked(setting.transferAssigned === 1);
                    setIsIndiaMartChecked(setting.indiaMartRequest === 1);
                    setEditingSetting(setting);
                }
            } catch (error) {
                console.error("Error fetching setting:", error);
            }
        };
        fetchSetting();
    }, []);

    return (
        <React.Fragment>
            <div className='container'>
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2">
                            <i className="fa fa-gear"></i>
                        </span>
                        Setting
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview
                                <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="form-inline" onSubmit={handleSettingSubmit}>
                                <div className="col-md-3 mb-2">
                                    <div className="form-check form-check-danger mb-5">
                                        <label className="form-check-label text-start mt-4 mt-lg-0" htmlFor="transferCheckbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={handleTransferCheckboxChange}
                                                checked={isTransferChecked}
                                                id="transferCheckbox"
                                            />
                                            Auto-assign at 12:00 AM <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-2">
                                    <div className="form-check form-check-danger mb-5">
                                        <label className="form-check-label text-start mt-4 mt-lg-0" htmlFor="transferCheckbox">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={handleIndiaMartCheckboxChange}
                                                checked={isIndiaMartChecked}
                                                id="indiaMartCheckbox"
                                            />
                                            IndiaMart API pull request <i className="input-helper"></i>
                                        </label>
                                    </div>
                                </div>
                                <Loader isLoading={isLoading} />
                                <div className='row'>
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-sm btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {showAlert.visible && (
                    <div
                        className={`alert alert-${showAlert.type} alert-dismissible fade show`}
                        role="alert"
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            zIndex: 1050
                        }}
                    >
                        <strong>{showAlert.message}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setShowAlert({ ...showAlert, visible: false })}
                        ></button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Setting;
