import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import * as XLSX from 'xlsx';

const CustomerAgeing = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [getCustomerAgeing, setGetCustomerAgeing] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [getUnit, setGetUnit] = useState([]);
    const [getProductType, setGetProductType] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);

    useEffect(() => {
        const fetchCustomerAgeing = async () => {
            try {
                const response = await api.get('/customerAgeing');
                setGetCustomerAgeing(response.data.filteredCustomerAgeingData);
            } catch (error) {
                // console.error('Error fetching customer ageing:', error);
            }
        };
        fetchCustomerAgeing();
    }, []);

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await api.get("/unit");
                setGetUnit(response.data);
            } catch (error) {
                // console.log(error);
            }
        };
        fetchUnits();
    }, []);

    useEffect(() => {
        const fetchProductType = async () => {
            try {
                const response = await api.get("/productTypes");
                setGetProductType(response.data);
            } catch (error) {
                // console.log(error);
            }
        };
        fetchProductType();
    }, []);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                // console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = getCustomerAgeing.filter((item) => {
            const createdAt = moment(item.leadData.createdAt, "YYYY-MM-DD hh:mm A");
            return createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );
        });
        setFilteredRows(filtered);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };
        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        setFilteredRows([]);
        setSearchName("");
        setSelectedCustomerId(null);
    };

    const filteredCustomers = getCustomerCreation.filter((customer) => {
        const searchLower = searchName.toLowerCase();
        const name = customer?.name?.toLowerCase() || '';
        const alterMobile = customer?.alterMobile || '';
        const mobile = customer?.mobile || '';
        const customerCode = customer?.customerCode || '';
        return (
            name.includes(searchLower) ||
            alterMobile.includes(searchName) ||
            mobile.includes(searchName) ||
            customerCode.includes(searchName)
        );
    });

    const handleNameSelect = (customer) => {
        setSearchName(
            `${customer.name} - ${customer.mobile} - ${customer.alterMobile} - ${customer.customerCode}`
        );
        setSelectedCustomerId(customer._id);
    };

    // Calculate frequency only for consecutive rows
    const calculateFrequency = (rows) => {
        return rows.map((row, index) => {
            if (index === 0) return "N/A";
            const prevDate = moment(rows[index - 1].leadData.createdAt, "YYYY-MM-DD hh:mm A");
            const currDate = moment(row.leadData.createdAt, "YYYY-MM-DD hh:mm A");
            const diffDays = currDate.diff(prevDate, 'days');
            return `${diffDays} days`;
        });
    };

    // Sorting rows by date to ensure correct frequency calculation
    const sortedRows = [...filteredRows].sort((a, b) =>
        moment(a.leadData.createdAt).diff(moment(b.leadData.createdAt))
    );

    // Filtering rows for the selected customer
    const rowsForSelectedCustomer = selectedCustomerId
        ? sortedRows.filter(row => row.leadData.name === selectedCustomerId)
        : sortedRows;

    // Calculating frequency after sorting rows
    const frequencyDays = calculateFrequency(rowsForSelectedCustomer);

    // Define columns for DataGrid
    const columns = [
        { field: 'index', headerName: 'Number', width: 90 },
        { field: 'customerCode', headerName: 'Customer Code', width: 150 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'mobile', headerName: 'Mobile', width: 150 },
        { field: 'productName', headerName: 'Product Name', width: 200 },
        { field: 'unit', headerName: 'Unit', width: 100 },
        { field: 'quantity', headerName: 'Quantity', width: 120 },
        { field: 'givenPrice', headerName: 'Given Price', width: 120 },
        { field: 'expectedPrice', headerName: 'Expected Price', width: 120 },
        { field: 'createdAt', headerName: 'Created At', width: 180 },
        { field: 'frequency', headerName: 'Frequency (Days)', width: 150 },
    ];

    // Preparing rows for DataGrid
    const rows = (searchName && rowsForSelectedCustomer.length > 0 ? rowsForSelectedCustomer : filteredRows).map((row, index) => {
        const customer = getCustomerCreation.find(customer => customer._id === row.leadData.name);
        const selectedUnitObject = getUnit.find(unit => unit._id === row.product.unit);
        const unitName = selectedUnitObject ? selectedUnitObject.unit : 'N/A';

        const selectedProduct = getProductType.find(prod => prod._id === row.product.productType);
        const productName = selectedProduct
            ? `${selectedProduct.ProductCategory.productCategory} ${selectedProduct.product.product} ${selectedProduct.productTypes} ${selectedProduct.brand.brand}`
            : 'N/A';

        return {
            id: row._id, // Unique ID for each row
            index: index + 1,
            customerCode: customer?.customerCode,
            name: customer?.name,
            mobile: customer?.mobile,
            productName: productName,
            unit: unitName,
            quantity: row.product.quantity,
            givenPrice: row.product.givenPrice,
            expectedPrice: row.product.expectedPrice,
            createdAt: moment(row.leadData.createdAt).format("YYYY-MM-DD hh:mm A"),
            frequency: frequencyDays[index],
        };
    });

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CustomerAgeing");
        XLSX.writeFile(workbook, "CustomerAgeing.xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Customer Ageing
                </h3>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <div className="d-flex gap-2">
                        <div className="form-group w-100">
                            <label>Customer Name</label>
                            <input
                                type="text"
                                placeholder="Search a customer name"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                                className="form-control mb-2"
                                required
                            />
                            {searchName && (
                                <div className="form-group">
                                    <ul>
                                        {filteredCustomers.map((customer) => (
                                            <li
                                                key={customer._id}
                                                onClick={() => handleNameSelect(customer)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {`${customer.name} - ${customer.alterMobile} - ${customer.mobile} - ${customer.customerCode}`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mt-4">
                    <button
                        className="btn btn-primary me-2"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginBottom: "4px" }}
                        >
                            &times;
                        </button>
                    )}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title">List of Customer Ageing Report</h4>
                                </div>
                                <div>
                                    <button onClick={downloadExcel} className="btn btn-success mb-3">
                                        Download Excel
                                    </button>
                                </div>
                            </div>
                            <Paper sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    sx={{ border: 0 }}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CustomerAgeing;
