import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import api from '../Api';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Loader from './Loader';

const CreateVendor = ({ adminId }) => {
    const [rows, setRows] = useState([]);
    const [searchTableData, setSearchTableData] = useState('');
    const [vendorName, setVendorName] = useState("");
    const [selectedStaffName, setSelectedStaffName] = useState("");
    const [getVendorList, setGetVendorList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editingVendor, setEditingVendor] = useState(null);
    const [deleteVendor, setDeleteVendor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ visible: false, message: "", type: "" });
    const [getUser, setGetUser] = useState([]);
    const [getAdminRole, setGetAdminRole] = useState([]);
    const [userRole, setUserRole] = useState(null);


    const fetchUser = async () => {
        try {
            const response = await api.get("/user");
            setGetUser(response.data);
        } catch (error) {
            console.error("Error fetching User:", error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        const fetchAdminRole = async () => {
            try {
                const response = await api.get("/admin");
                setGetAdminRole(response.data);
            } catch (error) {
                console.error("Error fetching admin roles:", error);
            }
        };

        fetchAdminRole();
    }, []);

    useEffect(() => {
        const getUserRole = (adminId) => {
            const user = getUser.find((user) => user._id === adminId);
            if (user) {
                const loginuserRole = getAdminRole.find((role) => role._id === user.adminRole._id);
                setUserRole(loginuserRole);
                return loginuserRole?.roleName || null;
            } else {
                return null;
            }
        };
        const role = getUserRole(adminId);
    }, [adminId, getUser, getAdminRole]);



    const handleAddVendor = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Check if the lead source already exists
        const isDuplicate = getVendorList.some(source => source.vendorName === vendorName && (!editingVendor || source._id !== editingVendor._id));

        if (isDuplicate) {
            setIsLoading(false);
            setShowAlert({ visible: true, message: "Vendor already exists.", type: "danger" });
            return;
        }

        try {
            if (editingVendor) {
                
                const response = await api.put(`/vendor/${editingVendor.id}`, {
                    vendorName: vendorName,
                    staffName: selectedStaffName,
                });
                setEditingVendor(null);
            } else {
                const response = await api.post("/vendor", {
                    vendorName: vendorName,
                    staffName: selectedStaffName,
                });
            }
            fetchVendor();
            setVendorName("");
            setSelectedStaffName("");
            setShowForm(false);
            setIsLoading(false);
            setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
        } catch (error) {
            console.error("Error adding/updating vendor:", error);
            setShowAlert({ visible: true, message: "Error adding/updating vendor.", type: "error" });
        }
    };


    const handleDeleteVendor = async () => {
        if (!window.confirm("Are you sure you want to delete this entry?")) {
            return;
        }

        try {
            setIsLoading(true);
            const response = await api.delete(`/vendor/${deleteVendor.id}`);
            if (response.status === 200) {
                fetchVendor();
                setShowForm(false);
                setIsLoading(false);
                setShowAlert({ visible: true, message: "Submitted successfully", type: "success" });
            } else {
                throw new Error("Failed to delete the item. Please try again later.");
            }
        } catch (error) {
            console.error("Error deleting item:", error);
            setShowAlert({ visible: true, message: "Error deleting item.", type: "error" });
        } finally {
            setIsLoading(false);
        }
    };


    const fetchVendor = async () => {
        try {
            const response = await api.get("/vendor");
            setGetVendorList(response.data);
        } catch (error) {
            console.error("Error fetching lead sources:", error);
        }
    };

    const toggleForm = () => {
        setShowForm(!showForm);
        setEditingVendor(null);
        setVendorName("");
        setSelectedStaffName("");
    };

    const handleEditingVendor = (vendor) => {
        setIsLoading(false);
        setShowAlert({ visible: false, message: '', type: '' });
        setEditingVendor(vendor);
        setDeleteVendor(vendor);
        setVendorName(vendor.vendorName);
        setSelectedStaffName(vendor.staffName);
        setShowForm(true);
    };

    useEffect(() => {
        fetchVendor();
    }, []);

    useEffect(() => {
        if (showAlert.visible) {
            const timer = setTimeout(() => {
                setShowAlert(prev => ({ ...prev, visible: false }));
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showAlert.visible])



    const columns = [
        { field: 'number', headerName: 'Number', width: 100 },
        { field: 'vendorName', headerName: 'Vendor Name', width: 350 },
        { field: 'staffName', headerName: 'StaffName', width: 250 },
      ];
    
    
      useEffect(() => {
        if (getVendorList.length > 0) {

          const transformedRows = getVendorList.map((vendor, index) => ({
            number: index + 1,
            id: vendor._id,
            vendorName: vendor.vendorName,
            staffName: vendor.staffName,
          }));
    
          console.log(transformedRows);
          setRows(transformedRows);
        }
      }, [getVendorList]);
    
    
    
      const matchesSearchTerm = (row) => {
        const item = row || {};
    
        const vendorName = item.vendorName.toLowerCase() || '';
        const staffName = item.staffName.toLowerCase() || '';

    
        return (
            vendorName.includes(searchTableData.toLowerCase()) ||
            staffName.includes(searchTableData.toLowerCase())
        );
    
      };
    
      const filteredTableRows = searchTableData
        ? rows.filter(matchesSearchTerm)
        : rows;

        

    const handleTableSearchChange = (event) => {
        setSearchTableData(event.target.value);
    };

    return (
        <React.Fragment>
            <div className="container">
                <div className="page-header">
                    <h3 className="page-title">
                        <span className="page-title-icon bg-gradient-primary text-white me-2">
                            <i className="fa fa-bullseye"></i>
                        </span>{" "}
                        Vendor Module
                    </h3>
                    <nav aria-label="breadcrumb">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">
                                <span></span>Overview{" "}
                                <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="col-12">
                    {userRole && userRole.pageList.find(page => page.pageName === "Create Vendor").access[1] === 1 && (
                        <button
                            onClick={() => toggleForm()}
                            className="btn btn-gradient-primary mb-2"
                            type="button"
                        >
                            Create Vendor
                        </button>
                    )}
                </div>
                <div className="row justify-content-end mx-1">
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        value={searchTableData}
                        onChange={handleTableSearchChange}
                        className="col-12 col-lg-6"
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </div>
                <div
                    className={`offcanvas offcanvas-end w-50 ${showForm ? "show" : ""}`}
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                            {editingVendor ? "Edit Vendor" : "Create Vendor"}
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            onClick={toggleForm}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <form
                            onSubmit={handleAddVendor}
                            style={{
                                display: showForm || (editingVendor ? "block" : "none"),
                            }}
                        >
                            <div className='row'>
                                <div className="col-md-4">
                                    <label htmlFor="vendor" className="form-label">
                                        Vendor Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="vendor"
                                        value={vendorName}
                                        onChange={(e) => setVendorName(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="leadSource" className="form-label">
                                        Staff Name
                                    </label>
                                    <select
                                        className="form-control"
                                        id="leadSource"
                                        value={selectedStaffName}
                                        onChange={(e) => setSelectedStaffName(e.target.value)}
                                    >
                                        <option value="">Select staff</option>
                                        {getUser.map((user) => (
                                            <option key={user._id} value={user.name}>
                                                {user.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <Loader isLoading={isLoading} />
                            <div className="row text-start">
                                <div className="col-6 col-md-3 text-start">
                                    {userRole && userRole.pageList.find(page => page.pageName === "Create Vendor").access[2] === 1 && (
                                        <button type="submit" className="btn btn-sm btn-primary">
                                            {editingVendor ? "Update Vendor" : "Add Vendor"}
                                        </button>
                                    )}
                                </div>
                                <div className="col-6 col-md-3 align-items-center">
                                    {userRole?.pageList.some(page => page.pageName === "Create Vendor" && page.access[3] === 1) && editingVendor && (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={handleDeleteVendor}
                                        >
                                            Delete
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {showAlert.visible && (
                    <div
                        className={`alert alert-${showAlert.type} alert-dismissible fade show`}
                        role="alert"
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            zIndex: 1050
                        }}
                    >
                        <strong>{showAlert.message}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setShowAlert({ ...showAlert, visible: false })}
                        ></button>
                    </div>
                )}


                {/* DataGrid */}
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body table-responsive">
                                <h4 className="card-title">List of Vendor</h4>
                                <Paper sx={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={filteredTableRows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 5 },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10]}
                                        onRowDoubleClick={(row) => handleEditingVendor(row.row)}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CreateVendor;
