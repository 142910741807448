import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import GoogleMapsIcon from "../assets/images/map-icon.png";
import api from "../Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "../MediaQuery.css";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import StarRatings from "react-star-ratings";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Paper from "@mui/material/Paper";
import QuotationGenerator from "./QuotationGenerator";
import { DataGrid } from "@mui/x-data-grid";

const Marker = ({ lat, lng }) => (
  <img
    src={GoogleMapsIcon}
    alt="Google Maps Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const QuotationApproval = ({ adminId, adminName }) => {
  const [searchTableData, setSearchTableData] = useState('');
  const [getTabValue, setTabValue] = useState("1");
  const [getCustomerCreation, setGetCustomerCreation] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [getLeadSource, setGetLeadSource] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [searchReference, setSearchReference] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedRefId, setSelectedRefId] = useState(null);
  const [showRefButton, setShowRefButton] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [getServices, setGetServices] = useState([]);
  const [searchService, setSearchService] = useState("");
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedServiceList, setSelectedServiceList] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState([]);
  const [selectedAdditionalName, setSelectedAdditionalName] = useState([]);
  const [searchAdditionalCustomer, setSearchAdditionalCustomer] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [getPincode, setGetPincode] = useState([]);
  const [getProductType, setGetProductType] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedProductType, setSelectedproductType] = useState([]);
  const [getUnit, setGetUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [cameraStream, setCameraStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showLiveForm, setShowLiveForm] = useState(false);
  const [getLiveMarketing, setGetLiveMarketing] = useState([]);
  const [editingLiveMarketing, setEditingLiveMarketing] = useState(null);
  const [getBuildingStructure, setGetBuildingStructure] = useState([]);
  const [selectedBuildStructure, setSelectedBuildStructure] = useState(null);
  const [buildingRating, setBuildingRating] = useState(0);
  const starRatingRef = useRef(null);
  const [customerRating, setCustomerRating] = useState(0);
  const [projectValue, setProjectValue] = useState("");
  const [getImage, setGetImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [projectDuration, setProjectDuration] = useState("");
  const [isProjectChecked, setIsProjectChecked] = useState("");
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isEscalateChecked, setIsEscalateChecked] = useState(
    Array(additionalFields.length).fill(false));
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(true);
  const [selectedEscalationUser, setSelectedEscalationUser] = useState("");
  const [getUser, setGetUser] = useState([]);
  const [getTeams, setGetTeams] = useState([]);
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [getLeadStatus, setGetLeadStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [rows, setGridRows] = useState([]);
  const [selectedAddCustomerType, setSelectedAddCustomerType] = useState([]);
  const [givenPrice, setGivenPrice] = useState("");
  const [expectedPrice, setExpectedPrice] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [getFollowUp, setGetFollowUp] = useState([]);
  const [approvalby, setApprovalBy] = useState("");
  const [adminApproval, setAdminApproval] = useState("");
  // const [transferListedUser, setTransferListedUser] = useState("");
  const [selectedTransferUser, setSelectedTransferUser] = useState("");
  const [selectedTransferUserId, setSelectedTransferUserId] = useState("");

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [searchClicked1, setSearchClicked1] = useState(false);
  const [filteredRows1, setFilteredRows1] = useState([]);

  const [fromDate2, setFromDate2] = useState("");
  const [toDate2, setToDate2] = useState("");
  const [searchClicked2, setSearchClicked2] = useState(false);
  const [filteredRows2, setFilteredRows2] = useState([]);

  const [fromDate3, setFromDate3] = useState("");
  const [toDate3, setToDate3] = useState("");
  const [searchClicked3, setSearchClicked3] = useState(false);
  const [filteredRows3, setFilteredRows3] = useState([]);

  const [isSampleChecked, setIsSampleChecked] = useState(
    Array(additionalFields.length).fill(false)
  );
  const [QModal, setQModal] = useState(false);

  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");
  const [getLeadOnline, setGetLeadOnline] = useState([]);
  const [leadTLApprovalBy, setLeadTLApprovalBy] = useState("");
  const [leadAdminApprovalBy, setLeadAdminApprovalBy] = useState("");
  const [getLastLead, setGetLastLead] = useState(null);
  const [quotationStatusId, setQuotationStatusId] = useState(null);
  const [quotationalHistories, setQuotationalHistories] = useState([]);
  const [getAddTabValue, setAddTabValue] = useState("1");
  const [tabAddIndex, setAddTabIndex] = useState(0);
  const [getImageFile, setImageFile] = useState();
  const [modalImage, setModalImage] = useState('');
  const [showQuotationHistory, setShowQuotationHistory] = useState(false);
  const [areas, setAreas] = useState([]);

  const handleChangeTab = (index) => {
    setTabIndex(index);
  };

  const handleAddTabChange = (event, newValue) => {
    setAddTabValue(newValue);
  };

  const handleAddChangeTab = (index) => {
    setAddTabIndex(index);
  };

  const handleImageClick = (imageSrc) => {
    //-console.log(imageSrc);
    setModalImage(imageSrc);

  };

  const toggleQuotationHistory = () => {
    setShowQuotationHistory(!showQuotationHistory);
  };

  const handleCreateQuotationalHistory = () => {

    //-console.log("Selected Product List:", selectedProductList);
    //-console.log("Selected Product Types:", selectedProductType);

    const selectedProductsForQuotation = selectedProductList.map((product, index) => {
      const selectedUnitObject = getUnit.find(unit => unit._id === selectedUnit[index]);

      // Log data for debugging
      //-console.log('Selected product:', product);
      //-console.log('Selected product type:', selectedProductType[index]);
      //-console.log('Selected unit object:', selectedUnitObject);
      //-console.log('Index:', index);
      //-console.log('Quantity:', quantity[index]);
      //-console.log('Given price:', givenPrice[index]);
      //-console.log('Expected price:', expectedPrice[index]);

      return {
        productTypeId: selectedProductType[index],
        productType: product,
        unit: selectedUnitObject ? selectedUnitObject.unit : '',
        quantity: quantity[index] || '',
        givenPrice: givenPrice[index] || '',
        expectedPrice: expectedPrice[index] || '',
      };
    });

    // Update state with the new quotational history
    setQuotationalHistories(prevHistories => [...prevHistories, selectedProductsForQuotation]);
    //-console.log("quotationalHistories", quotationalHistories)

    // Clear form fields
    toggleQuotationHistory();
    setSelectedProductList([]);
    setSelectedproductType([]);
    setSelectedUnit([]);
    setQuantity([]);
    setGivenPrice([]);
    setExpectedPrice([]);
    setSearchProduct('');
  };

  useEffect(() => {
    //-console.log('modalImage:', modalImage);
  }, [modalImage]);

  useEffect(() => {
    const fetchLeadOnline = async () => {
      try {
        const response = await api.get("/leadCreationData");
        setGetLeadOnline(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchLeadOnline();
  }, []);

  useEffect(() => {
    const fetchLastLead = async () => {
      try {
        const response = await api.get("/findLastLead");
        setGetLastLead(response.data.assignedTo);
        //-console.log(response.data.assignedTo);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };
    fetchLastLead();
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await api.get("/team");
        setGetTeams(response.data);
      } catch (error) {
        //-console.log("Error fetching Teams:", error);
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/user");
        setGetUser(response.data);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  // live

  // Function to find the teamName of adminId
  const findUserTeamName = (adminId) => {
    const foundUser = getUser.find(user => user._id === adminId);
    if (foundUser) {
      const foundTeam = getTeams.find(team => team._id === foundUser.team);
      return foundTeam ? foundTeam.teamName : null;
    }
    return null;
  };

  // Function to find users by team TL
  const findUsersByTeamAndRole = (users, teams, adminRoles, teamName, roleName) => {
    return users.filter(user => {
      const userTeam = teams.find(team => team._id === user.team);
      return userTeam && userTeam.teamName === teamName;
    }).filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id);
      return userRole && userRole.roleName === roleName;
    });
  };

  const findUsersByAdmin = (users, adminRoles, roleName) => {
    return users.filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id && role.roleName === roleName);
      return !!userRole;
    });
  };


  // Main component logic
  useEffect(() => {
    const teamName = findUserTeamName(adminId);

    const usersInTeamTL = findUsersByTeamAndRole(getUser, getTeams, getAdminRole, teamName, 'TL');
    const usersInTeamAdmin = findUsersByAdmin(getUser, getAdminRole, 'Admin');
    //-console.log("admin", usersInTeamAdmin);

    const LiveTL = usersInTeamTL.length > 0 ? usersInTeamTL[0]._id : null;
    const LiveAdmin = usersInTeamAdmin.length > 0 ? usersInTeamAdmin[0]._id : null;

    if (usersInTeamTL || usersInTeamTL) {
      setApprovalBy(LiveTL);
      setAdminApproval(LiveAdmin);
    } else {
      setApprovalBy(null);
      setAdminApproval(null);
    }
    //-console.log("Live TL:", LiveTL);
    //-console.log("Live Admin:", LiveAdmin);


  }, [adminId, getTeams, getUser, getAdminRole, findUserTeamName, setApprovalBy, setAdminApproval]);


  // Lead quotational ---------------------------------------
  const findLeadUserTeamName = (adminId) => {
    const foundUser = getUser.find(user => user._id === adminId);
    if (foundUser) {
      const foundTeam = getTeams.find(team => team._id === foundUser.team);
      return foundTeam ? foundTeam.teamName : null;
    }
    return null;
  };

  // Function to find users by team TL
  const findLeadUsersByTeamAndRole = (users, teams, adminRoles, teamName, roleName) => {
    return users.filter(user => {
      const userTeam = teams.find(team => team._id === user.team);
      return userTeam && userTeam.teamName === teamName;
    }).filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id);
      return userRole && userRole.roleName === roleName;
    });
  };

  const findLeadUsersByAdmin = (users, adminRoles, roleName) => {
    return users.filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id && role.roleName === roleName);
      return !!userRole;
    });
  };

  useEffect(() => {
    const teamName = findLeadUserTeamName(adminId);

    const usersLeadInTeamTL = findLeadUsersByTeamAndRole(getUser, getTeams, getAdminRole, teamName, 'TL');
    const usersLeadInTeamAdmin = findLeadUsersByAdmin(getUser, getAdminRole, 'Admin');

    //-console.log("TL:", usersLeadInTeamTL);
    //-console.log("Admin:", usersLeadInTeamAdmin);

    const LeadTL = usersLeadInTeamTL.length > 0 ? usersLeadInTeamTL[0]._id : null;
    const LeadAdmin = usersLeadInTeamAdmin.length > 0 ? usersLeadInTeamAdmin[0]._id : null;

    // Setting values based on conditions
    if (LeadTL || LeadAdmin) {
      setLeadTLApprovalBy(LeadTL);
      setLeadAdminApprovalBy(LeadAdmin);
    } else {
      setLeadTLApprovalBy(null);
      setLeadAdminApprovalBy(null);
    }

    // Debugging logs
    //-console.log("Lead TL:", LeadTL);
    //-console.log("Lead Admin:", LeadAdmin);

  }, [adminId, getTeams, getUser, getAdminRole, findLeadUserTeamName, setLeadTLApprovalBy, setLeadAdminApprovalBy]);


  //-console.log("leadAdmin", leadAdminApprovalBy);



  // Get TL ADMIN MANAGER USER  
  const foundUserByRole = (users, adminRole, roleName) => {
    return users.filter(user => {
      const userRole = adminRole.find(role => role._id === user.adminRole._id);
      return userRole && userRole.roleName === roleName;
    })
  }

  useEffect(() => {
    const transferUserId = getUser.find(user => user.name === selectedTransferUser)?._id || "";
    setSelectedTransferUserId(transferUserId);
  }, [selectedTransferUser, getUser]);



  useEffect(() => {
    const fetchImageAtLive = async () => {
      try {
        const response = await api.get("/liveMarketing");
        setGetImage(response.data.image);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchImageAtLive();
  }, []);

  const fetchFollowUp = async () => {
    try {
      const response = await api.get("/findFollowUpDataLive");
      setGetFollowUp(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    fetchFollowUp();
  }, []);

  useEffect(() => { }, [getFollowUp]);

  const fetchPincodeData = async (enteredPincode) => {
    if (/^\d{6}$/.test(enteredPincode)) {
      try {
        const response = await api.get(`/pincode/${enteredPincode}`);
        const { city, state, areas } = response.data;

        setCity(city);
        setState(state);
        setAreas(areas);
      } catch (error) {
        console.error('Error fetching pincode data:', error);
        setCity('');
        setState('');
        setAreas([]);
      }
    } else {
      setCity('');
      setState('');
      setAreas([]);
    }
  };


  const handlePincodeChange = (e) => {
    const enteredPincode = e.target.value;
    setPincode(enteredPincode);
  };

  const handlePincodeBlur = () => {
    fetchPincodeData(pincode);
  };

  useEffect(() => {
    const fetchLeadStatus = async () => {
      try {
        const response = await api.get("/leadStatus");
        const leadStatuses = response.data;

        // Find the lead status object with leadStatus 'quotation'
        const quotationStatus = leadStatuses.find(status => status.leadStatus === 'quotation');

        if (quotationStatus) {
          // If found, you can access its _id
          const quotationStatusId = quotationStatus._id;
          setQuotationStatusId(quotationStatusId);
          //-console.log(quotationStatusId);
        } else {
          //-console.log("Lead status 'quotation' not found.");
        }
        setGetLeadStatus(leadStatuses);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchLeadStatus();
  }, []);

  const mapCenter = latitude || { lat: 11, lng: 77 };
  const mapZoom = 15;

  const toggleLiveForm = () => {
    setShowLiveForm(!showLiveForm);
    clearForm();
    setEditingLiveMarketing(null);
  };

  const getCityAndState = (pincode) => {
    const pincodeEntry = getPincode.find((entry) => entry.pincode === pincode);

    if (pincodeEntry) {
      return {
        city: pincodeEntry.city.city,
        state: pincodeEntry.state.state,
      };
    } else {
      return { city: "Unknown", state: "Unknown" };
    }
  };

  const getCurrentLocation = (e) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          //-console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          //-console.error("Error getting current location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const fetchProductType = async () => {
      try {
        const response = await api.get("/productTypes");
        setGetProductType(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchProductType();
  }, []);

  useEffect(() => {
    const fetchBuildingStructre = async () => {
      try {
        const response = await api.get("/buildingStructure");
        setGetBuildingStructure(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchBuildingStructre();
  }, []);

  const fetchLiveMarketing = async () => {
    try {
      const response = await api.get("/liveMarketing");
      setGetLiveMarketing(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    fetchLiveMarketing();
  }, []);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await api.get("/unit");
        setGetUnit(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchUnits();
  }, []);

  useEffect(() => {
    const fetchCustomerCreation = async () => {
      try {
        const response = await api.get("/customerCreation");
        setGetCustomerCreation(response.data);
      } catch (error) {
        //-console.log("Error fetching customer creation:", error);
      }
    };
    const fetchLeadSources = async () => {
      try {
        const response = await api.get("/leadSource");
        setGetLeadSource(response.data);
      } catch (error) {
        //-console.error("Error fetching lead sources:", error);
      }
    };
    const fetchServices = async () => {
      try {
        const response = await api.get("/services");
        setGetServices(response.data);
      } catch (error) {
        //-console.error("Error fetching services:", error);
      }
    };

    fetchCustomerCreation();
    fetchLeadSources();
    fetchServices();
  }, []);

  const filteredCustomers = getCustomerCreation.filter((customer) =>
    customer.name
      ? customer.name.toLowerCase().includes(searchName.toLowerCase())
      : "" || customer.alterMobile
        ? customer.alterMobile.includes(searchName)
        : "" || customer.mobile
          ? customer.mobile.includes(searchName)
          : ""
  );

  const handleNameSelect = (customer) => {
    setSearchName(
      `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
    );
    setSelectedCustomerId(customer._id);
  };

  const filteredRefCustomers = getCustomerCreation.filter((reference) =>
    reference.name
      ? reference.name.toLowerCase().includes(searchName.toLowerCase())
      : "" || reference.alterMobile
        ? reference.alterMobile.includes(searchName)
        : "" || reference.mobile
          ? reference.mobile.includes(searchName)
          : ""
  );

  const handleRefSelect = (customer) => {
    setSearchReference(
      `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
    );
    setSelectedRefId(customer._id);
  };

  const clearName = (searchName) => {
    if (searchName) {
      setSearchName("");
    }
  };
  const clearRef = (searchReference) => {
    if (searchReference) {
      setSearchReference("");
    }
  };

  const filteredCustomerServices = getServices.filter(
    (service) =>
      (service.customerCategory && service.customerCategory.customerCategory
        .toLowerCase()
        .includes(searchService.toLowerCase())) ||
      (service.workNature && service.workNature.workNature
        .toLowerCase()
        .includes(searchService.toLowerCase())) ||
      (service.services && service.services.toLowerCase().includes(searchService.toLowerCase()))
  );


  const handleCustomerServicesClick = (serviceId) => {
    const service = getServices.find(
      (customerService) => customerService._id === serviceId
    );
    const combinedService = `${service.customerCategory.customerCategory} - ${service.workNature.workNature} - ${service.services}`;

    setSelectedServiceList((prevList) => {
      const updatedList = [...prevList, combinedService];
      //-console.log("Selected Service List:", updatedList);
      return [...prevList, combinedService];
    });

    setSelectedServiceType((prevTypes) => {
      const updatedTypes = [...prevTypes, service._id];
      //-console.log("Selected Service Types:", updatedTypes);
      return [...prevTypes, service._id];
    });

    setSearchService("");
    addDropdown();
  };

  useEffect(() => { }, [selectedServiceList]);

  const addDropdown = () => {
    setDropdowns([...dropdowns, { id: dropdowns.length }]);
  };

  const removeDropdown = (indexToRemove) => {
    setDropdowns(dropdowns.filter((_, index) => index !== indexToRemove));
    const updatedSelectedService = selectedServiceList.filter(
      (_, index) => index !== indexToRemove
    );
    // setSelectedServiceList(updatedSelectedService);
    // const updatedSelectedAdditionalName = selectedAdditionalName.filter(
    //   (_, index) => index !== indexToRemove
    // );
    // setSelectedAdditionalName(updatedSelectedAdditionalName);
  };

  const filteredAddCustomers = getCustomerCreation.filter((customer) => {
    if (typeof searchAdditionalCustomer === "string") {
      return (
        customer.name
          .toLowerCase()
          .includes(searchAdditionalCustomer.toLowerCase()) ||
        customer.alterMobile.includes(searchAdditionalCustomer) ||
        customer.mobile.includes(searchAdditionalCustomer)
      );
    } else {
      return false;
    }
  });

  const handleAdditionalNameSelect = (customerId) => {
    const addCustomer = getCustomerCreation.find(
      (customer) => customer._id === customerId
    );

    setSelectedAdditionalName((prevList) => {
      const updatedList = [...prevList, addCustomer];
      return [...prevList, addCustomer];
    });

    setSelectedAddCustomerType((prevTypes) => {
      const updatedTypes = [...prevTypes, addCustomer._id];
      return [...prevTypes, addCustomer._id];
    });

    setSearchAdditionalCustomer("");
  };

  const filteredProductType = getProductType.filter(
    (product) =>
      (product.ProductCategory?.productCategory ?? ""
        .toLowerCase())
        .includes(searchProduct.toLowerCase()) ||
      (product.product?.product ?? ""
        .toLowerCase())
        .includes(searchProduct.toLowerCase()) ||
      (product.productTypes?.productTypes ?? ""
        .toLowerCase())
        .includes(searchProduct.toLowerCase()) ||
      (product.brand?.brand ?? "".toLowerCase().includes(searchProduct.toLowerCase()))
  );

  const handleProductSelect = (selectedProduct) => {
    // Find the product details based on the selected product ID
    const productDetail = getProductType.find(
      (producttype) => producttype._id === selectedProduct
    );

    // Construct a string representation of the selected product (optional)
    const selectedProductString = `${productDetail.ProductCategory.productCategory} - ${productDetail.product.product} - ${productDetail.productTypes} - ${productDetail.brand.brand}`;

    setSelectedProductList((prevList) => {
      return [...prevList, selectedProductString];
    });

    setSelectedproductType((prevTypes) => {
      const updatedTypes = [...prevTypes, productDetail._id];
      return [...prevTypes, productDetail._id];
    });

    setSearchProduct("");
  };

  useEffect(() => { }, [selectedProductList]);

  const startCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      let constraints = { video: {} };
      if (videoDevices.length > 0) {
        constraints.video.deviceId = {
          exact: videoDevices[videoDevices.length - 1].deviceId,
        };
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      setCameraStream(stream);
      setIsCameraActive(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      //-console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
      setIsCameraActive(false);
    }
  };

  useEffect(() => {
    return () => stopCamera();
  }, []);

  const captureImage = async () => {
    closeModal();
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL("image/jpeg");
      setCapturedImage([...capturedImage, imageDataURL]);
      stopCamera();
    }
  };

  const toggleCamera = () => {
    const newFacingMode = isFrontCamera ? "environment" : "user";
    setIsFrontCamera(!isFrontCamera);
    stopCamera();
    startCamera(newFacingMode);
  };

  const removeCapturedImage = (indexToRemove) => {
    const updatedCapturedImage = capturedImage.filter(
      (_, index) => index !== indexToRemove
    );
    setCapturedImage(updatedCapturedImage);
  };

  // post
  const handleLiveMarketingSubmit = async (e) => {
    e.preventDefault();

    // Lead

    if (editingLiveMarketing.serviceList == null) {
      //-console.log("after", editingLiveMarketing);
      try {

        let quotationApproval = [];
        if (selectedStatus === quotationStatusId && selectedApprovalStatus === "waiting") {
          quotationApproval.push({
            approvalby: leadTLApprovalBy,
            submissionDate: "",
            approvalStatus: selectedApprovalStatus,
            approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        }

        if (selectedApprovalStatus === "approved") {
          quotationApproval = editingLiveMarketing.quotationApproval;
          quotationApproval[quotationApproval.length - 1].approvalStatus = "approved"
        }

        // re correction
        if (selectedApprovalStatus === "declined") {
          quotationApproval = editingLiveMarketing.quotationApproval;
          quotationApproval[quotationApproval.length - 1].approvalStatus = "declined"
          quotationApproval[quotationApproval.length - 1].approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");
        }


        if (selectedStatus === quotationStatusId && selectedApprovalStatus === "transfer") {
          quotationApproval = editingLiveMarketing.quotationApproval;
          quotationApproval[quotationApproval.length - 1].approvalStatus = "transfer";
          quotationApproval[quotationApproval.length - 1].approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");
          quotationApproval.push({
            approvalby: selectedTransferUser,
            submissionDate: "",
            approvalStatus: "waiting",
            approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        }



        if (isProjectChecked === 1 && selectedApprovalStatus === "approved") {
          let quotationApproval = editingLiveMarketing.quotationApproval;

          // Get all users with role "Admin"
          const TL = getUser.filter(user => user.adminRole.roleName === "TL");

          // Extract the IDs of admin users
          const TLIds = TL.map(tl => tl._id);

          // Check if the latest approval record's approvalby is an admin user ID
          const latestApproval = quotationApproval[quotationApproval.length - 1];
          if (latestApproval && TLIds.includes(latestApproval.approvalby)) {
            // Update the latest approval record
            latestApproval.approvalStatus = "forAdminApproval";
            latestApproval.approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");

            // Add a new approval record
            quotationApproval.push({
              approvalby: leadAdminApprovalBy,
              submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              approvalStatus: "waiting",
              approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            });

          }
        }


        console.log("AfterquotationApproval", quotationApproval)

        const updatedData = {
          quotationApproval: quotationApproval,
          project: isProjectChecked ? 1 : 0,
          status: selectedStatus,
        };

        //-console.log("updatedLeadData", updatedData);

        const response = await api.put(`/leadTL/${editingLiveMarketing._id}`, updatedData);

        // Log success message and update state
        //-console.log("Updated Lead successfully:", response.data);
        setShowLiveForm(false);
        setEditingLiveMarketing(null);
        fetchLiveMarketing();
        fetchFollowUp();
      } catch (error) {
        // Log error if there is an exception
        //-console.error("Error submitting live marketing data:", error);
      }
    }
    else {
      try {
        //-console.log("after", editingLiveMarketing);
        let quotationApproval = [];
        if (selectedStatus === quotationStatusId && selectedApprovalStatus === "waiting") {
          quotationApproval.push({
            approvalby: approvalby,
            approvalStatus: selectedApprovalStatus,
            approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        }

        if (selectedApprovalStatus === "approved") {
          quotationApproval = editingLiveMarketing.quotationApproval;
          quotationApproval[quotationApproval.length - 1].approvalStatus = "approved"
          quotationApproval[quotationApproval.length - 1].approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");
        }

        // re correction
        if (selectedApprovalStatus === "declined") {
          quotationApproval = editingLiveMarketing.quotationApproval;
          quotationApproval[quotationApproval.length - 1].approvalStatus = "declined"
          quotationApproval[quotationApproval.length - 1].approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");
        }

        if (selectedStatus === quotationStatusId && selectedApprovalStatus === "transfer") {
          quotationApproval = editingLiveMarketing.quotationApproval;
          quotationApproval[quotationApproval.length - 1].approvalStatus = "transfer";
          quotationApproval[quotationApproval.length - 1].approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");
          quotationApproval.push({
            approvalby: selectedTransferUser,
            // submissionDate: "",
            approvalStatus: "waiting",
            approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
        }

        if (isProjectChecked === 1 && selectedApprovalStatus === "approved") {
          let quotationApproval = editingLiveMarketing.quotationApproval;

          // Get all users with role "Admin"
          const TL = getUser.filter(user => user.adminRole.roleName === "TL");

          // Extract the IDs of admin users
          const TLIds = TL.map(tl => tl._id);

          // Check if the latest approval record's approvalby is an admin user ID
          const latestApproval = quotationApproval[quotationApproval.length - 1];
          if (latestApproval && TLIds.includes(latestApproval.approvalby)) {
            // Update the latest approval record
            latestApproval.approvalStatus = "forAdminApproval";
            latestApproval.approvalDate = moment().format("YYYY-MM-DD HH:mm:ss");

            // Add a new approval record
            quotationApproval.push({
              approvalby: leadAdminApprovalBy,
              submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              approvalStatus: "waiting",
              approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            });

          }
        }

        console.log("AfterquotationApproval", quotationApproval)

        const updatedData = {
          quotationApproval: quotationApproval,
          project: isProjectChecked ? 1 : 0,
          status: selectedStatus,
        };

        //-console.log("updatedLiveData", updatedData);

        const response = await api.put(`/liveMarketingTL/${editingLiveMarketing._id}`, updatedData);

        // Log success message and update state
        //-console.log("Updated Live successfully:", response.data);
        setShowLiveForm(false);
        setEditingLiveMarketing(null);
        fetchLiveMarketing();
        fetchFollowUp();
      } catch (error) {
        //-console.error("Error submitting live marketing data:", error);
      }
    };
  }

  const clearForm = () => {
    setSearchName("");
    setSelectedLeadSource("");
    setSelectedProductList([]);
    setQuantity([]);
    setState("");
    setCity("");
    setPincode("");
    setAddress("");
    setSearchReference("");
    setAdditionalFields([]);
    setBuildingRating(0);
    setCustomerRating(0);
    setSelectedStatus("");
    setProjectDuration("");
    setProjectValue("");
    setShowClearButton(false);
    setShowRefButton(false);
    setSelectedServiceList([]);
  };

  const handleEditingLiveMarketing = (item) => {
    // Set basic fields
    //-console.log("item", item);

    setSelectedLeadSource(item.leadSource ? item.leadSource._id : "");
    const searchName = item.name
      ? `${item.name.name || ''} ${item.name.mobile || '-'} ${item.name.alterMobile || '-'}`
      : '';

    setSearchName(searchName);
    const searchReferenceName = item.leadReference
      ? `${item.leadReference?.name ?? ''} ${item.leadReference?.mobile ?? '-'} ${item.leadReference?.alterMobile ?? '-'}`
      : "";

    setSearchReference(searchReferenceName);
    setSelectedCustomerId(item.name ? item.name._id : "");
    setShowLiveForm(true);
    setEditingLiveMarketing(item);
    setSelectedImage(item.image);
    setSelectedStatus(item.status ? item.status._id : "N/A");
    setSelectedBuildStructure(item.buildingStructure || "");
    setBuildingRating(item.buildingRating || 0);
    setCustomerRating(item.customerRating || 0);
    setProjectValue(item.projectValue || "");
    setProjectDuration(item.projectDuration || "");
    setIsProjectChecked(item.project === 1 ? 1 : 0);
    setPincode(pincode.pincode || "");
    setSelectedAreaName(pincode.area || "");
    setCity(pincode.district ? pincode.district.district : "");
    setState(pincode.state ? pincode.state.state : "");
    setAddress(item.name ? item.name.address : "-");

    // Process and set quotationalHistories
    const history = item.quotationalHistories.map(innerArray =>
      Object.values(innerArray).map(product => ({
        productType: `${product.productType?.ProductCategory?.productCategory || ''} ${product.productType?.product?.product || ''} ${product.productType?.productTypes || ''} ${product.productType?.brand?.brand || ''}`,
        unit: product.unit.unit,
        quantity: product.quantity,
        givenPrice: product.givenPrice,
        expectedPrice: product.expectedPrice,
      }))
    );

    setQuotationalHistories(history);

    // Process and set selectedServices
    const selectedServices = item.serviceList
      ? item.serviceList.map(service => service.serviceType ? service.serviceType.services : "N/A")
      : [];
    setSelectedServiceList(selectedServices);

    // Process and set selectedSerType
    const selectedSerType = item.serviceList
      ? item.serviceList.map(service => service.serviceType ? service.serviceType._id : "N/A")
      : [];
    setSelectedServiceType(selectedSerType);

    // Process and set selectedNames
    const selectedNames = item.serviceList
      ? item.serviceList.map(service => ({
        name: service.customerType ? service.customerType.name : "",
        mobile: service.customerType ? service.customerType.mobile : "",
      }))
      : [];
    setSelectedAdditionalName(selectedNames);

    // Process and set selectedAddCustomerType
    const selectedAddCustomerType = item.serviceList
      ? item.serviceList.map(service => service.customerType ? service.customerType._id : "N/A")
      : [];
    setSelectedAddCustomerType(selectedAddCustomerType);

    // Process and set additionalFields
    const additionalFields = item.followup
      ? item.followup.map(followup => ({
        id: followup._id,
        nextFollowupDate: followup.nextFollowUpDate ? new Date(followup.nextFollowUpDate) : null,
        imageSource: followup.imageSource || "",
        remarks: followup.remark || "",
      }))
      : [];
    setAdditionalFields(additionalFields);

    // Set project checked status
    setIsProjectChecked(item.project === 1 ? 1 : 0);
  };


  const handleUpload = async (index) => {
    const formData = new FormData();
    formData.append("file", getImageFile);
    formData.append("customerid", adminId);
    formData.append("format", "image");
    const response = await api.post("/uploadAudio", formData);
    const updatedFields = [...additionalFields];
    updatedFields[index].imageSource = response.data;
    setAdditionalFields(updatedFields);
    //-console.log(additionalFields);
  };


  const generateQuotation = (editingLead, index) => {
    //-console.log("New Tab");

    const data = { leadData: editingLead, quote: index };
    localStorage.setItem("generateQuotation", JSON.stringify(data));
    const url = "/quotation";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleBuildingRatingChange = (newRating) => {
    setBuildingRating(newRating);
  };

  const handleRatingFocus = () => {
    if (starRatingRef.current) {
      starRatingRef.current.blur();
    }
  }
  const handleCustomerRatingChange = (newRating) => {
    setCustomerRating(newRating);
  };

  const addAdditionalField = () => {
    const newField = {
      id: uuidv4(),
      nextfollowUp: null,
      remarks: "",
      audio: "",
    };
    setAdditionalFields([...additionalFields, newField]);
  };
  // Function to remove an additional field by index
  const removeAdditionalField = (index) => {
    removeCapturedImage(index);
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  // Function to update remarks in additional fields
  const handleRemarksChange = (index, remarks) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].remarks = remarks;
    setAdditionalFields(updatedFields);
  };

  const handleEscalationChange = (index, value) => {
    const updatedEscalation = [...selectedEscalationUser];
    updatedEscalation[index] = value;
    setSelectedEscalationUser(updatedEscalation);
  };

  const handleDateChange = (date, index, fieldName) => {
    const updatedFields = [...additionalFields];

    if (fieldName === "nextFollowup") {
      updatedFields[index] = {
        ...updatedFields[index],
        nextFollowupDate: date,
      };
    }

    setAdditionalFields(updatedFields);
  };

  const handleEscaltionCheckbox = (index, checked) => {
    const updatedEscalationCheck = [...isEscalateChecked];
    updatedEscalationCheck[index] = checked;
    setIsEscalateChecked(updatedEscalationCheck);
    setIsDropdownDisabled(!checked);
  };

  const handleProjectCheckboxChange = (event) => {
    setIsProjectChecked(event.target.checked);
    if (event.target.checked) {

    }
  };

  const handleSampleCheckbox = (index, checked) => {
    const updatedSample = [...isSampleChecked];
    updatedSample[index] = checked;
    setIsSampleChecked(updatedSample);
  };

  const handleUnitChange = (index, value) => {
    const updatedUnits = [...selectedUnit];
    updatedUnits[index] = value;
    setSelectedUnit(updatedUnits);
  };

  // Example handler for updating quantity state
  const handleQuantityChange = (index, value) => {
    const updatedQuantities = [...quantity];
    updatedQuantities[index] = value;
    setQuantity(updatedQuantities);
  };

  const handleGivePrice = (index, value) => {
    const updatedGivePrice = [...givenPrice];
    updatedGivePrice[index] = value;
    setGivenPrice(updatedGivePrice);
  };

  const handleExpectedPrice = (index, value) => {
    const updatedExpectedPrice = [...expectedPrice];
    updatedExpectedPrice[index] = value;
    setExpectedPrice(updatedExpectedPrice);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    stopCamera();
  };
  // Quotation Modal
  const closeQModal = () => {
    setQModal(false);
  };

  const openQModal = () => {
    setQModal(true);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let temp_rows = [];
    getLiveMarketing.map((item, index) => {
      const quotationalHistories = [];
      item.quotationalHistories.map((product, index) => {
        quotationalHistories.push({
          productType: product.productType,
          unit: product.unit,
          quantity: product.quantity,
          givenPrice: product.givenPrice,
          expectedPrice: product.expectedPrice,
        });
      });
      temp_rows.push(
        createData(
          item.name ? item.name.name : "",
          item.leadSource?.leadSource ?? "",
          item.createdAt,
          getCityAndState(item.pincode).city,
          item.pincode,
          quotationalHistories,
          item
        )
      );
    });
    getLeadOnline.map((item, index) => {
      const quotationalHistories = [];
      item.quotationalHistories.map((product, index) => {
        quotationalHistories.push({
          productType: product.productType,
          unit: product.unit,
          quantity: product.quantity,
          givenPrice: product.givenPrice,
          expectedPrice: product.expectedPrice,
        });
      });
      temp_rows.push(
        createData(
          item.name ? item.name.name : "",
          item.leadSource?.leadSource ?? "",
          item.createdAt,
          getCityAndState(item.pincode).city,
          item.pincode,
          quotationalHistories,
          item
        )
      );
    });
    //-console.log(temp_rows);
    setGridRows(temp_rows);
  }, [getLiveMarketing, getLeadOnline]);

  const handleFromDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setFromDate1(e.target.value);
        break;
      case 1:
        setFromDate2(e.target.value);
        break;
      case 2:
        setFromDate3(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleToDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setToDate1(e.target.value);
        break;
      case 1:
        setToDate2(e.target.value);
        break;
      case 2:
        setToDate3(e.target.value);
        break;
      default:
        break;
    }
  };

  // Handle search click
  const handleSearchClick = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setSearchClicked1(true);
        break;
      case 1:
        setSearchClicked2(true);
        break;
      case 2:
        setSearchClicked3(true);
        break;
      default:
        break;
    }
  };

  // Handle clear filter
  const handleClearFilter = (tabIndex) => {
    const setDefaultFromDate = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      switch (tabIndex) {
        case 0:
          setFromDate1(currentDateFormatted);
          setSearchClicked1(false);
          setToDate1(currentDateFormatted);
          setFilteredRows1([]);
          break;
        case 1:
          setFromDate2(currentDateFormatted);
          setSearchClicked2(false);
          setToDate2(currentDateFormatted);
          setFilteredRows2([]);
          break;
        case 2:
          setFromDate3(currentDateFormatted);
          setSearchClicked3(false);
          setToDate3(currentDateFormatted);
          setFilteredRows3([]);
          break;
        default:
          break;
      }
    };

    setDefaultFromDate();
  };



  useEffect(() => {
    const filterRowsByDate = (tabIndex) => {
      let fromDate, toDate, setFilteredRows, statusFilter;
      switch (tabIndex) {
        case 0:
          fromDate = fromDate1;
          toDate = toDate1;
          setFilteredRows = setFilteredRows1;
          statusFilter = "waiting";
          break;
        case 1:
          fromDate = fromDate2;
          toDate = toDate2;
          setFilteredRows = setFilteredRows2;
          statusFilter = "approved";
          break;
        case 2:
          fromDate = fromDate3;
          toDate = toDate3;
          setFilteredRows = setFilteredRows3;
          statusFilter = "declined";
          break;
        default:
          break;
      }

      //-console.log(`fromDate: ${fromDate}, toDate: ${toDate}`);

      if (fromDate && toDate) {
        const filtered = rows.filter((row) => {
          //-console.log("row", row);
          const quotationApproval = row.item.quotationApproval || [];
          const lastIndex = quotationApproval.length - 1;
          const lastQuotationApproval = quotationApproval[lastIndex];
          const followup = row.item.followup;


          //-console.log("assignedTo",assignedTo);
          if ((followup && followup.length > 0) && (quotationApproval && quotationApproval.length > 0)) {
            const nextFollowUpDate = moment(followup[0]?.nextFollowUpDate);
            const isWithinDateRange = nextFollowUpDate.isBetween(moment(fromDate).startOf('day'), moment(toDate).endOf('day'), null, '[]');
            const isAdminIdMatching = quotationApproval.some(approval => approval.approvalby === adminId);
            return isWithinDateRange && (lastQuotationApproval.approvalStatus === statusFilter) && isAdminIdMatching;
          }
          return false;
        });
        setFilteredRows(filtered);
      } else {
        setFilteredRows([]);
      }
    };

    switch (tabIndex) {
      case 0:
        if (searchClicked1) {
          filterRowsByDate(0);
        }
        break;
      case 1:
        if (searchClicked2) {
          filterRowsByDate(1);
        }
        break;
      case 2:
        if (searchClicked3) {
          filterRowsByDate(2);
        }
        break;
      default:
        break;
    }
  }, [
    fromDate1,
    toDate1,
    searchClicked1,
    fromDate2,
    toDate2,
    searchClicked2,
    fromDate3,
    toDate3,
    searchClicked3,
    tabIndex,
    rows,
  ]
  );


  useEffect(() => {
    const setDefaultFromToDates = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      setFromDate1(currentDateFormatted);
      setFromDate2(currentDateFormatted);
      setFromDate3(currentDateFormatted);
      setToDate1(currentDateFormatted);
      setToDate2(currentDateFormatted);
      setToDate3(currentDateFormatted);
    };

    setDefaultFromToDates();
  }, []);


  const filteredEscalationUsers = getUser.filter(
    (user) => user.name !== adminName
  );

  const handleTableSearchChange = (event) => {
    setSearchTableData(event.target.value);
  };

  const transformRows = (rows) => {
    return rows.map(row => {
      // console.log("Transforming row", row); // Check each row being transformed

      const item = row.item || {};
      const pincode = item.pincode || {};

      return {
        id: item._id || '',
        cus_name: item.name?.name || '',
        lead_source: item.leadSource?.leadSource || '',
        lead_date: row.lead_date || '',
        pincode: pincode.pincode || '',
        area: pincode.district?.district || '',
        quotationApproval: item.quotationApproval || [],
        item: row.item
      };
    });
  };

  const matchesSearchTerm = (row) => {
    const item = row.item || {};
    const name = item.name?.name?.toLowerCase() || '';
    const leadSource = item.leadSource?.leadSource?.toLowerCase() || '';
    const pincodeData = item.pincode || {};
    const pincode = pincodeData.pincode?.toString() || '';
    const city = pincodeData.district?.district?.toLowerCase() || '';

    return (
      name.includes(searchTableData.toLowerCase()) ||
      leadSource.includes(searchTableData.toLowerCase()) ||
      pincode.includes(searchTableData.toLowerCase()) ||
      city.includes(searchTableData.toLowerCase())
    );
  };

  // Filter rows based on the search term


  const filteredWaitingRows = () => {
    const rowsToFilter =
      searchClicked1 ? filteredRows1
        : rows.filter(row => {
          const { status } = row.item;
          const { item } = row;

          if (item && item.quotationApproval) {
            const hasApproval = item.quotationApproval.some(
              (approval) =>
                approval.approvalby === adminId &&
                approval.approvalStatus === "waiting"
            );

            return hasApproval && status.leadStatus === "quotation";
          }
          return false;
        });
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;

    return transformRows(rowsFilteredBySearch);
  };

  const filteredApprovedRows = () => {
    const rowsToFilter =
      searchClicked2 ? filteredRows2
        : rows.filter(row => {
          const { status } = row.item;
          const { item } = row;

          if (item && item.quotationApproval) {
            const hasApproval = item.quotationApproval.some(
              (approval) =>
                approval.approvalby === adminId &&
                approval.approvalStatus === "approved"
            );

            return hasApproval && status.leadStatus === "quotation";
          }
          return false;
        });
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;

    return transformRows(rowsFilteredBySearch);
  };

  const filteredDeclinedRows = () => {
    const rowsToFilter =
      searchClicked3 ? filteredRows3
        : rows.filter(row => {
          const { status } = row.item;
          const { item } = row;

          if (item && item.quotationApproval) {
            const hasApproval = item.quotationApproval.some(
              (approval) =>
                approval.approvalby === adminId &&
                approval.approvalStatus === "declined"
            );

            return hasApproval && status.leadStatus === "quotation";
          }
          return false;
        });
    const rowsFilteredBySearch = searchTableData
      ? rowsToFilter.filter(matchesSearchTerm)
      : rowsToFilter;

    return transformRows(rowsFilteredBySearch);
  };



  const columns = [
    {
      field: 'Marker',
      headerName: 'Marker',
      width: 150,
      renderCell: (params) => {
        const { lead_source } = params.row;
        const hasLeadSource = !lead_source;

        return (
          <TableCell>
            {hasLeadSource ? <LocationOnIcon color="primary" /> : ""}
          </TableCell>
        );
      }
    },
    { field: 'cus_name', headerName: 'Customer Name', width: 300 },
    { field: 'lead_source', headerName: 'Lead Source', width: 150 },
    { field: 'lead_date', headerName: 'Lead Date', width: 200 },
    { field: 'pincode', headerName: 'Pincode', width: 150 },
    { field: 'area', headerName: 'Area', width: 200 },

    // Add additional columns as needed
  ];


  return (
    <React.Fragment>
      {/* camera model */}
      <div>
        {showModal && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Camera</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {/* Camera */}
                  <div className="camera-container">
                    {cameraStream && (
                      <>
                        <video
                          ref={videoRef}
                          autoPlay
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "10px 0 0 0",
                          }}
                          playsInline
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="modal-footer row justify-content-evenly">
                  <button
                    type="button"
                    className="btn btn-gradient-primary col-auto"
                    onClick={startCamera}
                  >
                    <i
                      class="fa fa-power-off"
                      style={{ "font-size": "20px" }}
                    ></i>
                  </button>

                  {/* Conditionally render Capture Image button */}
                  <button
                    className="btn btn-gradient-success col-auto"
                    onClick={captureImage}
                  >
                    <FontAwesomeIcon
                      icon={faCameraRetro}
                      style={{ height: "20px" }}
                    />
                  </button>

                  {/* switch camera */}
                  <button
                    type="button"
                    className="btn btn-info col-auto"
                    onClick={toggleCamera}
                  >
                    <FontAwesomeIcon
                      icon={faExchangeAlt}
                      style={{ height: "20px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showModal && <div className="modal-backdrop fade show"></div>}
      </div>
      {/* header */}
      <div className="container">
        <div class="page-header">
          <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
              <i class="fa fa-check-circle"></i>
            </span>{" "}
            Quotation Approval
          </h3>
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>
        {/* off canvas  */}
        <div
          className={`offcanvas offcanvas-end   ${showLiveForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleLiveForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="card">
              <div className="card-body">
                <form
                  className="form-inline"
                  onSubmit={handleLiveMarketingSubmit}
                  style={{
                    display:
                      showLiveForm || (editingLiveMarketing ? "block" : "none"),
                  }}
                >
                  <div className="row">
                    {/* Customer Name */}
                    <div className="col-md-4">
                      <div className="d-flex gap-2">
                        <div className="form-group w-100">
                          <label>Customer Name</label>
                          <input
                            type="text"
                            placeholder="Search a name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            className="form-control mb-2"
                            onClick={() => setShowClearButton(true)}
                            required
                            disabled
                          />
                          {searchName && (
                            <div className="form-group">
                              <ul>
                                {filteredCustomers.map((customer) => (
                                  <li
                                    key={customer._id}
                                    onClick={() => handleNameSelect(customer)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {`${customer.name} - ${customer.alterMobile} - ${customer.mobile}`}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          {showClearButton && (
                            <button
                              onClick={() => clearName(searchName)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              type="button"
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Lead Source */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Lead Source</label>
                        <select
                          className="form-select form-control"
                          value={selectedLeadSource}
                          onChange={(e) => setSelectedLeadSource(e.target.value)}
                          required
                          disabled
                        >
                          <option value="">Select Lead</option>
                          {getLeadSource.map((lead) => (
                            <option key={lead._id} value={lead._id}>
                              {lead.leadSource}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Lead Reference */}
                    <div className="col-md-4">
                      <div className="d-flex gap-2">
                        <div className="form-group w-100">
                          <label htmlFor="pincode">Lead Reference</label>
                          <input
                            type="text"
                            value={searchReference}
                            placeholder="Search a lead reference name"
                            onChange={(e) => setSearchReference(e.target.value)}
                            className="form-control mb-2"
                            onClick={() => setShowRefButton(true)}
                            disabled
                          />
                          {/* filter */}
                          {searchReference && (
                            <div className="form-group">
                              {filteredRefCustomers.map((customer) => (
                                <div
                                  key={customer._id}
                                  onClick={() => handleRefSelect(customer)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {customer.name} - {customer.alterMobile} -{" "}
                                  {customer.mobile}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        {/* btn */}
                        <div>
                          {showRefButton && (
                            <button
                              onClick={() => clearRef(searchReference)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                              type="button"
                              disabled
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Additional Customers Data */}
                  <div className="row">
                    {/* Customer Category */}
                    <div className="col-md-4">
                      <div className="form-group w-100">
                        <label>Customer Category</label>
                        <input
                          type="text"
                          placeholder="Search a Category"
                          value={searchService}
                          onChange={(e) => setSearchService(e.target.value)}
                          className="form-control mb-2"
                          onClick={() => setShowClearButton(true)}
                          disabled
                        />
                        {searchService && (
                          <select
                            className="js-example-basic-multiple"
                            multiple
                            style={{ width: "100%" }}
                          >
                            {filteredCustomerServices.map((service) => (
                              <option
                                key={service._id}
                                value={service._id}
                                onClick={() =>
                                  handleCustomerServicesClick(service._id)
                                }
                              >
                                {`${service.customerCategory.customerCategory} - ${service.workNature.workNature} - ${service.services}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>

                    {/* Search Additional Customer Name */}
                    <div className="col-md-4">
                      <div className="form-group w-100">
                        <label>Customer Name</label>
                        <input
                          type="text"
                          placeholder="Search a name"
                          value={searchAdditionalCustomer}
                          onChange={(e) =>
                            setSearchAdditionalCustomer(e.target.value)
                          }
                          className="form-control mb-2"
                          onClick={() => setShowClearButton(true)}
                          disabled
                        />
                        {searchAdditionalCustomer && (
                          <select
                            className="js-example-basic-multiple"
                            multiple
                            style={{ width: "100%" }}
                          >
                            {filteredAddCustomers.map((addCustomer) => (
                              <option
                                key={addCustomer._id}
                                value={addCustomer._id}
                                onClick={() =>
                                  handleAdditionalNameSelect(addCustomer._id)
                                }
                              >
                                {`${addCustomer.name} - ${addCustomer.alterMobile} - ${addCustomer.mobile}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Dynamic Dropdowns */}
                  <div>
                    {selectedServiceType.map((service, index) => (
                      <div key={service.id}>
                        <div className="d-flex gap-3">
                          {/* Selected Service */}
                          <div className="form-group col-md-4">
                            <label htmlFor={`selectedService-${index}`}>
                              Service
                            </label>
                            <input
                              type="text"
                              id={`selectedService-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedServiceList[index]}
                              disabled
                            />
                          </div>
                          {/* Selected Additional Customer Name */}
                          <div className="form-group col-md-3">
                            <label htmlFor={`selectedAdditionalName-${index}`}>
                              Name
                            </label>
                            <input
                              type="text"
                              id={`selectedAdditionalName-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedAdditionalName[index]?.name || ""}
                              disabled
                            />
                          </div>
                          {/* Corresponding Mobile Number */}
                          <div className="form-group col-md-3">
                            <label htmlFor={`correspondingMobile-${index}`}>
                              Mobile
                            </label>
                            <input
                              type="text"
                              id={`correspondingMobile-${index}`}
                              readOnly
                              className="form-control"
                              value={selectedAdditionalName[index]?.mobile || ""}
                              disabled
                            />
                          </div>
                          {/* Remove Button */}
                          <div>
                            <button
                              onClick={() => removeDropdown(index)}
                              className="btn btn-sm badge-gradient-danger"
                              aria-label="Close"
                              style={{ marginTop: "30px" }}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Products */}
                  <div className="col-md-4">
                    <div className="form-group w-100">
                      <label>Search Product</label>
                      <input
                        type="text"
                        placeholder="Search by product"
                        value={searchProduct}
                        onChange={(e) => setSearchProduct(e.target.value)}
                        className="form-control"
                        disabled
                      />
                      {searchProduct && (
                        <select
                          className="js-example-basic-multiple"
                          multiple
                          style={{ width: "100%" }}
                        >
                          {filteredProductType.map((product) => (
                            <option
                              key={product._id}
                              value={product._id}
                              onClick={() => handleProductSelect(product._id)}
                            >
                              {product.ProductCategory.productCategory} -{" "}
                              {product.product.product} - {product.productTypes} -{" "}
                              {product.brand.brand}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="">
                    {selectedProductList.length > 0 && (
                      <div className="">
                        {selectedProductList.map((product, index) => (
                          <div key={index} className="row">
                            <div className="form-group col-md-3">
                              <label>Product Category</label>
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                value={product}
                                disabled
                              />
                            </div>
                            <div className="col-md-2">
                              <label htmlFor="workNature">Unit</label>
                              <select
                                className="form-select"
                                value={selectedUnit[index]}
                                onChange={(e) =>
                                  handleUnitChange(index, e.target.value)
                                }
                                disabled
                              >
                                <option value="">selected a unit</option>
                                {getUnit.map((unit) => (
                                  <option key={unit._id} value={unit._id}>
                                    {unit.unit}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group col-md-2">
                              <label>Quantity</label>
                              <input
                                type="text"
                                className="form-control"
                                value={quantity[index]}
                                onChange={(e) =>
                                  handleQuantityChange(index, e.target.value)
                                }
                                disabled
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label>Given Price</label>
                              <input
                                type="number"
                                className="form-control"
                                value={givenPrice[index]}
                                onChange={(e) =>
                                  handleGivePrice(index, e.target.value)
                                }
                                disabled
                              />
                            </div>
                            {/* expected price */}
                            <div className="form-group col-md-2">
                              <label>Asked price</label>
                              <input
                                type="number"
                                className="form-control"
                                value={expectedPrice[index]}
                                onChange={(e) =>
                                  handleExpectedPrice(index, e.target.value)
                                }
                                disabled
                              />
                            </div>

                            <div className="form-group col-md-1">
                              <button
                                onClick={() => removeDropdown(index)}
                                className="btn  btn-sm badge-gradient-danger"
                                aria-label="Close"
                                style={{ marginTop: "30px" }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="row">
                    {/* Building Structure */}
                    <div className="col-md-4 mb-2">
                      <div className="form-group">
                        <label>Building Structure</label>
                        <select
                          className="form-select form-control"
                          value={selectedBuildStructure}
                          onChange={(e) =>
                            setSelectedBuildStructure(e.target.value)
                          }
                          disabled
                        >
                          {getBuildingStructure.map((build) => (
                            <option key={build._id} value={build._id}>
                              {build.buildingStructure}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Building Rating */}
                    <div className="col-md-4 mb-2">
                      <label>Building Rating</label>
                      <StarRatings
                        rating={buildingRating}
                        starRatedColor="blue"
                        changeRating={handleBuildingRatingChange}
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        onFocus={handleRatingFocus}
                      />
                    </div>
                    {/* customer rating */}
                    <div className="col-md-4 mb-2">
                      <label>Customer Rating</label>
                      <StarRatings
                        rating={customerRating}
                        starRatedColor="blue"
                        changeRating={handleCustomerRatingChange}
                        numberOfStars={5}
                        name="rating"
                        starDimension="30px"
                        disabled
                      />
                    </div>
                    {/* Project value */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project value</label>
                        <div className="d-flex">
                          <input
                            className="form-control"
                            id="projectValue"
                            value={projectValue}
                            placeholder="Enter a project value"
                            onChange={(e) => setProjectValue(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    {/* Project duration*/}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project duration</label>
                        <input
                          className="form-control"
                          id="projectValue"
                          value={projectDuration}
                          placeholder="Enter a project duration"
                          onChange={(e) => setProjectDuration(e.target.value)}
                          disabled
                        />
                      </div>
                    </div>

                    {/* selected status */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status</label>
                        <select
                          className="form-select form-control"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          required
                          disabled
                        >
                          <option value="">selected a status</option>
                          {getLeadStatus.map((status) => (
                            <option key={status._id} value={status._id}>
                              {status.leadStatus}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project</label>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            onChange={handleProjectCheckboxChange}
                            checked={isProjectChecked}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* Pincode */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="pincode">Pincode</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            placeholder="Enter a pincode"
                            value={pincode}
                            onChange={handlePincodeChange}
                            onKeyUp={handlePincodeBlur}
                            disabled
                          />
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="state"
                            placeholder="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      {/* City */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            readOnly
                            className="form-control"
                            id="city"
                            placeholder="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                      {/* Area Name */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="city">Area Name</label>
                          <select
                            id="customerCredits"
                            className="form-select form-control"
                            value={selectedAreaName}
                            onChange={(e) => setSelectedAreaName(e.target.value)}
                            disabled
                          >
                            <option value="">Select Area</option>
                            {areas.map((area, index) => (
                              <option key={index} value={area}>
                                {area}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <input
                            className="form-control"
                            rows={4}
                            id="address"
                            value={address}
                            placeholder="Enter a address"
                            onChange={(e) => setAddress(e.target.value)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    {/* image */}
                    {selectedImage && (
                      <div>
                        <label>Captured image</label>
                        <img
                          src={selectedImage}
                          alt="Selected Image"
                          className="d-block m-3 ms-0"
                          disabled
                        />
                      </div>
                    )}
                  </div>

                  {/* Map */}
                  <div style={{ width: "100%" }}>
                    {/* Map Integration */}
                    <div
                      className="map"
                      style={{ height: "50vh", width: "100%" }}
                    >
                      <button
                        className="btn btn-gradient-success mb-2"
                        onClick={getCurrentLocation}
                        disabled
                      >
                        Get Current Location
                      </button>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyDPgQr91arVKr9GYB4_-1dSzaCA1XdXckA",
                        }}
                        center={mapCenter}
                        defaultZoom={mapZoom}
                      >
                        <Marker lat={latitude} lng={longitude} />
                      </GoogleMapReact>
                    </div>
                  </div>
                  <canvas ref={canvasRef} style={{ display: "none" }} />

                  <div className="row" style={{ marginTop: "100px" }} >
                    {/* Approval status */}
                    {tabIndex === 0 && (
                      <div className="col-md-3 mb-2">
                        <label htmlFor="inlineFormInputName2">Approval Status</label>
                        <select
                          className="form-select form-control"
                          value={selectedApprovalStatus}
                          onChange={(e) => setSelectedApprovalStatus(e.target.value)}
                          required
                        >
                          <option value="">Select a Status</option>
                          <option value="waiting">waiting</option>
                          <option value="approved">approved</option>
                          <option value="declined">declined</option>
                          <option value="transfer">transfer</option>
                        </select>
                      </div>
                    )}

                    {/* Transfer users */}
                    {selectedApprovalStatus === "transfer" && (
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label htmlFor="inlineFormInputName2">Transfer User</label>
                          <select
                            className="form-select form-control"
                            value={selectedTransferUser}
                            onChange={(e) => setSelectedTransferUser(e.target.value)}
                            required
                          >
                            <option value="">Select transfer user</option>
                            {foundUserByRole(getUser, getAdminRole, 'TL').map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.name} (TL)
                              </option>
                            ))}
                            {foundUserByRole(getUser, getAdminRole, 'Admin').map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.name} (Admin)
                              </option>
                            ))}
                            {foundUserByRole(getUser, getAdminRole, 'Manager').map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.name} (Manager)
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    {/* TAB _ FOLLOWUP */}
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={getAddTabValue}>
                        <Box>
                          <TabList
                            onChange={handleAddTabChange}
                            aria-label="lab API tabs example"
                            textColor="secondary"
                            indicatorColor="secondary"
                          >
                            <Tab label="FollowUp" value="1" />
                            <Tab label="Quotation" value="2" />
                          </TabList>
                        </Box>
                        {/* Add followUP */}
                        <TabPanel value="1" onClick={() => handleAddChangeTab(0)}>
                          {/* Additional field */}
                          <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                            FollowUp History
                          </h4>
                          <h4
                            className="text-info"
                            style={{ marginBottom: "5px" }}
                          >{`Total FollowUps - ${additionalFields.length}`}</h4>
                          {additionalFields.map((field, index) => (
                            <div key={field.id} className="card follow-cards shadow">
                              <div class="card-header">
                                <div class="row">
                                  <div class="col">
                                    <label class="badge badge-info me-4">
                                      Follow {index + 1}
                                    </label>
                                    <label class="badge badge-info me-4">
                                      {new Date().toLocaleString() + ""}
                                    </label>
                                    <label class="badge badge-info me-4">
                                      {adminName}
                                    </label>
                                  </div>
                                  <div className="col text-end">
                                    {/* Remove button */}
                                    <button
                                      type="button"
                                      onClick={() => removeAdditionalField(index)}
                                      className="btn btn-sm badge-gradient-danger"
                                      aria-label="Close"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div class="row">
                                  {/* Next follow-up date picker */}
                                  <div className="col-md-4">
                                    <label>Next Follow-Up</label>
                                    <input
                                      type="datetime-local"
                                      class="form-control"
                                      placeholder="Next Follow Date"
                                      value={
                                        field.nextFollowupDate
                                          ? moment(field.nextFollowupDate).format(
                                            "YYYY-MM-DDTHH:mm"
                                          )
                                          : ""
                                      }
                                      required
                                      onChange={(e) =>
                                        handleDateChange(
                                          new Date(e.target.value),
                                          index,
                                          "nextFollowup"
                                        )
                                      }
                                      disabled
                                    />
                                  </div>
                                  {/* Remarks */}
                                  <div className="col-md-4">
                                    <label htmlFor={`remarks-${index}`}>Remarks</label>
                                    <textarea
                                      id={`remarks-${index}`}
                                      className="form-control"
                                      placeholder="Enter remarks"
                                      rows="3"
                                      value={field.remarks}
                                      required
                                      onChange={(e) =>
                                        handleRemarksChange(index, e.target.value)
                                      }
                                      disabled
                                    ></textarea>
                                  </div>

                                  {/* Upload Image 1*/}
                                  {/* <div className="col-md-4">
                                <label>Add Photo</label>
                                <br />
                                {!capturedImage[index] && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={openModal}
                                  >
                                    Camera
                                  </button>
                                )}
                                {capturedImage[index] && (
                                  <div className="image-preview row">
                                    <div className="image-preview-item col-md-4">
                                      {!capturedImage[index]
                                        .toString()
                                        .match("data") ? (
                                        <img
                                          src={
                                            process.env.REACT_APP_DATA_SERVER +
                                            capturedImage[index]
                                          }
                                          alt={`Captured ${index}`}
                                        />
                                      ) : (
                                        <img
                                          src={capturedImage[index]}
                                          alt={`Captured ${index}`}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div> */}
                                  {/* Upload Image 2 */}
                                  <div className="col-md-4">
                                    {field.imageSource && (
                                      <>
                                        <a href={process.env.REACT_APP_DATA_SERVER + field.imageSource} target="_blank" rel="noopener noreferrer">
                                          <img
                                            src={process.env.REACT_APP_DATA_SERVER + field.imageSource}
                                            width="150px"
                                            alt="captured image"
                                            className="d-block m-3 ms-0"
                                          />
                                        </a>
                                      </>
                                    )}
                                    {/* Image model */}


                                  </div>
                                </div>
                                <div class="row">
                                  <div className="col-md-4 text-center align-self-center">
                                    <div class="form-check form-check-success">
                                      <label class="form-check-label text-start">
                                        <input
                                          type="checkbox"
                                          class="form-check-input"
                                          onChange={(e) =>
                                            handleEscaltionCheckbox(
                                              index,
                                              e.target.checked
                                            )
                                          }
                                          checked={isEscalateChecked[index] || false}
                                          disabled
                                        ></input>
                                        Escalation <i class="input-helper"></i>
                                      </label>
                                    </div>
                                  </div>
                                  {/* Excalation */}
                                  <div className="col-4">
                                    {/* Form */}
                                    <h4 className="card-title"></h4>
                                    <div className="form-group">
                                      <label htmlFor="inlineFormInputName2">
                                        Escalation To
                                      </label>
                                      <select
                                        className="form-select form-control"
                                        value={selectedEscalationUser[index]}
                                        onChange={(e) =>
                                          handleEscalationChange(index, e.target.value)
                                        }
                                        disabled={isDropdownDisabled}
                                      >
                                        <option value="">Select Team User</option>
                                        {filteredEscalationUsers.map((teamUser) => (
                                          <option key={teamUser._id} value={teamUser._id}>
                                            {teamUser.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  {/* sample  */}
                                  <div className="col-md-4 text-center align-self-center">
                                    <div class="form-check form-check-success">
                                      <label class="form-check-label text-start">
                                        <input
                                          type="checkbox"
                                          class="form-check-input"
                                          onChange={(e) =>
                                            handleSampleCheckbox(index, e.target.checked)
                                          }
                                          checked={isSampleChecked[index] || false}
                                          disabled
                                        ></input>
                                        Sample issues <i class="input-helper"></i>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </TabPanel>
                        {/* Quotational history */}
                        <TabPanel value="2" onClick={() => handleAddChangeTab(1)}>
                          <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                            Quotational History
                          </h4>
                          {/* Quotational history */}
                          <div className="col-md-12">
                            {quotationalHistories && quotationalHistories.map((quotationalHistory, index) => {
                              let approvalStatus;

                              if (editingLiveMarketing && editingLiveMarketing.quotationApproval && editingLiveMarketing.quotationApproval.length > 0) {
                                let history = 0;
                                let lastIndex = 0;
                                for (let i = 0; i < editingLiveMarketing.quotationApproval.length; i++) {
                                  const quotationApproval = editingLiveMarketing.quotationApproval[i];
                                  if (
                                    quotationApproval?.approvalStatus !== "transfer" &&
                                    quotationApproval?.approvalStatus !== "forAdminApproval" &&
                                    i >= lastIndex
                                  ) {
                                    if (index === history) {
                                      approvalStatus = quotationApproval?.approvalStatus ?? "pending";
                                      lastIndex = i;
                                      //-console.log("i", i);
                                      //-console.log("history", history);
                                      //-console.log("history & Index", history, index);
                                      //-console.log("approvalStatus", approvalStatus);
                                      break;
                                    }
                                    history++;
                                  }
                                }
                              } else {
                                approvalStatus = 'pending';
                              }


                              return (
                                <div key={index} className="mt-4">
                                  <div className="card follow-cards shadow">
                                    <div className="card-header">
                                      <div className="row">
                                        <div className="col">
                                          <label className="badge badge-info me-4">
                                            Quotation {index + 1}
                                          </label>
                                          <label className="badge badge-info me-4">
                                            {new Date().toLocaleString()}
                                          </label>
                                          <label className="badge badge-info me-4">
                                            {adminName}
                                          </label>
                                        </div>
                                        <div className="col text-end">
                                          {/* Generate button */}
                                          {approvalStatus === "approved" ? (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                generateQuotation(editingLiveMarketing, index);
                                              }}
                                              className="btn btn-sm btn-success"
                                            >
                                              Generate Quotation
                                            </button>
                                          ) : (
                                            <p className="ps-2 fw-2" style={{ color: "green" }}>
                                              {approvalStatus}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Product Category</th>
                                          <th>Unit</th>
                                          <th>Quantity</th>
                                          <th>Given Price</th>
                                          <th>Expected Price</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {quotationalHistory.map((product, idx) => (
                                          <tr key={idx}>
                                            <td>{product.productType}</td>
                                            <td>{product.unit}</td>
                                            <td>{product.quantity}</td>
                                            <td>{product.givenPrice}</td>
                                            <td>{product.expectedPrice}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </TabPanel>
                      </TabContext>
                    </Box>
                    <div className="col-md-2">
                      {editingLiveMarketing && (
                        <button type="submit" className="btn btn-sm btn-primary" style={{ marginTop: "16px", width: "150px", height: "45px" }}>
                          {" "}
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* download quotation */}
        {QModal && (
          <div
            className="modal quotation-generator"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body bg-white">
                  <button
                    type="button"
                    className="btn-close float-end"
                    onClick={closeQModal}
                    aria-label="Close"
                  ></button>
                  <QuotationGenerator quotation_Data={editingLiveMarketing} />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Tab */}
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={getTabValue}>
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                width: '100%',
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="secondary"
                indicatorColor="secondary"
                sx={{ display: 'flex', minWidth: 'max-content' }}
              >
                <Tab label="Pending Quotation" value="1" />
                <Tab label="Approved Quotation" value="2" />
                <Tab label="Declined Quotation" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(0)}>
              {/* Table - live marketing */}
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate1}
                    onChange={(e) => handleFromDateChange(0, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate1}
                    onChange={(e) => handleToDateChange(0, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(0)}
                  >
                    Search date
                  </button>
                  {searchClicked1 && (
                    <button
                      onClick={() => handleClearFilter(0)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Waiting Leads</h4>
                      <Paper sx={{ height: 400, width: '100%', paddingX : "0px" }}>
                        <DataGrid
                          rows={filteredWaitingRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* approved */}
            <TabPanel value="2" sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(1)}>
              {/* Quotation */}
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate2}
                    onChange={(e) => handleFromDateChange(1, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate2}
                    onChange={(e) => handleToDateChange(1, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(1)}
                  >
                    Search date
                  </button>
                  {searchClicked2 && (
                    <button
                      onClick={() => handleClearFilter(1)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Approved Leads</h4>
                      <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={filteredApprovedRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* declined */}
            <TabPanel value="3"sx={{ paddingX: "0px" }} onClick={() => handleChangeTab(2)}>
              {/* Table - live marketing */}
              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate3}
                    onChange={(e) => handleFromDateChange(2, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate3}
                    onChange={(e) => handleToDateChange(2, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-primary mt-4"
                    onClick={() => handleSearchClick(2)}
                  >
                    Search date
                  </button>
                  {searchClicked3 && (
                    <button
                      onClick={() => handleClearFilter(2)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <div className="row justify-content-end m-1">
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={searchTableData}
                  onChange={handleTableSearchChange}
                  className="col-12 col-lg-6"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">List of Declined Leads</h4>
                      <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={filteredDeclinedRows()}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          onRowDoubleClick={(params) => handleEditingLiveMarketing(params.row.item)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </React.Fragment>
  );
};
function createData(
  cus_name,
  lead_source,
  lead_date,
  city,
  pincode,
  quotationalHistories,
  item
) {
  return {
    cus_name,
    lead_source,
    lead_date,
    city,
    pincode,
    quotationalHistories,
    item,
  };
}

export default QuotationApproval;
