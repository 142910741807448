import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from 'xlsx';

const MultipleProdCat = () => {
    const [getCompareProductTypes, setGetCompareProductTypes] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchMultiProdTableData, setSearchMultiProdTableData] = useState("");

    // Fetch product type comparison data
    useEffect(() => {
        const fetchCompareProductTypes = async () => {
            try {
                const response = await api.get("/compareProductTypes");
                setGetCompareProductTypes(response.data);
            } catch (error) {
                //-console.error('Error fetching product type comparisons:', error);
            }
        };

        fetchCompareProductTypes();
    }, []);

    // Fetch customer creation data
    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                //-console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    // Filter rows based on date range
    const filterRowsByDate = useCallback(
        (startDate, endDate) => {
            const filtered = getCompareProductTypes.filter(item => {
                return item.differences.some(diff => {
                    const allUniqueItems = Object.values(diff.differences).flat();
                    return allUniqueItems.some(diffItem => {
                        const createdAt = moment(diffItem.createdAt);
                        return createdAt.isBetween(
                            moment(startDate).startOf("day"),
                            moment(endDate).endOf("day"),
                            null,
                            "[]"
                        );
                    });
                });
            });

            setFilteredRows(filtered);
        },
        [getCompareProductTypes]
    );

    // Handle date changes
    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
    };

    // Columns for DataGrid
    const columns = [
        { field: "id", headerName: "Number", width: 100 },
        { field: "name", headerName: "Name", width: 250 },
        { field: "mobile", headerName: "Mobile", width: 150 },
        { field: "email", headerName: "Email", width: 300 },
    ];

    // Rows for DataGrid
    const rows = filteredRows.map((row, index) => {
        const customer = getCustomerCreation.find(
            (customer) => customer._id === row.name
        );
        return {
            id: index + 1,
            name: customer?.name || "N/A",
            mobile: customer?.mobile || "N/A",
            email: customer?.email || "N/A",
        };
    });

    const handleTableSearchChange = (event) => {
        setSearchMultiProdTableData(event.target.value);
    };

    const matchesMultiProdSearchTerm = (row) => {
        const item = row || {};

        const name = (item.name || '').toLowerCase();
        const mobile = (item.mobile || '').toString();
        const email = (item.email || '').toString();

        const searchValue = searchMultiProdTableData.toLowerCase();

        return (
            name.includes(searchValue) ||
            mobile.includes(searchValue) ||
            email.includes(searchValue)
        );
    };

    const filteredMultiProd = searchMultiProdTableData
        ? rows.filter(matchesMultiProdSearchTerm)
        : rows;

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredMultiProd);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "MultipleProductCategories");
        XLSX.writeFile(workbook, "Multiple_ProdCat.xlsx");
    };


    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Multiple Product Categories
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Date Filters */}
            <div className="container">
                <div className="row">
                    {/* From Date */}
                    <div className="col-md-3">
                        <label>From Date</label>
                        <input
                            type="date"
                            className="form-control"
                            name="fromDate"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </div>
                    {/* To Date */}
                    <div className="col-md-3">
                        <label>To Date</label>
                        <input
                            type="date"
                            className="form-control"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </div>
                    {/* Search Button */}
                    <div className="col-md-3">
                        <button
                            className="btn btn-primary mt-3"
                            onClick={handleSearchClick}
                        >
                            Search
                        </button>
                        {searchClicked && (
                            <button
                                onClick={handleClearFilter}
                                className="btn btn-sm badge-gradient-danger mt-3"
                                style={{ marginLeft: "10px" }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                </div>
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchMultiProdTableData}
                    onChange={handleTableSearchChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: '50%',
                        marginLeft: 'auto',
                        display: 'flex',
                        padding: '10px'
                    }}
                />
                {/* DataGrid Table */}
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body table-responsive">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h4 className="card-title">List of Multiple Product Categories</h4>
                                    </div>
                                    <div>
                                        <button onClick={downloadExcel} className="btn btn-success mb-3">
                                            Download Excel
                                        </button>
                                    </div>
                                </div>
                                <Paper sx={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={filteredMultiProd}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 5 },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10]}
                                        sx={{ border: 0 }}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MultipleProdCat;
