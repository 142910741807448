import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import LoginPage from "./Components/LoginPage";
import CreateLeadSource from "./Components/CreateLeadSource";
import CustomerCategory from "./Components/CustomerCategory";
import ProductCategory from "./Components/ProductCategory";
import LocationManager from "./Components/LocationManager";
import CustomerCreation from "./Components/CustomerCreation";
import LeadCreationOnline from "./Components/LeadCreationOnline";
import Admin from "./Components/CreateAdmin";
import CreateUser from "./Components/CreateUser";
import Team from "./Components/Team";
import LiveMarketing from "./Components/LiveMarketing";
import QuotationApproval from "./Components/QuotationApproval"
import CustomerCredit from "./Components/CustomerCredits";
import AdminDashboard from "./Components/AdminDashboard";
import UserDashboard from "./Components/UserDashboard";
import Setting from "./Components/Setting";
import LeadCancelation from "./Components/LeadCancelation";
import LeadLiveMonitoring from "./Components/LeadLiveMonitoring"
import UnderFollowUp from "./Components/UnderFollowUp";
import MultipleProdCat from "./Components/MultipleProdCat";
import QuotationGenerator from "./Components/QuotationGenerator";
import RepeatedCustomer from "./Components/RepeatedCustomer";
import ProductBasedReport from "./Components/ProductBasedReport";
import EmployeeBased from "./Components/EmployeeBased";
import CustomerAgeing from "./Components/CustomerAgeing";
import LeadEscalationReport from "./Components/LeadEscalationReport";
import MissedLead from "./Components/MissedLead";
import CreateVendor from "./Components/VendorCreation";
import LoadFollow from "./Components/LoadFollow";
import RecoveryFollow from "./Components/RecoveryFollow";
import IncentivePayments from "./Components/IncentivePayments"
import ConsolidationReport from "./Components/ConsolidationReport";

const App = () => {
  const [activeComponent, setActiveComponent] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return !!localStorage.getItem("user");
  });
  const [adminId, setAdminId] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [showNavbar, setShowNavbar] = useState(true);

  const checkLoggedIn = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const foundUser = JSON.parse(storedUser);
      setIsLoggedIn(true);
      setUserRole(foundUser.adminRole || "");
      setAdminId(foundUser._id);
      setAdminName(foundUser.name);
      // setRoleName(foundUser.adminRole.roleName);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  const handleLogin = (foundUser) => {
    setIsLoggedIn(true);
    setUserRole(foundUser.adminRole || "");
    setAdminId(foundUser._id);
    setAdminName(foundUser.name);
    setRoleName(foundUser.adminRole.roleName);
    localStorage.setItem("user", JSON.stringify(foundUser));
  };

  useEffect(() => {
    if (roleName === "Admin") {
      setActiveComponent("Admin Dashboard");
    } else {
      setActiveComponent("User Dashboard");
    }
  }, [roleName]);

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserRole(null);
    localStorage.removeItem("user");
  };

  const handlePageClick = (pageName) => {
    setActiveComponent(pageName);
  };

  const handleNotificationClick = (activePage) => {
    setActiveComponent(activePage);
  };

  const navbarDisplay = () => {
    setShowNavbar(showNavbar);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Admin Dashboard":
        return <AdminDashboard adminId={adminId} />;
      case "User Dashboard":
        return <UserDashboard adminId={adminId} />;
      case "Lead Source":
        return <CreateLeadSource adminId={adminId} />;
      case "Customer Credit":
        return <CustomerCredit adminId={adminId} />;
      case "Customer Category":
        return <CustomerCategory adminId={adminId} F />;
      case "Area Manager":
        return <LocationManager />;
      case "Product Category":
        return <ProductCategory adminId={adminId} />;
      case "Customer Creation":
        return <CustomerCreation adminId={adminId} />;
      case "Lead Online":
        return <LeadCreationOnline adminId={adminId} adminName={adminName} />;
      case "Role & Access":
        return <Admin adminId={adminId} />;
      case "Users":
        return <CreateUser adminId={adminId} />;
      case "Team":
        return <Team adminId={adminId} />;
      case "Live Marketing":
        return <LiveMarketing adminId={adminId} adminName={adminName} />;
      case "Quotation Approval":
        return <QuotationApproval adminId={adminId} adminName={adminName} />;
      case "Setting":
        return <Setting adminId={adminId} />
      case "Lead Cancelation":
        return <LeadCancelation adminId={adminId} adminName={adminName} />;
      case "Lead Monitoring":
        return <LeadLiveMonitoring adminId={adminId} adminName={adminName} />;
      case "Load Follow":
        return <LoadFollow adminId={adminId} adminName={adminName} />;
      case "Recovery Payment":
        return <RecoveryFollow adminId={adminId} adminName={adminName} />;
      case "Create Vendor":
        return <CreateVendor adminId={adminId} adminName={adminName} />;
      case "Incentive Reports":
        return <IncentivePayments adminId={adminId} adminName={adminName} />;
      case "Consolidation Reports":
        return <ConsolidationReport adminId={adminId} adminName={adminName} />;
      case "UnderFollowUp":
        return <UnderFollowUp adminId={adminId} adminName={adminName} />;
      case "MultipleProdCategory":
        return <MultipleProdCat adminId={adminId} adminName={adminName} />;
      case "RepeatedCustomer":
        return <RepeatedCustomer adminId={adminId} adminName={adminName} />;
      case "ProductCategory":
        return <ProductBasedReport adminId={adminId} adminName={adminName} />;
      case "EmployeeBased":
        return <EmployeeBased adminId={adminId} adminName={adminName} />;
      case "CustomerAgeing":
        return <CustomerAgeing adminId={adminId} adminName={adminName} />;
      case "LeadEscalation":
        return <LeadEscalationReport adminId={adminId} adminName={adminName} />;
      case "MissedLead":
        return <MissedLead adminId={adminId} adminName={adminName} />;
      default:
        return null;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route path="/quotation" element={<QuotationGenerator />} />
        <Route
          path="/home"
          element={
            isLoggedIn ? (
              <div className="container-scroller">
                {isLoggedIn && (
                  <Header
                    adminId={adminId}
                    adminName={adminName}
                    roleName={roleName}
                    onLogout={handleLogout}
                    onNotificationClick={handleNotificationClick}
                    navbarDisplay={navbarDisplay}
                  />
                )}
                <div className="container-fluid page-body-wrapper">
                  <Sidebar
                    userRole={userRole}
                    onPageClick={handlePageClick}
                    showNavbar={showNavbar}
                  />
                  <div className="main-panel">
                    <div className="content-wrapper px-3">{renderComponent()}</div>
                  </div>
                </div>
              </div>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
