import React, { useState, useEffect } from "react";
import api from "../Api";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Loader from './Loader';

const CustomerCredit = ({ adminId }) => {
  const [customerCredits, setCustomerCredits] = useState("");
  const [getCustomerCredits, setGetCustomerCredits] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingCustomerCredits, setEditingCustomerCredits] = useState(null);
  const [deleteCustomerCredits, setDeleteCustomerCredits] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showAlert, setShowAlert] = useState({ visible: false, message: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleSetCustomerCredits = (e) => {
    setCustomerCredits(e.target.value);
  };


  const fetchCustomerCredits = async () => {
    try {
      const response = await api.get("/customerCredits");
      setGetCustomerCredits(response.data);
    } catch (error) {
      console.error("Error fetching customer credits:", error);
    }
  };


  useEffect(() => {
    fetchCustomerCredits();
  }, []);


  const fetchUserRole = async () => {
    try {
      const response = await api.post("/userRole", { adminId });
      setUserRole(response.data);
    } catch (error) {
      console.error("Error fetching User Role:", error);
    }
  };

  useEffect(() => {
    if (adminId) {
      fetchUserRole();
    }
  }, [adminId]);


  const handleAddCustomerCredits = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check for duplicates
    const isDuplicate = getCustomerCredits.some(credit => credit.customerCredits === customerCredits && (!editingCustomerCredits || credit._id !== editingCustomerCredits.id));

    if (isDuplicate) {
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Customer credit already exists', type: 'danger' });
      return;
    }

    try {
      if (editingCustomerCredits) {
        const response = await api.put(`/customerCredits/${editingCustomerCredits.id}`, { customerCredits });
        console.log("Customer credits updated:", response.data);
        setEditingCustomerCredits(null);
      } else {
        const response = await api.post("/customerCredits", { customerCredits });
        console.log("Customer credits added:", response.data);
      }
      setIsLoading(false);
      setCustomerCredits("");
      fetchCustomerCredits();
      setShowForm(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      console.error("Error adding/updating customer credits:", error);
      setShowAlert({ visible: true, message: 'Error occurred while submitting customer credit. Please try again.', type: 'danger' });
    }
  };

  const handleDeleteCustomerCredit = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this entry?")) {
        return;
      }
      setIsLoading(true);
      const response = await api.delete(`/customerCredits/${deleteCustomerCredits.id}`);
      if (response.status === 200) {
        fetchCustomerCredits();
        setShowForm(false);
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      setShowAlert({ visible: true, message: 'Error occurred while deleting customer credit', type: 'danger' });
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setEditingCustomerCredits(null);
    setCustomerCredits("");
  };

  const handleEditingCustomerCredits = (customerCredit) => {
    setIsLoading(false);
    setShowAlert({ visible: false, message: '', type: '' });
    setEditingCustomerCredits(customerCredit);
    setDeleteCustomerCredits(customerCredit);
    setCustomerCredits(customerCredit.customerCredits);
    setShowForm(true);
  };

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])


  const columns = [
    { field: 'number', headerName: 'Number', width: 100 },
    { field: 'customerCredits', headerName: 'Customer Credits', width: 200 },
  ];

  const rows = getCustomerCredits.map((credit, index) => ({
    id: credit._id,
    number: index + 1,
    customerCredits: credit.customerCredits,
  }));

  return (
    <React.Fragment>
      <div className="container">
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="fa fa-inr"></i>
            </span>{" "}
            Customer Credits
          </h3>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <span></span>Overview{" "}
                <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        </div>
        <div className="col-12">
          {userRole && userRole.pageList.find(page => page.pageName === "Customer Credit").access[1] === 1 && (
            <button
              onClick={() => toggleForm()}
              className="btn btn-gradient-primary mb-2"
              type="button"
            >
              Create Customer Credits
            </button>
          )}
        </div>

        <div
          className={`offcanvas offcanvas-end w-50 ${showForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              {editingCustomerCredits
                ? "Edit Customer Credit"
                : "Create Customer Credit"}
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <form
              onSubmit={handleAddCustomerCredits}
              style={{
                display: showForm || (editingCustomerCredits ? "block" : "none"),
              }}
            >
              <div className="mb-3">
                <label htmlFor="leadSource" className="form-label">
                  Lead Source
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="leadSource"
                  value={customerCredits}
                  onChange={handleSetCustomerCredits}
                />
              </div>
              <div className="row text-start">
                <div className="col-8 col-md-3 text-start">
                  {userRole && userRole.pageList.find(page => page.pageName === "Customer Credit").access[2] === 1 && (
                    <button type="submit" className="btn btn-sm btn-primary">
                      {editingCustomerCredits
                        ? "Update Customer Credit"
                        : "Add Customer Credit"}
                    </button>
                  )}
                </div>
                <div className="col-4 col-md-3 align-items-center">
                  {userRole?.pageList.some(page => page.pageName === "Customer Credit" && page.access[3] === 1) && editingCustomerCredits && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleDeleteCustomerCredit}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Loader isLoading={isLoading} />
        {showAlert.visible && (
          <div
            className={`alert alert-${showAlert.type} alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1050
            }}
          >
            <strong>{showAlert.message}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert({ ...showAlert, visible: false })}
            ></button>
          </div>
        )}

        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body table-responsive">
                <h4 className="card-title">List of Customer Credits</h4>
                <Paper sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    onRowDoubleClick={(params) => handleEditingCustomerCredits(params.row)}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerCredit;
