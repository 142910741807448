import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Paper from "@mui/material/Paper";
import * as XLSX from 'xlsx';

const LeadEscalationReport = () => {
    const [searchEscalationTableData, setSearchEscalationTableData] = useState("")
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState("FollowUp");
    const [statusFilter, setStatusFilter] = useState(["escalation", "quotation"]);
    const [getLeadEscalationReport, setGetLeadEscalationReport] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [getLeadStatus, setGetLeadStatus] = useState([]);
    const [getUser, setGetUser] = useState([]);

    useEffect(() => {
        const fetchLeadEscalationReport = async () => {
            try {
                const response = await api.get('/LeadEscalationReport');
                setGetLeadEscalationReport(response.data);
            } catch (error) {
                // console.error('Error fetching lead escalation:', error);
            }
        };
        fetchLeadEscalationReport();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get("/user");
                setGetUser(response.data);
            } catch (error) {
                // console.error("Error fetching User:", error);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                // console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    useEffect(() => {
        const fetchLeadStatus = async () => {
            try {
                const response = await api.get("/leadStatus");
                setGetLeadStatus(response.data);
            } catch (error) {
                // console.error("Error fetching lead status:", error);
            }
        };
        fetchLeadStatus();
    }, []);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = getLeadEscalationReport.filter((row) => {
            const createdAt = moment(row.createdAt, "YYYY-MM-DD hh:mm A");
            return createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );
        });
        setFilteredRows(filtered);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
    };

    useEffect(() => {
        const determineStatusFilter = () => {
            switch (selectedReport) {
                case "FollowUp":
                    return ["escalation", "quotation"];
                case "Non Converted Leads":
                    return ["cancel"];
                case "Converted":
                    return ["order success"];
                default:
                    return [];
            }
        };

        setStatusFilter(determineStatusFilter());
    }, [selectedReport]);

    const getReportFilteredRows = () => {
        if (!searchClicked) return [];

        return filteredRows.filter((row) => {
            const leadStatus = getLeadStatus.find(status => status._id === row.status)?.leadStatus;
            return statusFilter.includes(leadStatus);
        });
    };

    const LeadReportFilteredRows = getReportFilteredRows();

    // Define columns for DataGrid
    const columns = [
        { field: 'number', headerName: 'Number', width: 90 },
        { field: 'customerCode', headerName: 'Customer Code', width: 150 },
        { field: 'customerName', headerName: 'Customer Name', width: 150 },
        { field: 'mobile', headerName: 'Mobile', width: 150 },
        { field: 'createdBy', headerName: 'Created By', width: 150 },
        { field: 'e1', headerName: 'E1', width: 150 },
        { field: 'e2', headerName: 'E2', width: 150 },
        { field: 'e3', headerName: 'E3', width: 150 },
        { field: 'e4', headerName: 'E4', width: 150 },
        { field: 'e5', headerName: 'E5', width: 150 },
    ];

    // Prepare rows for DataGrid
    const rows = LeadReportFilteredRows.map((row, index) => {
        const customer = getCustomerCreation.find(customer => customer._id === row.name);
        const createdBy = getUser.find(user => user._id === row.createdBy);
        const escalations = row.followup.map(followup => followup?.escalationTo || '');

        return {
            id: row._id,
            number: index + 1,
            customerCode: customer?.customerCode || '',
            customerName: customer?.name || '',
            mobile: customer?.mobile || '',
            createdBy: createdBy?.name || '',
            e1: getUser.find(user => user._id === escalations[0])?.name || '',
            e2: getUser.find(user => user._id === escalations[1])?.name || '',
            e3: getUser.find(user => user._id === escalations[2])?.name || '',
            e4: getUser.find(user => user._id === escalations[3])?.name || '',
            e5: getUser.find(user => user._id === escalations[4])?.name || '',
        };
    });

    const handleTableSearchChange = (event) => {
        setSearchEscalationTableData(event.target.value);
    };

    const matchesEscalationSearchTerm = (row) => {
        const item = row || {};

        const name = (item.customerName || '').toLowerCase();
        const customerCode = (item.customerCode || '').toString().toLowerCase();
        const mobile = (item.mobile || '').toString().toLowerCase();
        const createdBy = (item.createdBy || '').toLowerCase();
        const e1 = (item.e1 || '').toLowerCase();
        const e2 = (item.e2 || '').toLowerCase();
        const e3 = (item.e3 || '').toLowerCase();

        const searchValue = searchEscalationTableData.toLowerCase();

        return (
            name.includes(searchValue) ||
            customerCode.includes(searchValue) ||
            mobile.includes(searchValue) ||
            createdBy.includes(searchValue) ||
            e1.includes(searchValue) ||
            e2.includes(searchValue) ||
            e3.includes(searchValue)
        );
    };


    const filteredEscalation = searchEscalationTableData
        ? rows.filter(matchesEscalationSearchTerm)
        : rows;

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredEscalation);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Escalation");
        XLSX.writeFile(workbook, "Escalation.xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Lead Escalation
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Filter and Search Section */}
            <div className="row">
                <div className="col-md-3">
                    <label>From Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label>To Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <div className="form-group">
                        <label htmlFor="inlineFormInputName2">Status Category</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                            required
                        >
                            <option value="FollowUp">FollowUp</option>
                            <option value="Non Converted Leads">Non Converted Leads</option>
                            <option value="Converted">Converted Leads</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger mt-3"
                            aria-label="Close"
                            style={{ marginLeft: '10px' }}
                        >
                            &times;
                        </button>
                    )}
                </div>
            </div>
            <TextField
                variant="outlined"
                placeholder="Search"
                value={searchEscalationTableData}
                onChange={handleTableSearchChange}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: '50%',
                    marginLeft: 'auto',
                    display: 'flex',
                    padding: '10px'
                }}
            />
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title">List of Escalation Lead</h4>
                                </div>
                                <div>
                                    <button onClick={downloadExcel} className="btn btn-success mb-3">
                                        Download Excel
                                    </button>
                                </div>
                            </div>
                            <Paper sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={filteredEscalation}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    sx={{ border: 0 }}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LeadEscalationReport;
