import React, { useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import api from "../Api";
import html2canvas from "html2canvas";
import "./QuotationGenerator.css"; // Import the CSS file

const QuotationGenerator = ({ quotation_Data }) => {
  console.log("quotation_Data", quotation_Data);
  const test_data = JSON.parse(localStorage.getItem("generateQuotation")) || {};
  quotation_Data = test_data.leadData;
  const quote = test_data.quote;
  //-console.log(test_data);
  //-console.log("quote",quote);
  const quotationContentRef = useRef(null);
  const dateObject = new Date(quotation_Data.createdAt);
  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const year = String(dateObject.getFullYear()).slice(-4);
  const formattedDate = `${day}/${month}/${year}`;
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [getUser, setGetUser] = useState([]);

  useEffect(() => {
    window.print(); // Executes print when the component mounts

    const handleBeforeUnload = () => {
      localStorage.removeItem("generateQuotation");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const quotation_id_gen = () => {
    let id = quotation_Data.name.customerCode + "-" + formattedDate + "-" + quotation_Data.leadCount + `-` + (quote + 1);
    return id;
  };
  const gstValue = () => {
    let total = 0;
    quotation_Data.quotationalHistories[quote].forEach((product) => {
      total += ((product.productType.productGST / 100)* product.givenPrice) * product.quantity;
    });
    return total;
  };
  const subTotal = () => {
    let total = 0;
    quotation_Data.quotationalHistories[quote].forEach((product) => {
      total += product.givenPrice * product.quantity;
    });
    return total;
  };

  const handleDownloadPDF = () => {
    const input = quotationContentRef.current;

    html2canvas(input, {
      scale: 2, // Increase the scale (2 is recommended for better resolution)
      useCORS: true, // Enable CORS if the HTML contains images from other domains
      logging: true, // Enable logging to console for debugging purposes
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
      const imgWidth = 210; // PDF width (A4)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("quotation.pdf");
    });
  };
  const currentDate = new Date().toLocaleDateString();

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/user");
        setGetUser(response.data);
        //-//-console.log(response.data);

      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchUser();
  }, []);


  const findUserRole = (assignedToUserRole) => {
    const userRole = getAdminRole.find(role => {
        return role._id === assignedToUserRole;
    });
    return userRole ? userRole.roleName : null;
};

const assignedToUser = quotation_Data.assignedTo?.name;
const assignedToUserRole = quotation_Data.assignedTo?.adminRole; 

const userRoleName = findUserRole(assignedToUserRole);
  
  const assignedToUserMobile = quotation_Data.assignedTo?.mobile || quotation_Data.assignedTo?.phoneNumber;
  

  return (
    <div className="q_container">
      <div className="quotation-content" ref={quotationContentRef}>
        <div className="p-5">
          <div className="row q_header">
            <div className="col-8">
              <h2 className="q_title">RPC Builders Supply</h2>
              <p className="mb-1">114/4C, Ashoka Puram Busstop, Aval Poondurai Road, Ashokapuram, Avalpoondurai, Erode, Tamilnadu, 638115</p>
              <p className="mb-1">Name: {assignedToUser} ({userRoleName})</p>
              <p className="mb-1">Contact: {assignedToUserMobile}</p>
              <p className="mb-1">Contact: +91-8344104202</p>
              <p>rpcgroups3@gmail.com, annaiplanners2016@gmail.com</p>
            </div>
            <div className="col-4 text-end">
              <img src="/assets/images/rpc_logo.jpg" alt="rpc_logo" srcset="" height="100px" />
            </div>
          </div>
          <div className="row float-end text-end me-2">
            <p className="mb-1">Date: {formattedDate}</p>
            <p className="mb-1">Quotation Id: {quotation_id_gen()}</p>
          </div>
          <div className="row mt-5 col-md-12">
            <p className="mb-1 p-0">To</p>
            <p className="mb-1 p-0">{quotation_Data.name.name}</p>
            <p className="mb-1 p-0">{quotation_Data?.address ?? "" + `, ` + quotation_Data?.pincode ?? ""}</p>
            <p className="mb-1 p-0">Contact :{` ` + quotation_Data.name?.mobile ?? "" + ", " + quotation_Data.name?.alterMobile ?? "" + ", " + quotation_Data.name?.email ?? ""}</p>
            <p className="quotation-subject p-0">Subject: Product Quotation</p>
          </div>
          <div className="row mt-4">
            <p className="">Dear {quotation_Data.name.name}</p>
            <p className="">Your enquiry for the below products dated : {formattedDate}</p>
            <p className=" ">Thankyou for showing interest in our products & contacting us. Please find our exclusive quotation for your requirements.</p>
          </div>
          <div className="row">
            <table class="table table-striped">
              <thead className="table-info">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Product Details</th>
                  <th scope="col">Images</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Price / Unit</th>
                  <th scope="col">GST</th>
                  <th scope="col">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {quotation_Data.quotationalHistories[quote].map(
                  (product, index) => (
                    //-console.log("product", product),
                    (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td> {product.productType?.product?.product || ''} {product.productType?.productTypes || ''} {product.productType?.brand?.brand || ''}</td>
                        <td>{product.productType.productImage ? <img src={process.env.REACT_APP_API_SERVER + "/" + product.productType.productImage} alt="" /> : ""}</td>
                        <td>{product.quantity.toLocaleString()}</td>
                        <td>{product.givenPrice + `/` + product.unit.unit}</td>
                        <td>{product.productType.productGST}%</td>
                        <td>{(product.quantity * product.givenPrice).toLocaleString()}</td>
                      </tr>
                    )
                  )
                )}

                <tr>
                  <th className="text-end" scope="row" colspan="6">
                    Sub Total:
                  </th>
                  <td>{subTotal().toLocaleString()}</td>
                </tr>
                <tr>
                  <th className="text-end" scope="row" colspan="6">
                    GST:
                  </th>
                  <td>{gstValue().toLocaleString()}</td>
                </tr>
                <tr>
                  <th className="text-end" scope="row" colspan="6">
                    Grand Total:
                  </th>
                  <td>{(subTotal() + gstValue()).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="quotation-subject mb-0">Terms and Conditions</p>
          {quotation_Data.quotationalHistories[quote].map((product, index) => {
            const termsAndCondition = 
              product?.productType?.product?.["\r\ntermsAndCondition"]?.replace(/\r\n/g, '\n') || "";

            return (
              <pre key={index} className="mb-0" style={{ fontFamily: "Times New Roman, serif", lineHeight: '1.5', }}>
                {termsAndCondition}
              </pre>
            );
          })}
          
          <p>Please contact us in case of any concerns.</p>
          <p class="quotation-subject mb-0">Sincerely yours</p>
          <p class="mb-0">Aravinth Sivanantham </p>
          <p>https://www.rpcgroups.com</p>
        </div>
      </div>
      {/* <button className="btn btn-primary" onClick={handleDownloadPDF}>
        Download Quotation as PDF
      </button> */}
    </div>
  );
};

export default QuotationGenerator;
