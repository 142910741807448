import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from 'xlsx';

const ProductBasedReport = () => {
    const [searchProdBasedTableData, setSearchProdBasedTableData] = useState("")
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState("");
    const [getSelectedProductReport, setGetSelectedProductReport] = useState([]);
    const [getProductCategory, setGetProductCategory] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [getProduct, setGetProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [getUnit, setGetUnit] = useState([]);
    const [getProductType, setGetProductType] = useState([]);

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await api.get("/unit");
                setGetUnit(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchUnits();
    }, []);

    useEffect(() => {
        const fetchProductType = async () => {
            try {
                const response = await api.get("/productTypes");
                setGetProductType(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchProductType();
    }, []);

    useEffect(() => {
        const fetchSelectedProductReport = async () => {
            if (selectedProductCategory || selectedProduct) {
                try {
                    const params = {};
                    if (selectedProductCategory) {
                        params.selectedProductCategory = selectedProductCategory;
                    }
                    if (selectedProduct) {
                        params.selectedProduct = selectedProduct;
                    }
                    const response = await api.get('/productReport', { params });
                    setGetSelectedProductReport(response.data.filteredProductReportData);
                } catch (error) {
                    // Handle error
                }
            } else {
                setGetSelectedProductReport([]);
                setFilteredRows([]);
            }
        };

        if (searchClicked) {
            fetchSelectedProductReport();
        }
    }, [selectedProductCategory, selectedProduct, searchClicked]);

    useEffect(() => {
        if (searchClicked) {
            filterRowsByDate(fromDate, toDate);
        }
    }, [getSelectedProductReport, searchClicked]);

    useEffect(() => {
        const fetchProducts = async () => {
            if (selectedProductCategory !== "") {
                try {
                    const response = await api.get(`/product/${selectedProductCategory}`);
                    setGetProduct(response.data);
                } catch (error) {
                    // Handle error
                }
            }
        };

        if (selectedProductCategory) {
            fetchProducts();
        } else {
            setGetProduct([]);
        }
    }, [selectedProductCategory]);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchCustomerCreation();
    }, []);

    useEffect(() => {
        const fetchProductCategory = async () => {
            try {
                const response = await api.get("/productCategory");
                setGetProductCategory(response.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchProductCategory();
    }, []);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = getSelectedProductReport.filter((item) => {
            const createdAt = moment(item.leadData.createdAt, "YYYY-MM-DD hh:mm A");
            return createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );
        });
        setFilteredRows(filtered);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };
        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        setSelectedProductCategory("");
        setSelectedProduct("");
        setFilteredRows([]);
        setGetSelectedProductReport([]);
    };

    // Define columns for DataGrid
    const columns = [
        { field: 'number', headerName: 'Number', width: 100 },
        { field: 'customerCode', headerName: 'Customer Code', width: 150 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'mobile', headerName: 'Mobile', width: 150 },
        { field: 'productName', headerName: 'Product Name', width: 200 },
        { field: 'unit', headerName: 'Unit', width: 100 },
        { field: 'quantity', headerName: 'Quantity', width: 120 },
        { field: 'givenPrice', headerName: 'Given Price', width: 150 },
        { field: 'expectedPrice', headerName: 'Expected Price', width: 150 },
    ];

    // Prepare rows for DataGrid
    const rows = filteredRows.flatMap((row, index) => {
        const customer = getCustomerCreation.find(
            (customer) => customer._id === row.leadData.name
        );

        return row.leadData?.quotationalHistories.flat().map((product, productIdx) => {
            // Fetch unit details
            const selectedUnitObject = getUnit.find(unit => unit._id === product.unit);
            const unitName = selectedUnitObject ? selectedUnitObject.unit : 'N/A';

            // Fetch product details
            const selectedProduct = getProductType.find(prod => prod._id === product.productType);
            const productName = selectedProduct
                ? `${selectedProduct.ProductCategory.productCategory} 
                   ${selectedProduct.product.product} 
                   ${selectedProduct.productTypes} 
                   ${selectedProduct.brand.brand}`
                : 'N/A';

            return {
                id: `${row.leadData.name}-${productIdx}`, // Unique ID for each row
                number: index + 1,
                customerCode: customer?.customerCode || "N/A",
                name: customer?.name || "N/A",
                mobile: customer?.mobile || "N/A",
                productName: productName,
                unit: unitName,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            };
        });
    });

    const handleTableSearchChange = (event) => {
        setSearchProdBasedTableData(event.target.value);
    };

    const matchesProdBasedSearchTerm = (row) => {
        const item = row || {};

        const name = (item.name || '').toLowerCase();
        const customerCode = (item.customerCode || '').toString().toLowerCase();
        const mobile = (item.mobile || '').toString().toLowerCase();
        const productName = (item.productName || '').toLowerCase();
        const quantity = (item.quantity || '').toString().toLowerCase();

        const searchValue = searchProdBasedTableData.toLowerCase();

        return (
            name.includes(searchValue) ||
            customerCode.includes(searchValue) ||
            mobile.includes(searchValue) ||
            productName.includes(searchValue) ||
            quantity.includes(searchValue)
        );
    };

    const filteredProdBased = searchProdBasedTableData
        ? rows.filter(matchesProdBasedSearchTerm)
        : rows;


    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredProdBased);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ProductBased");
        XLSX.writeFile(workbook, "Product_Based.xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>
                    Product Category Based
                </h3>
            </div>

            <div className="row">
                <div className="col-md-2">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-2">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label className="h4" htmlFor="productCategory">Product Category</label>
                    <select
                        className="form-select form-control"
                        id="productCategory"
                        value={selectedProductCategory}
                        required
                        onChange={(e) => setSelectedProductCategory(e.target.value)}
                    >
                        <option value="">Select a product category</option>
                        {getProductCategory.map((productCategory) => (
                            <option key={productCategory._id} value={productCategory._id}>
                                {productCategory.productCategory}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2">
                    <label className="h4" htmlFor="product">Product</label>
                    <select
                        className="form-select form-control"
                        id="product"
                        value={selectedProduct}
                        required
                        onChange={(e) => setSelectedProduct(e.target.value)}
                    >
                        <option value="">Select a product</option>
                        {getProduct.map((product) => (
                            <option key={product._id} value={product._id}>
                                {product.product}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 mt-4">
                    <button
                        className="btn btn-primary me-2"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginBottom: "4px" }}
                        >
                            &times;
                        </button>
                    )}
                </div>
            </div>
            <TextField
                variant="outlined"
                placeholder="Search"
                value={searchProdBasedTableData}
                onChange={handleTableSearchChange}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: '50%',
                    marginLeft: 'auto',
                    display: 'flex',
                    padding: '10px'
                }}
            />
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title">List of Product Category Based</h4>
                                </div>
                                <div>
                                    <button onClick={downloadExcel} className="btn btn-success mb-3">
                                        Download Excel
                                    </button>
                                </div>
                            </div>
                            <Paper sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={filteredProdBased}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    sx={{ border: 0 }}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductBasedReport;
