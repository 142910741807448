import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';

const RepeatedCustomer = () => {
    const [data, setData] = useState({
        repeatedCustomers: [],
        liveMarketingData: [],
        leadOnlineData: [],
        gridRows: [],
    });
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchRepeatedCustomerTableData, setSearchRepeatedCustomerTableData] = useState("");

    // Fetch data from API
    const fetchData = useCallback(async () => {
        try {
            const [repeatedResponse, liveResponse, leadResponse] = await Promise.all([
                api.get("/repeatedCustomer"),
                api.get("/liveMarketing"),
                api.get("/leadCreationData"),
            ]);

            const fetchedData = {
                repeatedCustomers: repeatedResponse.data,
                liveMarketingData: liveResponse.data,
                leadOnlineData: leadResponse.data,
                gridRows: [],
            };

            setData(fetchedData);

        } catch (error) {
            //-console.error("Error fetching data:", error);
        }
    }, []);

    useEffect(() => {
        // -

        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    // Filter rows based on date range
    const filterRowsByDate = useCallback(
        (data, start, end) => {
            const filtered = data.gridRows.filter((row) => {
                const createdAt = moment(row.createdAt, "YYYY-MM-DD hh:mm A");
                return createdAt.isBetween(
                    moment(start).startOf("day"),
                    moment(end).endOf("day"),
                    null,
                    "[]"
                );
            });
            setFilteredRows(filtered);
        },
        []
    );

    const matchesRepeatedCustomerSearchTerm = (row) => {
        const item = row || {};

        const cus_name = (item.cus_name || '').toLowerCase();
        const mobile = (item.mobile || '').toString();

        const searchValue = searchRepeatedCustomerTableData.toLowerCase();

        return (
            cus_name.includes(searchValue) ||
            mobile.includes(searchValue)
        );
    };

    const filteredRepeatedCustomer = searchRepeatedCustomerTableData
        ? filteredRows.filter(matchesRepeatedCustomerSearchTerm)
        : filteredRows;

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredRepeatedCustomer);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "RepeatedCustomer");
        XLSX.writeFile(workbook, "Repeated_Customer.xlsx");
    };

    // Create grid rows from combined data
    useEffect(() => {
        const combinedData = [...data.liveMarketingData, ...data.leadOnlineData];
        const uniqueCustomers = new Map();

        data.repeatedCustomers.forEach((customer) => {
            const relevantItems = combinedData.filter(
                (item) => item.name?._id === customer.name
            );

            relevantItems.forEach((item) => {
                // Log the item to verify the structure
                //-console.log("item", item);

                // Create a unique key for each customer
                const uniqueKey = `${item.name?.name}_${item.altermobile || "N/A"}`;

                if (!uniqueCustomers.has(uniqueKey)) {
                    uniqueCustomers.set(uniqueKey, {
                        cus_name: item.name?.name || "",  
                        mobile: item.name?.mobile || "",  
                        email: item.name?.email || "",    
                        frequency: customer.frequency || "",  
                        createdAt: item.createdAt || "", 
                    });
                }
            });
        });

        // Map the customer data into grid rows
        const tempRows = Array.from(uniqueCustomers.values()).map((item, index) => ({
            number: index + 1, 
            ...item,     
        }));

        setData((prevData) => ({ ...prevData, gridRows: tempRows }));
    }, [data.liveMarketingData, data.leadOnlineData, data.repeatedCustomers]);


    // Handle date changes
    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(data, fromDate, toDate);
    };

    // Handle clear filter
    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(data, currentDateFormatted, currentDateFormatted);
    };

    // Create data helper function
    const createData = (cus_name, item, createdAt) => ({
        cus_name,
        item,
        createdAt,
    });

    useEffect(() => {
        if (searchClicked) {
            filterRowsByDate(data, fromDate, toDate);
        }
    }, [searchClicked, data, fromDate, toDate, filterRowsByDate]);

    const columns = [
        { field: 'number', headerName: 'Number', width: 120 },
        { field: 'cus_name', headerName: 'Customer Name', width: 280 },
        { field: 'mobile', headerName: 'Mobile', width: 250 },
        { field: 'email', headerName: 'Email', width: 280 },
        { field: 'frequency', headerName: 'Frequency', width: 150 },
    ];

    const handleTableSearchChange = (event) => {
        setSearchRepeatedCustomerTableData(event.target.value);
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Repeated Customers
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="container">
                <div className="row">
                    {/* From Date */}
                    <div className="col-md-3">
                        <label>From Date</label>
                        <input
                            type="date"
                            className="form-control"
                            name="fromDate"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>To Date</label>
                        <input
                            type="date"
                            className="form-control"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-primary mt-4" onClick={handleSearchClick}>
                            Search Date
                        </button>
                        {searchClicked && (
                            <button
                                onClick={handleClearFilter}
                                className="btn btn-sm badge-gradient-danger"
                                aria-label="Close"
                                style={{ marginLeft: "10px", marginTop: "30px" }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                </div>
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchRepeatedCustomerTableData}
                    onChange={handleTableSearchChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: '50%',
                        marginLeft: 'auto',
                        display: 'flex',
                        padding: '10px'
                    }}
                />
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body table-responsive">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h4 className="card-title">List of Repeated Customers</h4>
                                    </div>
                                    <div>
                                        <button onClick={downloadExcel} className="btn btn-success mb-3">
                                            Download Excel
                                        </button>
                                    </div>
                                </div>
                                <Paper sx={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={filteredRepeatedCustomer}
                                        columns={columns}
                                        getRowId={(row) => `${row.cus_name}_${row.mobile}`}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 5 },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10]}
                                        sx={{ border: 0 }}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default RepeatedCustomer;
