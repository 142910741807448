import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";

import * as XLSX from 'xlsx';

const ConsolidationReport = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [rows, setRows] = useState([]);
    const [consolidationData, setConsolidationData] = useState([]);
    const [selectedStaffName, setSelectedStaffName] = useState("");
    const [getUser, setGetUser] = useState([]);
    const [searchTableData, setSearchTableData] = useState("");


    const fetchConsolidationData = async () => {
        if (!selectedStaffName) return;
        try {
            const response = await api.get(`/consolidation/${selectedStaffName}`, {
                params: { fromDate, toDate }
            });
            setConsolidationData(response.data);
            console.log("consol", response.data);
            
        } catch (error) {
            console.error("Error fetching incentive report:", error);
        }
    };
    

    const handleSearchClick = () => {
        setSearchClicked(true);
        fetchConsolidationData();
    };



    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get("/user");
                setGetUser(response.data);
            } catch (error) {
                //-console.error("Error fetching User:", error);
            }
        };
        fetchUser();
    }, []);


    // Handle date changes
    const handleFromDateChange = (e) => {
        //-console.log("From date changed:", e.target.value);
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        //-console.log("To date changed:", e.target.value);
        setToDate(e.target.value);
    };



    useEffect(() => {
        if (searchClicked) {
            fetchConsolidationData();
        }
    }, [consolidationData, fromDate, toDate, searchClicked]);

    useEffect(() => {
        if (selectedStaffName) {
            fetchConsolidationData();
        }
    }, [ selectedStaffName]);


    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);


    const columns = [

        { field: 'number', headerName: 'Number', width: 100 },
        { field: 'staff', headerName: 'Staff', width: 220 },
        { field: 'LoadReference', headerName: 'Load Reference', width: 180 },
        { field: 'VendorReference', headerName: 'Vendor Reference', width: 180 },
        { field: 'collectionReference', headerName: 'collection Reference', width: 180 },
        { field: 'LeadReference', headerName: 'Lead Reference', width: 180 },

    ];

    useEffect(() => {
        if (consolidationData.length > 0) {
            const transformedRows = consolidationData.map((item, index) => {
                console.log("item", item);
                const LoadReference = item.totalLoadProfit|| 0;
                const VendorReference = item.totalVendorProfit|| 0;
                const collectionReference = item.totalCollectionProfit || 0;
                const LeadReference = item.totalLeadProfit || 0;

                return {
                    id: item._id || `row-${index}`,
                    number: index + 1,
                    staff: selectedStaffName,
                    LoadReference: LoadReference,
                    VendorReference: VendorReference,
                    collectionReference: collectionReference,
                    LeadReference: LeadReference,
                };
            });

            console.log(transformedRows);
            setRows(transformedRows);
        } else {

            setRows([]);
        }
    }, [consolidationData, selectedStaffName]);


    const matchesSearchTerm = (row) => {
    };


    const filteredConsolidationReports = searchTableData
        ? rows.filter(matchesSearchTerm)
        : rows;



    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredConsolidationReports);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "EmployeeBased");
        XLSX.writeFile(workbook, "Employee_Based.xlsx");
    };

    const handleTableSearchChange = (event) => {
        setSearchTableData(event.target.value);
    };


    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Consolidation Reports
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="row">
                {/* From Date */}
                <div className="col-md-4">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-4">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
            
                <div className="col-md-4 mb-2">
                    <div className="form-group">
                        <label htmlFor="staffNameSelect">Staff Name</label>
                        <select
                            id="staffNameSelect"
                            className="form-select form-control"
                            value={selectedStaffName}
                            onChange={(e) => {
                                const newStaffName = e.target.value;
                                console.log("Selected staff name:", newStaffName);
                                setSelectedStaffName(newStaffName);
                            }}
                            required
                        >
                            <option value="">Select Staff</option>
                            {getUser.map((staff) => (
                                <option key={staff._id} value={staff.name}>
                                    {staff.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-3 mt-2">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                </div>

                <div className="container">
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        value={searchTableData}
                        onChange={handleTableSearchChange}
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            width: '50%',
                            marginLeft: 'auto',
                            display: 'flex',
                            padding: '10px'
                        }}
                    />
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body table-responsive">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h4 className="card-title">List of Consolidation Report</h4>
                                        </div>
                                        <div>
                                            <button onClick={downloadExcel} className="btn btn-success mb-3">
                                                Download Excel
                                            </button>
                                        </div>
                                    </div>
                                    <Paper sx={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={filteredConsolidationReports}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 5 },
                                                },
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            sx={{ border: 0 }}
                                        />
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default ConsolidationReport;
