import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import * as XLSX from 'xlsx';

const UnderFollowUp = () => {
    const [searchUnderFollowUpTableData, setSearchUnderFollowUpTableData] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rows, setGridRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState("");
    const [getLiveMarketing, setGetLiveMarketing] = useState([]);
    const [getLeadOnline, setGetLeadOnline] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [getLeadSource, setGetLeadSource] = useState([]);
    const [selectedLeadSource, setSelectedLeadSource] = useState("All");
    const [getLeadCancelReason, setGetLeadCancelReason] = useState([]);
    const [selectedReason, setSelectedReason] = useState("");


    useEffect(() => {
        const fetchLeadOnline = async () => {
            try {
                const response = await api.get("/leadCreationData");
                //-console.log(response.data);
                setGetLeadOnline(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLeadOnline();
    }, []);

    useEffect(() => {
        const fetchLeadCancelReason = async () => {
            try {
                const response = await api.get("/cancelReason");
                setGetLeadCancelReason(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLeadCancelReason();
    }, []);

    useEffect(() => {
        const fetchLeadSources = async () => {
            try {
                const response = await api.get("/leadSource");
                setGetLeadSource(response.data);
            } catch (error) {
                //-console.error("Error fetching lead sources:", error);
            }
        };
        fetchLeadSources();
    }, []);

    useEffect(() => {
        const fetchLiveMarketing = async () => {
            try {
                const response = await api.get("/liveMarketing");
                setGetLiveMarketing(response.data);
                //-console.log(response.data);

            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLiveMarketing()
    }, []);

    useEffect(() => {
        let temp_rows = [];

        getLiveMarketing.forEach((item, index) => {
            const quotationalHistories = item.quotationalHistories.map((product) => ({
                productType: product.productType,
                unit: product.unit,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            }));

            temp_rows.push(
                createData(
                    item.name?.name || "",
                    item.assignedTo?.name || "",
                    item.createdAt,
                    item.pincode,
                    quotationalHistories,
                    item
                )
            );
        });

        getLeadOnline.forEach((item, index) => {
            const quotationalHistories = item.quotationalHistories.map((product) => ({
                productType: product.productType,
                unit: product.unit,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            }));

            temp_rows.push(
                createData(
                    item.name?.name || "",
                    item.assignedTo?.name || "",
                    item.createdAt,
                    item.pincode,
                    quotationalHistories,
                    item
                )
            );
        });

        //-console.log("Constructed Rows:", temp_rows);
        setGridRows(temp_rows);

    }, [getLiveMarketing, getLeadOnline]);


    // Handle date changes
    const handleFromDateChange = (e) => {
        //-console.log("From date changed:", e.target.value);
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        //-console.log("To date changed:", e.target.value);
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        //-console.log("Search clicked.");
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        //-console.log("fromDate", fromDate);
        //-console.log("toDate", toDate);

        // Ensure that fromDate and toDate are parsed correctly
        const fromMoment = moment(fromDate).startOf("day");
        const toMoment = moment(toDate).endOf("day");

        //-console.log("fromMoment", fromMoment);
        //-console.log("toMoment", toMoment);

        const filtered = rows.filter((row) => {
            // Parse createdAt according to its format
            const createdAt = moment(row.item.createdAt, "YYYY-MM-DD hh:mm A");
            //-console.log("createdAt", createdAt);

            // Check if createdAt is between fromMoment and toMoment
            const isBetween = createdAt.isBetween(fromMoment, toMoment, null, "[]");
            return isBetween;
        });
        setFilteredRows(filtered);
    };



    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    // Handle clear filter
    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
        setSelectedLeadSource("All");
        setSelectedReport("FollowUp")
    };

    useEffect(() => {
        const determineStatusFilter = () => {
            switch (selectedReport) {
                case "FollowUp":
                    return ["open", "escalation", "quotation"];
                case "Non Converted Leads":
                    return ["cancel"];
                case "Converted":
                    return ["order success"];
                default:
                    return [];
            }
        };

        const newStatusFilter = determineStatusFilter();
        //-console.log("Status Filter:", newStatusFilter);
        setStatusFilter(newStatusFilter);
    }, [selectedReport]);


    const getReportFilteredRows = () => {
        if (!searchClicked) return [];

        //-console.log("Filtered Rows Before Processing:", filteredRows);

        return filteredRows.filter((row) => {
            //-console.log("Processing Row:", row);

            const { leadCancelation } = row.item;
            //-console.log("leadCancel", row.item.leadCancelation);

            const statusMatches = statusFilter.includes(row.item.status.leadStatus);
            //-console.log("Status Matches:", statusMatches);

            if (selectedReport === "Non Converted Leads") {
                const lastApprovalStatus =
                    leadCancelation && leadCancelation.length > 0
                        ? leadCancelation[leadCancelation.length - 1]?.status
                        : null;
                //-console.log("Last Approval Status:", lastApprovalStatus);
                return statusMatches && lastApprovalStatus === "approval";
            }
            return statusMatches;
        });
    };


    const getFinalFilteredRows = () => {
        const reportFilteredRows = getReportFilteredRows();


        if (selectedLeadSource && selectedLeadSource !== "All") {
            const liveMarketingSource = getLeadSource.find(source => source.leadSource === "Live Marketing");


            const liveMarketingId = liveMarketingSource ? liveMarketingSource._id : null;

            return reportFilteredRows.filter(row => {

                if (selectedLeadSource === liveMarketingId) {
                    return !row.item.leadSource || row.item.leadSource === liveMarketingId;
                }
                return row.item.leadSource && row.item.leadSource._id === selectedLeadSource;
            });
        }

        if (selectedReport === "Non Converted Leads") {
            return reportFilteredRows.filter(row => {
                if (selectedReason === "All") {
                    return true;
                }
                return row.item.cancelReason === selectedReason;
            });
        }
        return reportFilteredRows;
    };


    const columns = [
        { field: "number", headerName: "Number", width: 100 },
        { field: 'customerName', headerName: 'Customer Name', width: 150 },
        { field: 'leadSource', headerName: 'Lead Source', width: 150 },
        { field: 'assignedTo', headerName: 'Assigned To', width: 150 },
        { field: 'date', headerName: 'Date', width: 180 },
        { field: 'pincode', headerName: 'Pincode', width: 150 },
        { field: 'city', headerName: 'City', width: 150 },
    ];

    if (selectedReport === "Non Converted Leads") {
        columns.push({ field: 'reason', headerName: 'Reason', width: 220 });
    }

    const renderRows = () => {
        const ReportFilteredRows = getFinalFilteredRows();
        return ReportFilteredRows.map((row, index) => ({
            id: row.item._id,
            number: index + 1,

            customerName: row.item.name?.name ?? "",
            leadSource: row.item.leadSource?.leadSource ?? "",
            assignedTo: row.item.assignedTo?.name ?? "",
            date: row.lead_date,
            pincode: row.item.pincode?.pincode ?? "",
            city: row.item.pincode?.district?.district ?? "",
            ...(selectedReport === "Non Converted Leads" && { reason: row.item?.cancelReason ?? "" }),
        }));
    };

    const filteredUnderReports = renderRows();

    const handleTableSearchChange = (event) => {
        setSearchUnderFollowUpTableData(event.target.value);
    };

    const matchesUnderFollowUpSearchTerm = (row) => {
        const item = row || {};
        console.log("item", item);

        const customerName = (item.customerName || '').toLowerCase();
        const leadSource = (item.leadSource || '').toString().toLowerCase();
        const assignedTo = (item.assignedTo || '').toString().toLowerCase();
        const pincode = (item.pincode || '').toString().toLowerCase();
        const city = (item.city || '').toString().toLowerCase();

        const searchValue = searchUnderFollowUpTableData.toLowerCase();

        return (
            customerName.includes(searchValue) ||
            leadSource.includes(searchValue) ||
            assignedTo.includes(searchValue) ||
            pincode.includes(searchValue) ||
            city.includes(searchValue)
        );
    };

    const filteredUnderFollowUp = searchUnderFollowUpTableData
        ? filteredUnderReports.filter(matchesUnderFollowUpSearchTerm)
        : filteredUnderReports;


    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredUnderFollowUp);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "UnderFollowUp");
        XLSX.writeFile(workbook, "UnderFollowUp.xlsx");
    };


    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Under FollowUp
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria--current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="row">
                {/* From Date */}
                <div className="col-md-2">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-2">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-2 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status Category</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                            required
                        >
                            <option selected value="FollowUp">FollowUp</option>
                            <option value="Non Converted Leads">Non Converted Leads</option>
                            <option value="Converted">Converted Leads</option>
                        </select>
                    </div>
                </div>
                {selectedReport === "Non Converted Leads" && (
                    <div className="col-md-3 mb-2">
                        <div className="form-group">
                            {/* Form */}
                            <label htmlFor="inlineFormInputName2">Cancel reason</label>
                            <select
                                className="form-select form-control"
                                value={selectedReason}
                                onChange={(e) => setSelectedReason(e.target.value)}
                                required
                            >
                                <option selected value="All">All</option>
                                {getLeadCancelReason.map((reason, index) => (
                                    <option key={index} value={reason}>
                                        {reason.cancelReason}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {/* Select the Lead Source */}
                <div className="col-md-2 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Lead Source</label>
                        <select
                            className="form-select form-control"
                            value={selectedLeadSource}
                            onChange={(e) => setSelectedLeadSource(e.target.value)}
                        >
                            <option selected value="All">All</option>
                            {getLeadSource.map((source) => (
                                <option key={source._id} value={source._id}>
                                    {source.leadSource}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="d-flex align-items-center">
                        <button className="btn btn-primary mt-4" onClick={handleSearchClick}>
                            Search Date
                        </button>
                        {searchClicked && (
                            <button
                                onClick={handleClearFilter}
                                className="btn btn-sm badge-gradient-danger"
                                aria-label="Close"
                                style={{ marginLeft: "10px", marginTop: "30px" }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <TextField
                variant="outlined"
                placeholder="Search"
                value={searchUnderFollowUpTableData}
                onChange={handleTableSearchChange}
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: '50%',
                    marginLeft: 'auto',
                    display: 'flex',
                    padding: '10px'
                }}
            />
            {/* Table display */}
            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title">List of Under FollowUp</h4>
                                </div>
                                <div>
                                    <button onClick={downloadExcel} className="btn btn-success mb-3">
                                        Download Excel
                                    </button>
                                </div>
                            </div>
                            <Paper sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={filteredUnderFollowUp}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    sx={{ border: 0 }}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

function createData(
    cus_name,
    lead_source,
    lead_date,
    pincode,
    quotationalHistories,
    item
) {
    return {
        cus_name,
        lead_source,
        lead_date,
        pincode,
        quotationalHistories,
        item,
    };
}

export default UnderFollowUp;
